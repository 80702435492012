import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  return (
    <React.Fragment>
      {/* <li>
        <Link to={"/users"}>
          <i className="bx bx-user-circle" />
          <span>{props.t("Users")}</span>
        </Link>
      </li> */}

      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-user-circle" />
          <span>{props.t("Users")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/users">{props.t("List")}</Link>
          </li>
          <li>
            <Link to="/add-user">{props.t("Add")}</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to={"/email-contacts"}>
          <i className="bx bx-book" />
          <span>{props.t("News Letter subscriber")}</span>
        </Link>
      </li>
      <li>
        <Link to={"/contact-us"}>
          <i className="bx bx-book" />
          <span>{props.t("Contact Us")}</span>
        </Link>
      </li>

      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-collection" />
          <span>{props.t("Crypto")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/crypto">{props.t("List")}</Link>
          </li>
          <li>
            <Link to="/crypto-add">{props.t("Add")}</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-money" />
          <span>{props.t("Currency")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/currency">{props.t("List")}</Link>
          </li>
          <li>
            <Link to="/currency-add">{props.t("Add")}</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to={"/transactions"}>
          <i className="bx bx-transfer" />
          <span>{props.t("Transactions")}</span>
        </Link>
      </li>

      <li>
        <Link to={"/referrals"}>
          <i className="bx bx-book" />
          <span>{props.t("Referrals")}</span>
        </Link>
      </li>
      {/* <li>
        <Link to={"/promotion-email"}>
        <i className="bx bxs-book-content" />
          <span>{props.t("Promotion Email")}</span>
        </Link>
      </li> */}
      <li>
        <Link to={"/faqs"}>
          <i className="bx bx-bar-chart" />
          <span>{props.t("Faqs")}</span>
        </Link>
      </li>
      <li>
        <Link to={"/Content"}>
          <i className="bx bxs-book-content" />
          <span>{props.t("Content page")}</span>
        </Link>
      </li>
      <li>
        <Link to={"/company-page"}>
          <i className="bx bxs-book-content" />
          <span>{props.t("Company page")}</span>
        </Link>
      </li>
      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-book" />
          <span>{props.t("Blogs")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/blogs">{props.t("List")}</Link>
          </li>
          <li>
            <Link to="/add-blogs">{props.t("Add")}</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-cog" />
          <span>{props.t("Settings")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/general">{props.t("General Settings")}</Link>
          </li>
          <li>
            <Link to="/blockchain">{props.t("Network Mode")}</Link>
          </li>
          <li>
            <Link to="/uniramp">{props.t("Uniramp Api Keys")}</Link>
          </li>
          <li>
            <Link to="/transak">{props.t("Transak Api Keys")}</Link>
          </li>
          <li>
            <Link to="/fireblock">{props.t("Fireblock Api Keys")}</Link>
          </li>

          <li>
            <Link to="/mail-chimp">{props.t("Mail Chimp Api Keys")}</Link>
          </li>
          <li>
            <Link to="/tawk-to">{props.t("Tawk To Api Keys")}</Link>
          </li>
          <li>
            <Link to="/kyc-keys">{props.t("KYC Aid Keys")}</Link>
          </li>
        </ul>
      </li>
      {/* <hr />

      <li>
        <Link to="/general">
          {" "}
          <i className="bx bx-cog" />
          {props.t("General Settings")}
        </Link>
      </li> */}

      {/* <li>
        <Link to="/blockchain">
          {" "}
          <i className="bx bx-bitcoin" />
          {props.t("Blockchain Network")}
        </Link>
      </li>

      <li>
        <Link to="/wallet">
          {" "}
          <i className="bx bx-wallet-alt" />
          {props.t("Wallet Settings")}
        </Link>
      </li>
      <li>
        <Link to="/stripe">
          {" "}
          <i className="bx bx-dollar" />
          {props.t("Stripe Settings")}
        </Link>
      </li>

      <li>
        <Link to="/transak">
          {" "}
          <i class='bx bx-transfer-alt'></i>
          {props.t("Transak Settings")}
        </Link>
      </li> */}

      <hr />

      <li>
        <Link to="/change-password">
          {" "}
          <i className="mdi mdi-key" />
          {props.t("Change Password")}
        </Link>
      </li>

      <li>
        <Link to="/logout">
          {" "}
          <i className="bx bx-power-off" />
          {props.t("Logout")}
        </Link>
      </li>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
