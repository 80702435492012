import { call, put, takeEvery } from "redux-saga/effects";

// Item Redux States
import {
  GET_ITEMS,
  ADD_ITEM,
  GET_ITEM,
  PUT_ITEM,
  DELETE_ITEM,
  PUT_ITEMS_STATUS,
  MINT_ITEM,
} from "./actionTypes";
import {
  getItemsFail,
  getItemsSuccess,
  addItemFail,
  addItemSuccess,
  getItemFail,
  getItemSuccess,
  putItemFail,
  putItemSuccess,
  deleteItemFail,
  deleteItemSuccess,
  putItemsStatusFail,
  putItemsStatusSuccess,
  mintItemFail,
  mintItemSuccess,
} from "./actions";

import {
  getItems,
  postItem,
  getItem,
  putItem,
  deleteItem,
  putItemsStatus,
  mintItem,
} from "helpers/backend_helper";

function* fetchItems({ payload }) {
  try {
    const response = yield call(getItems, payload);

    if (response.status == "failure") {
      return yield put(getItemsFail(response.message));
    }

    yield put(getItemsSuccess(response));
  } catch (error) {
    yield put(getItemsFail(error));
  }
}

function* onAddNewItem({ payload: { item, callback } }) {
  try {
    const response = yield call(postItem, item);
    callback && callback(response);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(addItemFail(response.message));
    }

    yield put(addItemSuccess());
  } catch (error) {
    yield put(addItemFail("Internal Error!"));
  }
}

function* fetchItem({ payload }) {
  try {
    const response = yield call(getItem, payload);

    if (response.status == "failure") {
      return yield put(getItemFail(response.message));
    }

    yield put(getItemSuccess(response.data));
  } catch (error) {
    yield put(getItemFail(error));
  }
}

function* onPutItem({ payload: { data, history } }) {
  try {
    const response = yield call(putItem, data);

    if (response.status == "failure") {
      return yield put(putItemFail(response.message));
    }

    history && history.goBack();

    yield put(putItemSuccess());
  } catch (error) {
    yield put(putItemFail("Internal Error!"));
  }
}

function* onDeleteItem({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteItem, data);

    if (response.status == "failure") {
      return yield put(deleteItemFail(response.message));
    }

    yield put(deleteItemSuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteItemFail("Internal Error!"));
  }
}

function* onPutItemsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putItemsStatus, data);

    if (response.status == "failure") {
      return yield put(putItemsStatusFail(response.message));
    }

    yield put(putItemsStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putItemsStatusFail("Internal Error!"));
  }
}

function* itemMint({ payload: { data, callback } }) {
  try {
    const response = yield call(mintItem, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(mintItemFail(response.message));
    }

    yield put(mintItemSuccess());
  } catch (error) {
    yield put(mintItemFail("Internal Error!"));
  }
}

function* ItemsSaga() {
  yield takeEvery(GET_ITEMS, fetchItems);
  yield takeEvery(ADD_ITEM, onAddNewItem);
  yield takeEvery(GET_ITEM, fetchItem);
  yield takeEvery(PUT_ITEM, onPutItem);
  yield takeEvery(DELETE_ITEM, onDeleteItem);
  yield takeEvery(PUT_ITEMS_STATUS, onPutItemsStatus);
  yield takeEvery(MINT_ITEM, itemMint);
}

export default ItemsSaga;
