export const API_FAIL = "API_FAIL"
export const CUSTOMER_LOADING = "CUSTOMER_LOADING"

// Customers
export const GET_BLOGS = "GET_BLOGS"
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL"
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS"




export const ADD_BLOGS = "ADD_BLOGS";
export const ADD_BLOGS_FAIL = "ADD_BLOGS_FAIL";
export const ADD_BLOGS_SUCCESS = "ADD_BLOGS_SUCCESS";

export const UPDATE_BLOGS = "UPDATE_BLOGS";
export const UPDATE_BLOGS_FAIL = "UPDATE_BLOGS_FAIL";
export const UPDATE_BLOGS_SUCCESS = "UPDATE_BLOGS_SUCCESS";

export const BLOGS_BY_ID = "BLOGS_BY_ID";
export const BLOGS_BY_ID_FAIL = "BLOGS_BY_ID_FAIL";
export const BLOGS_BY_ID_SUCCESS = "BLOGS_BY_ID_SUCCESS";

export const DELETE_BLOGS = "DELETE_BLOGS";
export const DELETE_BLOGS_FAIL = "DELETE_BLOGS_FAIL";
export const DELETE_BLOGS_SUCCESS = "DELETE_BLOGS_SUCCESS";
