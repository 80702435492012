import {
  SUB_ADMIN_API_FAIL,
  GET_SUB_ADMINS,
  GET_SUB_ADMINS_FAIL,
  GET_SUB_ADMINS_SUCCESS,
  ADD_SUB_ADMIN,
  ADD_SUB_ADMIN_FAIL,
  ADD_SUB_ADMIN_SUCCESS,
  GET_SUB_ADMIN,
  GET_SUB_ADMIN_FAIL,
  GET_SUB_ADMIN_SUCCESS,
  PUT_SUB_ADMIN,
  PUT_SUB_ADMIN_FAIL,
  PUT_SUB_ADMIN_SUCCESS,
  DELETE_SUB_ADMIN,
  DELETE_SUB_ADMIN_FAIL,
  DELETE_SUB_ADMIN_SUCCESS,
} from "./actionTypes"

export const apiFail = error => ({
  type: SUB_ADMIN_API_FAIL,
  payload: error,
})

/* Get subAdmins */
export const getSubAdmins = data => ({
  type: GET_SUB_ADMINS,
  payload: data,
})

export const getSubAdminsSuccess = subAdmins => ({
  type: GET_SUB_ADMINS_SUCCESS,
  payload: subAdmins,
})

export const getSubAdminsFail = error => ({
  type: GET_SUB_ADMINS_FAIL,
  payload: error,
})
/* Get SubAdmins END */

/* Add SubAdmin */
export const addSubAdmin = (subAdmin, history) => ({
  type: ADD_SUB_ADMIN,
  payload: { subAdmin, history },
})

export const addSubAdminFail = error => ({
  type: ADD_SUB_ADMIN_FAIL,
  payload: error,
})

export const addSubAdminSuccess = () => ({
  type: ADD_SUB_ADMIN_SUCCESS,
})
/* Add SubAdmin END */

/* Get SubAdmin */
export const getSubAdmin = data => ({
  type: GET_SUB_ADMIN,
  payload: data,
})

export const getSubAdminFail = error => ({
  type: GET_SUB_ADMIN_FAIL,
  payload: error,
})

export const getSubAdminSuccess = subAdmin => ({
  type: GET_SUB_ADMIN_SUCCESS,
  payload: subAdmin,
})
/* Get SubAdmin END */

/* Update SubAdmin */
export const putSubAdmin = (data, history) => ({
  type: PUT_SUB_ADMIN,
  payload: { data, history },
})

export const putSubAdminFail = error => ({
  type: PUT_SUB_ADMIN_FAIL,
  payload: error,
})

export const putSubAdminSuccess = () => ({
  type: PUT_SUB_ADMIN_SUCCESS,
})
/* Update SubAdmin END */

/* Delete SubAdmin */
export const deleteSubAdmin = (data, callback) => ({
  type: DELETE_SUB_ADMIN,
  payload: { data, callback },
})

export const deleteSubAdminFail = error => ({
  type: DELETE_SUB_ADMIN_FAIL,
  payload: error,
})

export const deleteSubAdminSuccess = () => ({
  type: DELETE_SUB_ADMIN_SUCCESS,
})
/* Delete Faz END */
