import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Dropzone from "react-dropzone";

import { Row, Col, FormGroup, FormText, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";
import { useDispatch } from "react-redux";

// Images
import logo_placeholder from "assets/images/logo-placeholder.png";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;
  const dispatch = useDispatch();

  const [imageselectedFiles, setimageselectedFiles] = useState([]);

  useEffect(() => {
    if (fields?.image) {
      setimageselectedFiles([{ preview: fields?.image }]);
    }
  }, [JSON.stringify(fields?.image)]);

  const uploadFileSuccess = (name) => (response) => {
    const { link } = response.data;

    handleChange(null)(name)({ target: { value: link } });
  };

  const handleAcceptedFiles = (name) => (_files) => {
    const files = _files?.filter((file) => file.size < 5242880);

    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }

    onUploadFile({ file: files[0] }, uploadFileSuccess(name));
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    if (name === "image") {
      setimageselectedFiles(files);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Instructions t={props.t} />
        </Col>

        {/* <Col md={12}>
          <FormGroup>
            <Label>image</Label>
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles("image")(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => {
                const imageFile = imageselectedFiles[0];

                return (
                  <div
                    className="dropzone-single-image avatar-xl border"
                    {...getRootProps()}
                  >
                    <input
                      {...getInputProps()}
                      id="formrow-profile-image-Input"
                      multiple={false}
                    />
                    <img
                      className="rounded avatar-xl"
                      alt={
                        !!imageFile && imageFile.name ? imageFile.name : "hlc"
                      }
                      src={!!imageFile ? imageFile.preview : logo_placeholder}
                    />
                    <div className="edit">
                      <i className="bx bx-pencil"></i>
                    </div>
                  </div>
                );
              }}
            </Dropzone>
            <FormText>Max. upload file size: 5MB</FormText>
          </FormGroup>
        </Col> */}

        <Col lg={12}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Heading 1 English</Label>
                <Input
                  type="text"
                  value={fields.heading1En}
                  onChange={(e) => handleChange(null)("heading1En")(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Heading 1 Arabic</Label>
                <Input
                  type="text"
                  value={fields.heading1Ar}
                  onChange={(e) => handleChange(null)("heading1Ar")(e)}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Description English</Label>
                <Input
                  type="textarea"
                  value={fields.descriptionEn}
                  onChange={(e) => handleChange(null)("descriptionEn")(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Description Arabic</Label>
                <Input
                  type="textarea"
                  value={fields.descriptionAr}
                  onChange={(e) => handleChange(null)("descriptionAr")(e)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
