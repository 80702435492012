import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { post } from "../../../helpers/api_helper";
import { STATUS_CHANGE_BY_ADMIN } from "../../../helpers/url_helper";
import userData from "../CustomersList/data.json";
import TransactionData from "./data.json";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import moment from "moment";

//Import actions
import { getCustomer } from "store/actions";

const ViewCustomer = (props) => {
  const {
    match: { params },
    onGetCustomer,
    customer: reducerCustomer,
    error,
    loading,
  } = props;

  const [customer, setcustomer] = useState(null);
  const [state, setstate] = useState({
    innerTable: false,
    innerData: {},
  });

  const getCustomerFromJson = (id) => {
    if (id) {
      const getUser = userData?.find((_d) => _d._id == id);

      if (getUser) {
        setcustomer(getUser);
      }
    }
  };

  useEffect(() => {
    if (params && params.id) {
      // onGetCustomer(params.id);
      getCustomerFromJson(params.id);
    }
  }, [params]);

  const handleInnerListing = (e, data) => {
    e.preventDefault();
    if (data) {
      setstate((pre) => ({
        ...pre,
        innerTable: true,
        innerData: data,
      }));
    } else {
      setstate((pre) => ({
        ...pre,
        innerTable: false,
        innerData: null,
      }));
    }
  };

  const { innerData, innerTable } = state;

  return (
    <React.Fragment>
      {loading && (
        <div className="page-content">
          <Spinner color="primary" />
        </div>
      )}
      {/* {error && <Redirect to="/dashboard" />} */}
      { !isEmpty(customer) && !loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Users"
              breadcrumbItem="Merchant Transaction Details"
            />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <h5 className="mt-1 mb-3">{customer.name}</h5>
                          <h5 className="mt-1 mb-3">
                            Wallet Address - {customer.walletAddress}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                    {innerTable ? (
                      <a
                        href=""
                        className="ml-2 font-18"
                        onClick={(e) => handleInnerListing(e, null)}
                      >
                        <i class="fas fa-arrow-left mr-1"></i>Back
                      </a>
                    ) : (
                      <Col md="12" lg="10">
                        <div className="form-group row mb-2 mr-2">
                          <label className="col-md-12 col-xl-2 pr-0 col-form-label">
                            {"Select Date"}:
                          </label>

                          <div className="col-md-12 col-xl-4">
                            <input
                              id="search-bar-0"
                              aria-labelledby="search-bar-0-label"
                              className="form-control"
                              type="date"
                            />
                          </div>
                          <div className="col-md-12 col-xl-4">
                            <input
                              id="search-bar-0"
                              aria-labelledby="search-bar-0-label"
                              className="form-control"
                              type="date"
                            />
                          </div>
                        </div>
                      </Col>
                    )}

                    <div className="overflow_auto">
                      <table class="table table table-centered table-nowrap">
                        {innerTable ? (
                          <>
                            <thead class="thead-light">
                              <tr>
                                <th
                                  tabindex="0"
                                  aria-label="Name sortable"
                                  class="sortable"
                                >
                                  Date
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Name sortable"
                                  class="sortable"
                                >
                                  Type
                                </th>
                                <th
                                  tabindex="0"
                                  aria-label="Name sortable"
                                  class="sortable"
                                >
                                  Crypto
                                </th>
                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Crypto Transfer Fee
                                </th>
                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  User
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Amount
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Date & Time Request
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Date & Time Process
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {innerData?.length > 0 ? (
                                innerData.map((_d, i) => (
                                  <>
                                    <tr>
                                      <td>{"1-Nov-2022"}</td>
                                      <td>{_d.type}</td>
                                      <td>{_d.crypto}</td>
                                      <td>{}</td>
                                      <td>{_d.user}</td>
                                      <td>{_d.amount}</td>
                                      <td>{_d.timeRequest}</td>
                                      <td>{_d.timeProcess}</td>
                                    </tr>
                                  </>
                                ))
                              ) : (
                                <tr>
                                  {" "}
                                  <td>No User Found</td>
                                </tr>
                              )}
                            </tbody>
                          </>
                        ) : (
                          <>
                            <thead class="thead-light">
                              <tr>
                                <th
                                  tabindex="0"
                                  aria-label="Name sortable"
                                  class="sortable"
                                >
                                  Date
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Name sortable"
                                  class="sortable"
                                >
                                  Deposit
                                </th>
                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Currency
                                </th>
                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Crypto
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Crypto Transfer fee
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Minus Provider fee
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Withdraw
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Email sortable"
                                  class="sortable"
                                >
                                  Crypto Transfer fee
                                </th>
                                <th
                                  tabindex="0"
                                  aria-label="Wallet Address sortable"
                                  class="sortable"
                                >
                                  Minus Provider fee
                                </th>
                                <th
                                  tabindex="0"
                                  aria-label="Wallet Address sortable"
                                  class="sortable"
                                >
                                  DEPO - WD
                                </th>

                                <th
                                  tabindex="0"
                                  aria-label="Wallet Address sortable"
                                  class="sortable"
                                >
                                  DEPO - WD FEE
                                </th>
                                <th
                                  tabindex="0"
                                  aria-label="Wallet Address sortable"
                                  class="sortable"
                                >
                                  Payment
                                </th>
                                <th tabindex="0">Balance</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TransactionData?.crypto?.length > 0 ? (
                                TransactionData?.crypto.map((_d, i) => (
                                  <>
                                    <tr>
                                      <td>{i == 0 ? "1-Nov-2022" : ""}</td>
                                      
                                      <td>{_d.deposit}</td>
                                      <td>{_d.currency}</td>

                                      <td>
                                        <a
                                          href=""
                                          onClick={(e) =>
                                            handleInnerListing(
                                              e,
                                              _d.singleDayTransaction
                                            )
                                          }
                                        >
                                          {_d.name}
                                        </a>
                                      </td>

                                      <td>{}</td>
                                      <td>{_d.afterminusDeposit}</td>
                                      <td>{_d.withdraw}</td>
                                      <td>{_d.withdrawTransferFee}</td>
                                      <td>{_d.afterminuswithdraw}</td>
                                      <td>{_d.depo_wd}</td>
                                      <td>{_d.depo_wd_afterfee}</td>
                                      <td>{_d.payment}</td>
                                      <td>{_d.balance}</td>
                                    </tr>
                                  </>
                                ))
                              ) : (
                                <tr>
                                  {" "}
                                  <td>No User Found</td>
                                </tr>
                              )}
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ViewCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  customer: PropTypes.any,
  onGetCustomer: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getCustomer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
