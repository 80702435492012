import {
  CUSTOMER_LOADING,
  API_FAIL,

  GET_CONTACT_EMAILS,
  GET_CONTACT_EMAIL_SUCCESS,
  GET_CONTACT_EMAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  customers: [],
  totalCustomers: 0,
  error: "",
  keys_error: "",
  loading: false,
  keys_loading: false,
  customer: {},
  address: {},
  plans: [],
  transactions: {
    data: [],
    totalcount: 0,

  },
  hideThingsError: "",
}

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    /** Loading */
    /* Get Contacts Emails */
    case GET_CONTACT_EMAILS:
      return {
        ...state,
        loading: true,
        error: "",

      }

    case GET_CONTACT_EMAIL_SUCCESS:
      return {
        ...state,
        customers: action.payload.data || [],
        totalCustomers: action.payload.totalCount || 0,
        loading: false,
        error: "",

      }

    case GET_CONTACT_EMAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* POST Hide Things END */
    /** */
    default:
      return state
  }
}

export default Customers
