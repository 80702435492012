import {
  EMAIL_TEMPLATE_API_FAIL,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_FAIL,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_FAIL,
  GET_EMAIL_TEMPLATE_SUCCESS,
  PUT_EMAIL_TEMPLATE,
  PUT_EMAIL_TEMPLATE_FAIL,
  PUT_EMAIL_TEMPLATE_SUCCESS,
  POST_EMAIL_TEMPLATE,
  POST_EMAIL_TEMPLATE_FAIL,
  POST_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_FAIL,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  emailTemplates: [],
  totalEmailTemplates: 0,
  error: "",
  loading: false,
  emailTemplate: {},
}

const EmailTemplate = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get EmailTemplates */
    case GET_EMAIL_TEMPLATES:
      return {
        ...state,
        loading: true,
      }

    case GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload.data || [],
        totalEmailTemplates: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_EMAIL_TEMPLATES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get EmailTemplates END */

    /* Get Email Template */
    case GET_EMAIL_TEMPLATE:
      return {
        ...state,
        error: "",
        loading: true,
        emailTemplate: {},
      }

    case GET_EMAIL_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        emailTemplate: {},
      }

    case GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        emailTemplate: action.payload,
      }
    /* Add Email Template END */

    /* Update Email Template */
    case PUT_EMAIL_TEMPLATE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_EMAIL_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update Email Template END */

    /* Post Email Template */
    case POST_EMAIL_TEMPLATE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case POST_EMAIL_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case POST_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Rest Email Template END */

    /* Delete Email Template */
    case DELETE_EMAIL_TEMPLATE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_EMAIL_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Delete Email Template END */

    case EMAIL_TEMPLATE_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default EmailTemplate
