import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import toastr from "toastr"
import {
  GET_BLOGS,
  BLOGS_BY_ID ,
  ADD_BLOGS,
  UPDATE_BLOGS,
  DELETE_BLOGS
} from "./actionTypes"
import {
  getBlogsFail,
  getBlogsSuccess,
  addBlogsFail, addBlogsSuccess, udpateBlogsSuccess,udpateBlogsFail,
  getByIdBlogsFail, getByIdBlogsSuccess, deleteBlogsFail, deleteBlogsSuccess
} from "./actions"

import {
  getBlogsApi,
  addBlogsApi,
  updateBlogsApi,getByIdBlogApi,deleteBlogs
} from "helpers/backend_helper"

toastr.options = {
  newestOnTop: true,
  progressBar: true,
}

function* fetchBlogs({ payload }) {

  try {
    const response = yield call(getBlogsApi, payload)
    if (response.status == "failure") {
      return yield put(getBlogsFail(response.message))
    }
    yield put(getBlogsSuccess(response))
  } catch (error) {
    yield put(getBlogsFail(error))
  }
}


function* addBlogsSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(addBlogsApi, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(addBlogsFail(response.message));
    }
    yield put(addBlogsSuccess(response));
  } catch (error) {
    toastr.error(error?.response?.data?.message)
    yield put(addBlogsFail(error));
  }
}

function* updateBlogsSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(updateBlogsApi, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(udpateBlogsFail(response.message));
    }
    yield put(udpateBlogsSuccess(response));
  } catch (error) {
    toastr.error(error?.response?.data?.message)
    yield put(udpateBlogsFail(error));
  }
}

function* getByIdBlogsSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getByIdBlogApi, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getByIdBlogsFail(response.message));
    }
    yield put(getByIdBlogsSuccess(response));
  } catch (error) {
    toastr.error(error?.response?.data?.message)
    yield put(getByIdBlogsFail(error));
  }
}

function* deleteBlogsSaga({ payload: { data, callback } }) {
  console.log("data",data)
  try {
    const response = yield call(deleteBlogs, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(deleteBlogsFail(response.message));
    }
    yield put(deleteBlogsSuccess(response));
  } catch (error) {
    yield put(deleteBlogsFail(error));
  }
}


function* blogsSaga() {
  yield takeLatest(GET_BLOGS, fetchBlogs)
  yield takeLatest(ADD_BLOGS, addBlogsSaga)
  yield takeLatest(UPDATE_BLOGS, updateBlogsSaga)
  yield takeLatest(BLOGS_BY_ID, getByIdBlogsSaga)
  yield takeLatest(DELETE_BLOGS, deleteBlogsSaga)

}

export default blogsSaga
