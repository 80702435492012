import axios from "axios";
import { post, del, get, put } from "./api_helper";
import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";
import { data } from "jquery";

const urlHelper = (...props) => props?.join("/");

// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);

// Register
export const postRegisterEmail = (data) => post(url.POST_REGISTER_EMAIL, data);
export const postStoreName = (data) => post(url.POST_STORE_NAME, data);
export const postRegister = (data) => post(url.POST_REGISTER, data);

// Forgot Password
export const postForgetPwd = (data) => post(url.POST_FORGOT_PASSWORD, data);
export const postResetPwd = (data) => post(url.POST_RESET_PASSWORD, data);

// Profile
export const getCurrentUser = () => get(url.GET_CURRENT_USER);
export const putProfile = (data) => post(url.PUT_PROFILE, data);
export const putChangePassword = (data) => post(url.CHANGE_PASSWORD, data);
export const getLanguages = () => get(url.GET_LANGUAGES);
export const deleteStore = (data) => post(url.DELETE_STORE, data);

// Logs
export const getLogs = (data) => post(url.GET_LOGS, data);
export const postRebuildScript = () => get(url.POST_REBUILD_SCRIPT);
export const postStoreRebuildScript = (data) =>
  post(url.POST_STORE_REBUILD_SCRIPT, data);
export const postStoreSettingsScript = () =>
  get(url.POST_STORE_SETTINGS_SCRIPT);
export const postClearLogs = (query) => get(url.POST_CLEAR_LOGS + query);

// File
export const postFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_FILE, formData, config);
};
// File
export const postItemFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_ITEM_FILE, formData, config);
};
export const postRemoveFile = (data) => post(url.POST_REMOVE_FILE, data);

// Customers
export const getCustomers = (data) => post(url.GET_USERS, data);
export const getCustomer = (id) => get(url.VIEW_USER + "/" + id);
export const updateStoreStatus = (data) => post(url.UPDATE_STORE_STATUS, data);
export const putStoreKeys = (data) => post(url.PUT_STORE_KEYS, data);
export const postCheckMail = (query) => get(url.POST_CHECK_MAIL + query);
export const postHideThings = (data) => post(url.POST_HIDE_THINGS, data);
export const deleteCustomer = (data) => post(url.DELETE_CUSTOMER, data);
export const addCustomer = (data) => post(url.ADD_CUSTOMER, data);


// Email Template
export const getEmailTemplates = (data) => post(url.GET_EMAIL_TEMPLATES, data);
export const postEmailTemplate = (data) => post(url.POST_EMAIL_TEMPLATE, data);
export const putEmailTemplate = (data) => post(url.PUT_EMAIL_TEMPLATE, data);
export const deleteEmailTemplate = (data) =>
  post(url.DELETE_EMAIL_TEMPLATE, data);
export const getEmailTemplate = (id) => get(url.GET_EMAIL_TEMPLATE + "/" + id);

// Plans
export const getCollections = (data) => post(url.GET_COLLECTIONS, data);
export const postCollection = (data) => post(url.POST_COLLECTION, data);
export const putCollection = (data) => post(url.PUT_COLLECTION, data);
export const deleteCollection = (data) => post(url.DELETE_COLLECTION, data);
export const getCollection = (id) => get(url.GET_COLLECTION + "/" + id);
export const putCollectionsStatus = (data) =>
  post(url.PUT_COLLECTION_STATUS, data);
export const updateStoreCollection = (data) =>
  post(url.UPDATE_STORE_COLLECTION, data);
export const putStoreCollectionDiscount = (data) =>
  post(url.PUT_COLLECTION_DISCOUNT, data);

export const compileDeployCollection = (data) =>
  post(url.COMPILE_DEPLOY_COLLECTIONS, data);

// Transactions
export const getTransactions = (data) => post(url.GET_TRANSACTIONS, data);
export const getBorrowTransactions = (data) => post(url.GET_BORROW_TRANSACTIONS, data);
export const chargeStore = (data) => post(url.POST_STORE_CHARGE, data);
export const refundStore = (data) => post(url.POST_STORE_REFUND, data);

export const getReferrals = (data) => post(url.GET_REFERRALS, data);
export const deleteReferrals = (data) => post(url.DELETE_REFERRALS, data);


// get Domain
export const updateDns = (data) =>
  axios.get(`${url.UPDATE_DNS}webname=${data.old}&newdomain=${data.domain}`);
export const addDomain = (data) => post(url.ADD_DOMAIN, data);
export const searchDomain = (data) => post(url.SEARCH_DOMAIN, data);
export const buyDomain = (data) => post(url.BUY_DOMAIN, data);

// Sub Admins
export const getSubAdmins = (data) => post(url.GET_SUB_ADMINS, data);
export const postSubAdmin = (data) => post(url.ADD_SUB_ADMIN, data);
export const getSubAdmin = (data) => post(url.GET_SUB_ADMIN, data);
export const putSubAdmin = (data) => post(url.PUT_SUB_ADMIN, data);
export const deleteSubAdmin = (data) => post(url.DELETE_SUB_ADMIN, data);

// Store Login
export const storeLogin = (data) => post(url.STORE_LOGIN_BY_ADMIN, data);

// Settings
export const getSettings = () => get(url.GET_SETTINGS);
export const putSettings = (data) => post(url.PUT_SETTINGS, data);
export const getStoreSettings = (storeType, id) =>
  get([url.API_SETTINGS, storeType, url.GET_STORE_SETTINGS, id].join("/"));
export const putStoreSettings = (storeType, data) =>
  post([url.API_SETTINGS, storeType, url.PUT_STORE_SETTINGS].join("/"), data);
export const getAccessList = () => get(url.GET_ACCESS_LIST);
export const getStoreTypes = () => get(url.GET_STORE_TYPES);
export const getStarted = () => get(url.GET_STARTED);
export const postDeployContract = (data) =>
  put(url.POST_CONTRACT_DEPLOY, data);

  export const postCompileContract = (data) =>
  put(url.POST_COMPILE_CONTRACT, data);


  export const postDeployBorrowContract = (data) =>
  put(url.POST_BORROW_CONTRACT_DEPLOY, data);

  export const postCompileBorrowContract = (data) =>
  put(url.POST_COMPILE_BORROW_CONTRACT, data);

// Categories
export const getCategories = (data) => post(url.VIEW_CATEGORIES, data);

// Activities
export const getActivities = (data) => post(url.GET_ACTIVITIES, data);

// Items
export const getItems = (data) => post(url.GET_ITEMS, data);
export const postItem = (data) => post(url.POST_ITEM, data);
export const putItem = (data) => post(url.PUT_ITEM, data);
export const deleteItem = (data) => post(url.DELETE_ITEM, data);
export const getItem = (id) => get(url.GET_ITEM + "/" + id);
export const putItemsStatus = (data) => post(url.PUT_ITEM_STATUS, data);
export const mintItem = (data) => post(url.MINT_ITEM, data);

// Faqs
export const getFaqs = (data) => post(url.GET_FAQS, data);
export const postFaq = (data) => post(url.ADD_FAQ, data);
export const getViewFaq = (id) => get(url.GET_VIEW_FAQ + "/" + id);
export const updateFaq = (data) => post(url.UPDATE_FAQ, data);
export const deleteFaq = (data) => post(url.DELETE_FAQ, data);
export const putFaqsStatus = (data) => put(url.PUT_FAQS_STATUS, data);

//crypto
export const getCryptoList = (data) => post(url.GET_CRYPTO_LIST,data);
export const addCrypto = (data) => post(url.GET_CRYPTO_ADD, data);
export const updateCrypto = (data) => put(url.UPDATE_CRYPTO + data._id, data);
export const deleteCrypto = (id) => del(url.DELETE_CRYPTO+ id);
export const getCrypto = (data) => get(url.GET_CRYPTO + data);

//Currency
export const getCurrencyList = (data) => post(url.GET_CURRENCY_LIST,data);
export const addCurrency = (data) => post(url.GET_CURRENCY_ADD, data);
export const updateCurrency = (data) => put(url.UPDATE_CURRENCY + data._id, data);
export const deleteCurrency = (id) => del(url.DELETE_CURRENCY+ id);
export const getCurrency = (data) => get(url.GET_CURRENCY + data);


//crypto
export const getTopDappList = (data) => post(url.GET_TOPDAPP_LIST,data);
export const addTopDapp = (data) => post(url.GET_TOPDAPP_ADD, data);
export const updateTopDapp = (data) => put(url.UPDATE_TOPDAPP, data);
export const deleteTopDapp = (id) => post(url.DELETE_TOPDAPP, id);
export const getTopDapp = (data) => get(url.GET_TOPDAPP + data);

///support

export const getSupportList = (data)=> post(url.GET_SUPPORT_LIST, data)


// Customers
export const getContactEmails = (data) => post(url.GET_CONTACT_EMAIL, data);


// Content
export const getCONTENTs = (data) => post(url.GET_CONTENTS, data);
export const postCONTENT = (data) => post(url.ADD_CONTENT, data);
export const getCONTENT = (type) => get(url.GET_CONTENT +  type);
export const putCONTENT = (data) => put(url.PUT_CONTENT, data);
export const deleteCONTENT = (data) => del(url.DELETE_CONTENT, data);
export const putCONTENTsStatus = (data) => post(url.PUT_CONTENTS_STATUS, data);

// Blogs
export const getBlogsApi = (data) => post(url.GET_BLOGS, data);

export const addBlogsApi = (data) => post(url.ADD_BLOGS, data);
export const updateBlogsApi = (data) => put(url.UPDATE_BLOGS, data);
export const getByIdBlogApi = (data) => get(url.GET_BLOGS_BY_ID + data);
export const deleteBlogs = (data) => del(url.DELETE_BLOGS, data);

export const updateCompanyContent = (data) => post(url.UPDATE_COMPANY_PAGE_CONTENT, data)
export const getCompanyContent = (data) => get(url.GET_COMPANY_PAGE_CONTENT, data)