import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import {
  REGISTER_USER_EMAIL,
  REGISTER_STORE_NAME,
  REGISTER_USER,
} from "./actionTypes"
import {
  registerUserEmailSuccessful,
  registerUserEmailFailed,
  registerStoreNameSuccessful,
  registerStoreNameFailed,
  registerUserSuccessful,
  registerUserFailed,
} from "./actions"

//Include Both Helper File with needed methods
import {
  postRegisterEmail,
  postStoreName,
  postRegister,
} from "helpers/backend_helper"

function* registerUserEmail({ payload: { user, callback } }) {
  try {
    if (!user.policy) {
      window.scrollTo(0, 0)
      return yield put(
        registerUserEmailFailed("Please agree terms and condition!")
      )
    }
    const response = yield call(postRegisterEmail, user)

    if (response.status == "failure") {
      window.scrollTo(0, 0)
      return yield put(registerUserEmailFailed(response.message))
    }

    callback && callback()
    yield put(registerUserEmailSuccessful(response))
  } catch (error) {
    yield put(registerUserEmailFailed("Internal Server Error"))
  }
}

function* registerStoreName({ payload: { user, callback } }) {
  try {
    const response = yield call(postStoreName, user)

    if (response.status == "failure") {
      window.scrollTo(0, 0)
      return yield put(registerUserFailed(response.message))
    }

    callback && callback()
    yield put(registerStoreNameSuccessful(response))
  } catch (error) {
    yield put(registerUserFailed("Internal Server Error"))
  }
}

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    if (!user.policy) {
      window.scrollTo(0, 0)
      return yield put(registerUserFailed("Please agree terms and condition!"))
    }
    const response = yield call(postRegister, user)

    if (response.status == "failure") {
      window.scrollTo(0, 0)
      return yield put(registerUserFailed(response.message))
    }

    history.replace("/")
    yield put(registerUserSuccessful(response))
  } catch (error) {
    yield put(registerUserFailed("Internal Server Error"))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER_EMAIL, registerUserEmail)
  yield takeEvery(REGISTER_STORE_NAME, registerStoreName)
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
