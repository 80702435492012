import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
/* import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EditorUploadAdapterPlugin from "../../../plugins/ckeditor/UploadAdapterPlugin" */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FormButton from "components/Common/FormButtons";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import { addCONTENT, getCONTENT, putCONTENT, getCONTENTs } from "store/actions"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
let CONTENT_PAGES = [
  "TERMS_CONDITIONS",
  "PRIVACY_POLICY",

];
const AddCustomer = ({
  accessLevel,
  history,
  error,
  onAddContent,
  onGetContent,
  onPutContent,
  content,
  Contents,
  contentById,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);

  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    titleEn: "",
    contentEn: "",
    titleAr: "",
    contentAr: "",
    type: "ABOUT_US",
    status: "active",

  });

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetContent(id);
    }
  }, [id]);



  useEffect(() => {
    if (didMountRef.current) {
      if (!!contentById && !!contentById) {
        try {
          setFields({
            titleAr: contentById?.titleAr || "",
            titleEn: contentById?.titleEn || "",
            contentAr: contentById?.contentAr || "",
            contentEn: contentById?.contentEn || "",
            status: contentById?.status || "active",
            type: contentById?.type || "",
            // slug: contentById?.slug || ""
          });
        } catch (err) {
        }
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(contentById)]);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };



  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    if (id) {
      if (!accesses.canEdit) return;
      onPutContent({ _id: contentById?._id, ...fields }, history);
    } else {
      if (!accesses.canAdd) return;
      onAddContent({ ...fields }, history);
    }
  };

  const editorHandleChange = (evt) => {
    try {
      let data = evt.editor.getData();
      handleChange("content")({
        target: { value: data },
      });
    } catch (error) {
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Contents"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) + " " + props.t("content")
            }
            breadcrumbItems={[
              { title: props.t("Content"), link: "/content" },
              { title: id ? props.t("edit") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Type")}
                          </Label>
                          <Input
                            type={"select"}
                            id="formrow-firstname-Input"
                            value={fields.type}
                            onChange={handleChange("type")}
                          >
                            {CONTENT_PAGES.map((d) => (
                              <option value={d}>{d}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Title Arabic")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.titleAr}
                            onChange={handleChange("titleAr")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Title English")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.titleEn}
                            onChange={handleChange("titleEn")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>{props.t("Content Arabic")}</Label>
                          <div >
                            <CKEditor
                              data={fields.contentAr}
                              editor={ClassicEditor}
                              onChange={(evt, editor) => {
                                const data = editor?.getData();
                                handleChange("contentAr")({
                                  target: { value: data },
                                });
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>{props.t("Content English")}</Label>
                          <div >

                            <CKEditor
                              data={fields.contentEn}
                              editor={ClassicEditor}
                              onChange={(evt, editor) => {
                                const data = editor?.getData();
                                handleChange("contentEn")({
                                  target: { value: data },
                                });
                              }}


                            />
                          </div>
                         
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton
                      needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      goBack={() => history.goBack()}
                    />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  content: PropTypes.object,
  onAddContent: PropTypes.func,
  onGetContent: PropTypes.func,
  onPutContent: PropTypes.func,
}

const mapStateToProps = ({ content }) => ({
  error: content.error,
  loading: content.loading,
  contentById: content?.CONTENTBYID,
  Contents: content.CONTENTs,
})

const mapDispatchToProps = dispatch => ({
  onAddContent: (data, history) => dispatch(addCONTENT(data, history)),
  onGetContent: id => dispatch(getCONTENT(id)),
  onPutContent: (data, history) => dispatch(putCONTENT(data, history)),
  onGetContents: (data) => dispatch(getCONTENTs(data)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
)
