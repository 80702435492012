import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  addCurrency,
  uploadFile,
  removeFile,
  getCurrency,
  udpateCurrency,
} from "store/actions";

// Images
import avatar4 from "assets/images/users/symbol.png";

//i18n
import { withTranslation } from "react-i18next";

const AddCurrency = ({
  error,
  loading,
  onAddCurrency,
  onGetCurrency,
  onUploadFile,
  onRemoveFile,
  currencyDetails,
  onUdpateCurrency,
  ...props
}) => {
  const obj = JSON.parse(localStorage.getItem("authUser"));

  const [fields, setFields] = useState({
    author: obj.data._id,
    name: "",
    icon: null,
    symbol: "",
    status: "active",
  });

  const history = useHistory();
  const { id } = useParams();
  const [selectedFiles, setselectedFiles] = useState([]);

  const uploadFileSuccess = (response) => {
    if (response.status) {
      setFields((prevState) => ({
        ...prevState,
        icon: response.data.link,
      }));
    } else {
      toast.error(response.message);
    }
  };

  function handleAcceptedFiles(files) {
    if (selectedFiles.length > 0) {
      if (fields.icon) {
        onRemoveFile({ _id: fields.icon });

        setFields((prevState) => ({ ...prevState, icon: null }));
      }
    }

    onUploadFile({ file: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        _id: "abc",
      })
    );

    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  useEffect(() => {
    if (id) {
      onGetCurrency(id);
    }
  }, [id]);
  const onSubmit = (event) => {
    event.preventDefault();

    let data = { ...fields };

    if (!data.icon) {
      return toast.error("logo is required!")
    }

    if (loading) return;
    const CurrencyCallback = (response) => {
      if (response.status == "failure") {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        history.push("/currency");
      }
    };

    if (id) {
      onUdpateCurrency(
        {
          ...data,
          icon: data?.icon,
        },
        CurrencyCallback
      );
    } else {
      onAddCurrency(
        { ...data },
        CurrencyCallback
      );
    }
  };

  useEffect(() => {
    if (id) {
      setFields((prev) => ({ ...prev, ...currencyDetails, icon: currencyDetails?.icon || null }));
      setselectedFiles([{ preview: currencyDetails?.icon }]);
    }
  }, [currencyDetails]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>

            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Currency"
                breadcrumbItem={props.t("Add") + " " + props.t("Currency")}
                breadcrumbItems={[
                  { title: props.t("Currency"), link: "/Currency" },
                  { title: props.t("Add") },
                ]}
              />

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="formrow-profile-image-Input">
                      {props.t("Icon")}
                    </Label>

                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => {
                        const imageFile = selectedFiles[0];

                        return (
                          <div
                            className="dropzone-single-image avatar-xl"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              id="formrow-profile-image-Input"
                              multiple={false}
                            />

                            <img
                              className="rounded-circle avatar-xl"
                              alt={
                                !!imageFile && imageFile.name
                                  ? imageFile.name
                                  : "Soorx"
                              }
                              src={!!imageFile ? imageFile.preview : avatar4}
                            />

                            <div className="edit">
                              <i className="bx bx-pencil"></i>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Name")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.name}
                                onChange={handleChange("name")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Symbol")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.symbol}
                                onChange={handleChange("symbol")}
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("status")}</Label>
                              <div className="status-switch square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  switch="none"
                                  checked={fields.status == "active"}
                                  onChange={() => {
                                    const value =
                                      fields.status == "active"
                                        ? "inactive"
                                        : "active";

                                    handleChange("status")({
                                      target: { value },
                                    });
                                  }}
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label={props.t("active")}
                                  data-off-label={props.t("inactive")}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {props.t(id ? "Update" : "Add")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCurrency.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddCurrency: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

const mapStateToProps = ({ customers, currencies }) => ({
  error: customers.error,
  loading: customers.loading,
  currencyDetails: currencies?.CurrencyDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onAddCurrency: (data, callback) => dispatch(addCurrency(data, callback)),
  onGetCurrency: (data, callback) => dispatch(getCurrency(data, callback)),
  onUdpateCurrency: (data, callback) => dispatch(udpateCurrency(data, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCurrency))
);
