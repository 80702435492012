import moment from "moment-timezone";
import worldCountries from "world-countries";

export const ROLES = {
  admin: "ADMIN",
  staff: "STAFF",
  vendor: "VENDOR",
};
export const SERVICES = ["FOOD", "GROCERY", "MEDICINES"];
export const SERVICE_ICON = {
  customers: "bx bx-user-circle",
  faq: "bx bx-chat",
  contentPages: "bx bxs-book-content",
  globalSetting: "bx bx-cog",
  subAdmins: "fas fa-user-friends",
  transactions: "bx bx-transfer",
  FOOD: "mdi mdi-food",
  GROCERY: "mdi mdi-truck-delivery-outline",
  MEDICINES: "fas fa-clinic-medical",
  OTHER: "mdi-truck-fast",
  restaurant: "bx bx-user-circle",
  vendor: "bx bx-user-circle",
  // requests: "bx bx-git-pull-request",
  requests: "bx bx-shopping-bag",
  orders: "bx bx-shopping-bag",
  configuration: "bx bx-cog",
};
export const ADMIN_SERVICE_ROUTE = {
  customers: {
    title: "Customers",
    key: "customers",
    pageLocation: "Customers",
    icon: "bx bx-user-circle",
    hasProfile: true,
  },
  configuration: {
    title: "Configuration",
    key: "configuration",
    icon: "bx bx-cog",
    pageLocation: true,
    subItems: {
      faq: {
        title: "FAQs",
        key: "faqs",
        pageLocation: "Faq",
      },
      contentPages: {
        title: "Content Pages",
        key: "content-pages",
        pageLocation: "ContentPages",
        noAdd: true,
      },
      emailTemplate: {
        title: "Email Templates",
        key: "email-templates",
        pageLocation: "EmailTemplates",
        noAdd: true,
      },
      docTemplate: {
        title: "Document Templates",
        key: "document-templates",
        pageLocation: "Documents/Templates",
      },
      docTemplateFields: {
        title: "Document Templates",
        key: "document-templates/:templateId/fields",
        pageLocation: "Documents/Fields",
        isNotRequired: true,
      },
      globalSetting: {
        title: "Store Setting",
        key: "settings",
        pageLocation: "Settings",
      },
    },
  },
};
export const ADMIN_SUB_SERVICES_ROUTE = {
  restaurants: {
    title: "Restaurant",
    key: "restaurant",
    isDynamic: true,
    pageLocation: "FoodDelivery/Restaurant",
    isNotRequired: true,
    hasProfile: true,
    hasSettings: true,
  },
};
export const VENDOR_SERVICES_ROUTE = {
  orders: {
    title: "Orders",
    key: "orders",
  },
  promoCode: {
    title: "Promo Codes",
    key: "promo-codes",
    pageLocation: "FoodDelivery/PromoCodes",
  },
};
export const VENDOR_SERVICES = [
  { title: "Orders", key: "orders" },
  { title: "Category", key: "category" },
  { title: "Products", key: "products" },
  { title: "Addon", key: "add-on" },
  { title: "Promo Codes", key: "promo-codes" },
  { title: "Settings", key: "settings" },
];

export const SERVICE_NAMES = {
  FOOD: {
    vendor: "Restaurant",
    cuisine: "Cuisine",
  },
  GROCERY: {
    vendor: "Vendor",
    cuisine: "Brand",
  },
};

export const GET_SERVICE_NAME = ({ storeType, name = "vendor" }) => {
  if (!!SERVICE_NAMES[storeType] && !!SERVICE_NAMES[storeType][name]) {
    return SERVICE_NAMES[storeType][name];
  }

  return "Restaurant";
};

export const VENDOR_MENU = {
  FOOD: [
    {
      name: "Products",
      key: "productCount",
      to: `products`,
    },
    {
      name: "Category",
      key: "categoryCount",
      to: `category`,
    },
    {
      name: "Addon",
      key: "addonCount",
      to: `add-on`,
    },
  ],
  GROCERY: [
    {
      name: "Products",
      key: "productCount",
      to: `products`,
    },
    {
      name: "Category",
      key: "categoryCount",
      to: `category`,
    },
    {
      name: "Attributes",
      key: "attributeCount",
      to: `attributes`,
    },
  ],
};

export const CATEGORY_FORM = {
  FOOD: "simple",
  GROCERY: "subCat",
};

export const PRODUCT_FORM = {
  FOOD: {
    featured_image: true,
    images: false,
    manage_stock: false,
    stock_quantity: false,
    variations: false,
    categories: true,
    addon: true,
    cuisine: false,
  },
  GROCERY: {
    featured_image: true,
    images: true,
    manage_stock: true,
    stock_quantity: true,
    variations: true,
    categories: true,
    addon: false,
    cuisine: true,
  },
};

export const VENDOR_SETTINGS_FORM = {
  FOOD: {
    Cuisines: true,
  },
  GROCERY: {
    Cuisines: false,
  },
};

export const SERVICE_STATUS = [
  "Pending",
  "Confirmed",
  "In Route",
  "Arrived",
  "Completed",
];

export const SERVICE_REFUND_TYPE = [
  { label: "No Refund", value: "noRefund" },
  { label: "Full Refund", value: "fullRefund" },
  { label: "Partial Refund", value: "partialRefund" },
];

export const DELIVERY_MODES = [
  { label: "Takeaway", value: "TAKEAWAY" },
  { label: "Delivery", value: "DELIVERY" },
];

export const PAYMENT_METHODS = ["Stripe", "Pay Stack"];

export const SOCIAL_MEDIA = ["facebook", "twitter", "instagram"];

export const WEEK_NAMES = moment.weekdays();

export const LANGUAGES = [{ value: "en", label: "English" }];

export const TIMEZONES = moment.tz.names().map((name) => ({
  label: name,
  value: name,
  utc: "(GMT" + moment.tz(name).format("Z") + ")",
}));

export const COUNTRY_OPTIONS = worldCountries
  .filter(
    (country) => !!country.flag && country.name.common && country.idd.root
  )
  .map((country) => ({
    name: country.name.common,
    label: country.name.common,
    value: country.name.common,
    flag: country.flag,
    countryCode: country.idd.root + country.idd.suffixes[0] || "",
  }));

export const CURRENCY_OPTIONS = [
  { value: "USD", label: "$" },
  { value: "AUD", label: "$" },
  { value: "CAD", label: "$" },
  { value: "GBP", label: "£" },
  { value: "EUR", label: "€" },
];

export const FONT_OPTIONS = [
  { label: "Arial", value: "Arial" },
  { label: "Roboto", value: "Roboto" },
];

export const INPUT_TYPES = [
  { label: "Text", value: "text" },
  { label: "Date Picker", value: "datePicker" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Select", value: "select" },
  { label: "File", value: "file" },
];

export const STORE_KEYS = {
  mailgun: [
    { label: "MAILGUN API KEY", value: "MAILGUN_API_KEY" },
    { label: "MAILGUN DOMAIN", value: "MAILGUN_DOMAIN" },
    { label: "MAILGUN FROM", value: "MAILGUN_FROM" },
  ],
  twilio: [
    { label: "Account Sid", value: "accountSid" },
    { label: "Auth Token", value: "authToken" },
  ],
  firebase: [
    { label: "API Key", value: "FCM_APIKEY" },
    { label: "Auth Domain", value: "FCM_AUTHDOMAIN" },
    { label: "Project Id", value: "FCM_PROJECTID" },
    { label: "Storage Bucket", value: "FCM_STORAGEBUCKET" },
    { label: "Messaging Sender Id", value: "FCM_MESSAGINGSENDERID" },
    { label: "App Id", value: "FCM_APPID" },
    { label: "Measurement Id", value: "FCM_MEASUREMENTID" },
    { label: "Client Email", value: "FCM_CLIENT_EMAIL" },
    { label: "Private Key", value: "FCM_PRIVATE_KEY" },
  ],
};

export const LOGS_FILTER = [
  { label: "Direct Billing", value: "UPDATE_BILLING_DIRECT" },
  { label: "Invoice Billing", value: "UPDATE_BILLING_INVOICE" },
  { label: "Rebuild Script Update", value: "REBUILD_SCRIPT_UPDATE" },
  { label: "Rebuild Script Create", value: "REBUILD_SCRIPT_CREATE" },
  { label: "Update Setting", value: "UPDATE_SETTING" },
  { label: "Update Terminology", value: "UPDATE_TERMINOLOGY" },
  { label: "Create Terminology", value: "CREATE_TERMINOLOGY" },
];

export const STORE_FIELD_NAME = [
  { label: "Status", value: "adminStatus" },
  { label: "Billing Plan", value: "billingPlan" },
  /* { label: "Trial", value: "isTrial" }, */
];

export const STATUS_VALUE = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "InActive" },
  { value: "requested", label: "Requested" },
  { value: "blocked", label: "Blocked" },
];

export const TRIAL_VALUE = [
  { label: "Trial Only", value: true },
  { label: "Plan Only", value: false },
];

export const ACTIVITY_TYPE = {
  rent: "Rent Commission",
  minted: "Minted",
  transfer: "Transfer",
  admin_comission: "Admin Comission",
  bids: "Bids",
};

export const BLOCKCHAIN_CURRENCY_CODE = {
  ethereum: "ETH",
  polygon: "MATIC",
  BNB: "BNB",
  SOL: "SOL",
};
export const getNetworkUrl = (type, settings) => {
  if (settings.blockchain.length === 0) {
    return null;
  }

  let blockchain = settings.blockchain.filter((ele) => {
    return ele.status === true && ele.type === type;
  });

  if (blockchain.length === 0) {
    return null;
  }

  blockchain = blockchain[0];

  if (blockchain.networkUrl.length === 0) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  let blockchainNetworkUrl = blockchain.networkUrl.filter((ele) => {
    return ele.type === settings.blockchainNetworkMode;
  });

  blockchainNetworkUrl = blockchainNetworkUrl[0];

  if (!blockchainNetworkUrl.url) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return blockchainNetworkUrl;
};

export const getContractDetails = (type, settings, contractType = "token") => {

  
  if (
    settings[contractType] &&
    settings[contractType].contractAddress.length === 0
  ) {
    return null;
  }

  let contractAddress = settings[contractType].contractAddress.filter((ele) => {
    return ele.type === type;
  });

  if (contractAddress.length === 0) {
    return null;
  }

  contractAddress = contractAddress[0];

  if (!contractAddress[settings.blockchainNetworkMode]) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return {
    abi: settings[contractType].abi,
    contractAddress: contractAddress[settings.blockchainNetworkMode],
  };
};

export const sliceWalletAddress = (address, large) => {
  if (!address) {
    return "";
  }
  let strReturn;
  if (large) {
    strReturn = address?.slice(0, 10) + "..." + address?.slice(35);
  } else {
    strReturn = address?.slice(0, 6) + "..." + address?.slice(35, 40);
  }
  return strReturn;
};

export const copyToClipboard = (text) => {
  var textarea = document.createElement("textarea");
  textarea.textContent = text;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy"); // Security exception may be thrown by some browsers.
  } catch (ex) {
    console.warn("Copy to clipboard failed.", ex);
    // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
  } finally {
    document.body.removeChild(textarea);
  }
};
export function custmToFixed(x) {
  if (!x) {
    return "";
  }
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}


export function calcPercent(num, percentage, fixedValue) {
  let total = num * (percentage / 100);

  // console.log(Number(total).toFixed(fixedValue || 2), "Number(total).toFixed(fixedValue || 2)");
  return Number(total).toFixed(fixedValue || 2);
}


export const EXPLOREBYID = {
  1: "https://etherscan.io/",
  5: "https://goerli.etherscan.io/",
  11155111: "https://sepolia.etherscan.io/",
  137: "https://polygonscan.com/",
  80001: "https://mumbai.polygonscan.com/",
  56: "https://bscscan.com/",
  97: "https://testnet.bscscan.com/",
  43114: "https://subnets.avax.network/subnets",
  43113: "https://testnet.avascan.info/",
  250: "https://ftmscan.com/",
  25: "https://cronoscan.com/",
  338: "https://testnet.cronoscan.com/",
  11297108109: "https://explorer.palm.io/",
};
