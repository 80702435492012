import {
  GET_CRYPTO_LIST,
  GET_CRYPTO_LIST_SUCCESS,
  GET_CRYPTO_LIST_FAIL,
  ADD_CRYPTO,
  ADD_CRYPTO_SUCCESS,
  ADD_CRYPTO_FAIL,
  UPDATE_CRYPTO,
  UPDATE_CRYPTO_FAIL,
  UPDATE_CRYPTO_SUCCESS,
  DELETE_CRYPTO,
  DELETE_CRYPTO_SUCCESS,
  DELETE_CRYPTO_FAIL,
  GET_CRYPTO,
  GET_CRYPTO_SUCCESS,
  GET_CRYPTO_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  cryptoList: [],
  cryptoDetails: {},
  totalCrypto: 0
};

const Crypto = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get crypto */
    case GET_CRYPTO_LIST:
    case UPDATE_CRYPTO:
    case ADD_CRYPTO:
    case DELETE_CRYPTO:
    case GET_CRYPTO:
      return {
        ...state,
        loading: true,
      };

    case GET_CRYPTO_LIST_SUCCESS:
      return {
        ...state,
        cryptoList: action.payload.data || [],
        totalCrypto: action.payload.total || 0,
        loading: false,
        error: "",
      };
    case GET_CRYPTO_SUCCESS:
      return {
        ...state,
        cryptoDetails: action.payload.data,
        loading: false,
        error: "",
      };

    case GET_CRYPTO_LIST_FAIL:
    case UPDATE_CRYPTO_FAIL:
    case ADD_CRYPTO_FAIL:
    case UPDATE_CRYPTO_SUCCESS:
    case DELETE_CRYPTO_SUCCESS:
    case DELETE_CRYPTO_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get crypto END */

    default:
      return state;
  }
};

export default Crypto;
