import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  addCrypto,
  uploadFile,
  removeFile,
  getCrypto,
  udpateCrypto,
  addBlogs,
  getByIdBlogs,
  udpateBlogs,
} from "store/actions";

// Images
import avatar4 from "assets/images/logo-placeholder.png";

//i18n
import { withTranslation } from "react-i18next";

let TOKENS = [
  {
    symbol: "eth",
    label: "Ethereum"
  },
  {
    symbol: "bsc",
    label: "Binance"
  },
  {
    symbol: "poly",
    label: "Polygon"
  }

];

const AddBlogs = ({
  error,
  loading,
  onAddBlogs,
  onGetBlogs,
  onUploadFile,
  onRemoveFile,
  blogDetails,
  onUdpateBlogs,
  fileLoading,
  ...props
}) => {
  const obj = JSON.parse(localStorage.getItem("authUser"));


  const [fields, setFields] = useState({
    author: obj.data._id,
    titleAr: "",
    titleEn: "",
    blogImage: null,
    descriptionAr: "",
    descriptionEn: "",
    status: "active",



  });

  const history = useHistory();
  const { id } = useParams();
  const [selectedFiles, setselectedFiles] = useState([]);



  function handleAcceptedFiles(files) {
    if (selectedFiles.length > 0) {
      if (fields.blogImage) {
        onRemoveFile({ _id: fields.blogImage });
        setFields((prevState) => ({ ...prevState, blogImage: null }));
      }
    }


    const uploadFileSuccess = (response) => {
      if (response.status) {
        setselectedFiles(files);
        setFields((prevState) => ({
          ...prevState,
          blogImage: response.data._id,
        }));
      } else {
        toast.error(response.message);
      }
    };

    onUploadFile({ file: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        _id: "abc",
      })
    );

  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  useEffect(() => {
    if (id) {
      onGetBlogs(id);
    }
  }, [id]);
  const onSubmit = (event) => {
    event.preventDefault();

    let data = { ...fields };

    if (!data?.blogImage) {
      return toast.error("logo is required!")
    }

    if (loading) return;
    const BlogsCallback = (response) => {
      if (response.status == "failure") {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        history.push("/blogs");
      }
    };

    if (id) {
      onUdpateBlogs(
        {
          titleAr: data?.titleAr,
          titleEn: data?.titleEn,
          descriptionAr: data?.descriptionAr,
          descriptionEn: data?.descriptionEn,
          status: data?.status,
          blogImage: blogDetails?.blogImage?._id || data?.blogImage,
          _id: id
        },
        BlogsCallback
      );
    } else {
      onAddBlogs(
        { ...data },
        BlogsCallback
      );
    }
  };

  useEffect(() => {
    if (id) {
      setFields((prev) => ({ ...prev, ...blogDetails, blogImage: blogDetails?.blogImage?.link || null }));
      setselectedFiles([{ preview: blogDetails?.blogImage?.link }]);
    }
  }, [blogDetails]);



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>

            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Blogs"
                breadcrumbItem={props.t("Add") + " " + props.t("Blogs")}
                breadcrumbItems={[
                  { title: props.t("Blogs"), link: "/Blogs" },
                  { title: id ? props.t("Edit") : props.t("Add") },
                ]}
              />

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="formrow-profile-image-Input">
                      {props.t("Image")}
                    </Label>

                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => {
                        const imageFile = selectedFiles[0];

                        return (
                          <div
                            className="dropzone-single-image avatar-xl"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              id="formrow-profile-image-Input"
                              multiple={false}
                            />

                            <img
                              className="rounded-circle avatar-xl"
                              alt={
                                !!imageFile && imageFile.name
                                  ? imageFile.name
                                  : "Soorx"
                              }
                              src={!!imageFile ? imageFile.preview : avatar4}
                            />

                            <div className="edit">
                              <i className="bx bx-pencil"></i>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Title English")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.titleEn}
                                onChange={handleChange("titleEn")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Title Arabic")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.titleAr}
                                onChange={handleChange("titleAr")}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>Description English</Label>

                              <Input
                                type="textarea"
                                value={fields.descriptionEn}
                                onChange={handleChange("descriptionEn")}


                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>Description Arabic</Label>

                              <Input
                                type="textarea"
                                value={fields.descriptionAr}
                                onChange={handleChange("descriptionAr")}


                              />
                            </FormGroup>
                          </Col>








                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("status")}</Label>
                              <div className="status-switch square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  switch="none"
                                  checked={fields.status == "active"}
                                  onChange={() => {
                                    const value =
                                      fields.status == "active"
                                        ? "inactive"
                                        : "active";

                                    handleChange("status")({
                                      target: { value },
                                    });
                                  }}
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label={props.t("active")}
                                  data-off-label={props.t("inactive")}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {props.t(id ? "Update" : "Add")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                          {fileLoading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddBlogs.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddBlogs: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

const mapStateToProps = ({ customers, Blogs, File }) => ({
  error: customers.error,
  loading: customers.loading,
  blogDetails: Blogs?.blogDetails,
  fileLoading: File?.fileLoading,

});

const mapDispatchToProps = (dispatch) => ({
  onAddBlogs: (data, callback) => dispatch(addBlogs(data, callback)),
  onGetBlogs: (data, callback) => dispatch(getByIdBlogs(data, callback)),
  onUdpateBlogs: (data, callback) => dispatch(udpateBlogs(data, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddBlogs))
);
