import {
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
  PUT_SETTINGS,
  PUT_SETTINGS_FAIL,
  PUT_SETTINGS_SUCCESS,
  GET_ACCESS_LIST,
  GET_ACCESS_LIST_SUCCESS,
  GET_ACCESS_LIST_FAIL,
  GET_STORE_TYPES,
  GET_STORE_TYPES_SUCCESS,
  GET_STORE_TYPES_FAIL,
  GET_STARTED,
  GET_STARTED_SUCCESS,
  GET_STARTED_FAIL,
  POST_CONTRACT_DEPLOY,
  POST_CONTRACT_DEPLOY_SUCCESS,
  POST_CONTRACT_DEPLOY_FAIL,


  POST_COMPILE_CONTRACT,
  POST_COMPILE_CONTRACT_SUCCESS,
  POST_COMPILE_CONTRACT_FAIL,
} from "./actionTypes"


import * as CONST from "./actionTypes";


const SETTINGS = {}

const INIT_STATE = {
  loading: false,
  settings: SETTINGS,
  starterLoading: false,
  error: "",
}

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Global Settings */
    case GET_SETTINGS:
      return {
        ...state,
        loading: true,
        storeLoading: true,
        isStoreFailed: false,
        error: "",
      }

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        settings: action.payload,
        isStoreFailed: false,
        storeLoading: false,
      }

    case GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        storeLoading: false,
        isStoreFailed: true,
      }
    /* Get Global Settings END */

    /* Update Global Settings */
    case PUT_SETTINGS:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case PUT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      }

    case PUT_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Update Global Settings END */

    /* Get Access List */
    case GET_ACCESS_LIST:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case GET_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        accessList: action.payload,
      }

    case GET_ACCESS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Access List END */

    /* Get Store Types */
    case GET_STORE_TYPES:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case GET_STORE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        storeTypes: action.payload,
      }

    case GET_STORE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Store Types END */

    /* Get Store Started */
    case GET_STARTED:
      return {
        ...state,
        starterLoading: true,
      }

    case GET_STARTED_SUCCESS:
      return {
        ...state,
        starterLoading: false,
        getStarted: action.payload,
      }

    case GET_STARTED_FAIL:
      return {
        ...state,
        starterLoading: false,
        error: action.payload,
        getStarted: {},
      }
    /* Get Store Started END */

    /* Post Deploy Contract */
    case POST_CONTRACT_DEPLOY:
      return {
        ...state,
        loading: true,
      }

    case POST_CONTRACT_DEPLOY_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case POST_CONTRACT_DEPLOY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    /* Post Deploy Contract END */


      /* Post Compile Contract */
      case POST_COMPILE_CONTRACT:
        return {
          ...state,
          loading: true,
        };
  
      case POST_COMPILE_CONTRACT_SUCCESS:
        return {
          ...state,
          loading: false,
          error: "",
        };
  
      case POST_COMPILE_CONTRACT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      /* Post Compile Contract END */



        /* Post Deploy Contract */
    case CONST.POST_BORROW_CONTRACT_DEPLOY:
      return {
        ...state,
        loading: true,
      }

    case CONST.POST_BORROW_CONTRACT_DEPLOY_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case CONST.POST_BORROW_CONTRACT_DEPLOY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    /* Post Deploy Contract END */


      /* Post Compile Contract */
      case CONST.POST_COMPILE_BORROW_CONTRACT:
        return {
          ...state,
          loading: true,
        };
  
      case CONST.POST_COMPILE_BORROW_CONTRACT_SUCCESS:
        return {
          ...state,
          loading: false,
          error: "",
        };
  
      case CONST.POST_COMPILE_BORROW_CONTRACT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      /* Post Compile Contract END */

    default:
      return state
  }
}

export default Settings
