import {
 
  ADD_BLOGS, ADD_BLOGS_FAIL, ADD_BLOGS_SUCCESS,
  GET_BLOGS,GET_BLOGS_FAIL,GET_BLOGS_SUCCESS,
  UPDATE_BLOGS,UPDATE_BLOGS_FAIL,UPDATE_BLOGS_SUCCESS,
  BLOGS_BY_ID, BLOGS_BY_ID_FAIL, BLOGS_BY_ID_SUCCESS,
  DELETE_BLOGS,DELETE_BLOGS_FAIL,DELETE_BLOGS_SUCCESS
} from "./actionTypes"


/* Get Customer */
export const getBlogs = (id) => ({
  type: GET_BLOGS,
  payload: id,
})

export const getBlogsFail = (error) => ({
  type: GET_BLOGS_FAIL,
  payload: error,
})

export const getBlogsSuccess = (data) => ({
  type: GET_BLOGS_SUCCESS,
  payload: data,
})





export const addBlogs = (data, callback) => ({
  type: ADD_BLOGS,
  payload: { data, callback },
});

export const addBlogsSuccess = (data) => ({
  type: ADD_BLOGS_SUCCESS,
  payload: data,
});

export const addBlogsFail = (error) => ({
  type: ADD_BLOGS_FAIL,
  payload: error,
});

export const udpateBlogs = (data, callback) => ({
  type: UPDATE_BLOGS,
  payload: { data, callback },
});

export const udpateBlogsSuccess = (crypto) => ({
  type: UPDATE_BLOGS_SUCCESS,
  payload: crypto,
});

export const udpateBlogsFail = (error) => ({
  type: UPDATE_BLOGS_FAIL,
  payload: error,
});
export const getByIdBlogs = (data, callback) => ({
  type: BLOGS_BY_ID,
  payload: { data, callback },
});

export const getByIdBlogsSuccess = (data) => ({
  type: BLOGS_BY_ID_SUCCESS,
  payload: data,
});

export const getByIdBlogsFail = (error) => ({
  type: BLOGS_BY_ID_FAIL,
  payload: error,
});

export const deleteBlogs = (data, callback) => ({
  type: DELETE_BLOGS,
  payload: { data, callback },
});

export const deleteBlogsSuccess = (crypto) => ({
  type: DELETE_BLOGS_SUCCESS,
  payload: crypto,
});

export const deleteBlogsFail = (error) => ({
  type: DELETE_BLOGS_FAIL,
  payload: error,
});