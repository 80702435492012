import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { post } from "../../../helpers/api_helper";
import { STATUS_CHANGE_BY_ADMIN } from "../../../helpers/url_helper";
import userData from "../CustomersList/data.json";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getCustomer } from "store/actions";

const ViewCustomer = (props) => {
  const {
    match: { params },
    onGetCustomer,
    customer: reducerCustomer,
    error,
    loading,
  } = props;

  const [customer, setcustomer] = useState(null);
  const [Status, setStatus] = useState(customer?.adminStatus || null);
  const [statusData, setStatusData] = useState(customer?.adminStatus || null);

  const getCustomerFromJson = (id) => {
    if (id) {
      const getUser = userData?.find((_d) => _d._id == id);

      if (getUser) {
        setcustomer(getUser);
      }
    }
  };

  useEffect(() => {
    if (params && params.id) {
      // onGetCustomer(params.id);
      getCustomerFromJson(params.id);
    }
  }, [params]);
  useEffect(() => {
    setStatus(customer?.adminStatus);
  }, [customer]);

  return (
    <React.Fragment>
      {loading && (
        <div className="page-content">
          <Spinner color="primary" />
        </div>
      )}
      
      {!isEmpty(customer) && !loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Users" breadcrumbItem="Merchant Details" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <p className="text-primary">{customer.role}</p>
                          <h5 className="mt-1 mb-3">{customer.name}</h5>
                          <h5 className="mt-1 mb-3">
                            Wallet Address - {customer.walletAddress}
                          </h5>
                          <h5 className="mt-1 mb-3 text-muted">
                            Email - {customer.email}
                          </h5>

                          <div className="text-muted float-left mr-3 mb-3">
                            <p>Average Rating - {customer.avgRating}</p>
                            {/* <StarRatings
                              rating={customer.avgRating}
                              starRatedColor="#F1B44C"
                              starEmptyColor="#2D363F"
                              numberOfStars={5}
                              name="rating"
                              starDimension="14px"
                              starSpacing="3px"
                            /> */}
                          </div>
                          <h6 className="text-success text-uppercase">
                            {customer.reviewCount} Total Reviews
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    {/* {customer?.reviewCount > 0 && (
                      <div className="mt-5">
                        <h5 className="mb-4">Reviews :</h5>
                        {customer.reviews.map((comments) => (
                          <p className="text-muted">{comments}</p>
                        ))}
                      </div>
                    )} */}
                    <table class="table table table-centered table-nowrap">
                      <thead class="thead-light">
                        <tr>
                          <th
                            tabindex="0"
                            aria-label="Name sortable"
                            class="sortable"
                          >
                            Name
                          </th>
                          <th
                            tabindex="0"
                            aria-label="Email sortable"
                            class="sortable"
                          >
                            Email
                          </th>
                          <th
                            tabindex="0"
                            aria-label="Wallet Address sortable"
                            class="sortable"
                          >
                            Wallet Address
                          </th>
                          <th tabindex="0">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                      {customer?.merchantUserList?.length > 0 ? (
                        customer?.merchantUserList.map((_d)=>(

                        <>
                            <tr>
                              <td>{_d.name}</td>
                              <td>{_d.email}</td>
                              <td>0x65456456456545454...</td>
                              <td>
                                <span class="text-capitalize font-size-13 badge-soft-warning badge badge-secondary">
                                  Active
                                </span>
                              </td>
                            </tr>
                        </>
                        ))
                      ) : (
                        <tr>
                          {" "}
                          <td>No User Found</td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                    {/* {customer?.adminStatus && (
                      <div className="status-switch square-switch">
                        <input
                          className="switcher"
                          type="checkbox"
                          id="square-switch1"
                          switch="none"
                          checked={Status === "active" ? true : false}
                          value={statusData}
                          onChange={() => {
                            handleChange("AdminStatusChanges")({
                              target: {
                                value:
                                  Status === "active" ? "active" : "inactive",
                              },
                            });
                          }}
                        />
                        <label
                          htmlFor="square-switch1"
                          data-on-label="active"
                          data-off-label="inactive"
                        />
                      </div>
                    )} */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ViewCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  customer: PropTypes.any,
  onGetCustomer: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getCustomer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
