import {
  LOG_API_FAIL,
  GET_LOGS,
  GET_LOGS_FAIL,
  GET_LOGS_SUCCESS,
  POST_REBUILD_SCRIPT,
  POST_REBUILD_SCRIPT_FAIL,
  POST_REBUILD_SCRIPT_SUCCESS,
  POST_STORE_REBUILD_SCRIPT,
  POST_STORE_REBUILD_SCRIPT_FAIL,
  POST_STORE_REBUILD_SCRIPT_SUCCESS,
  POST_STORE_SETTINGS_SCRIPT,
  POST_STORE_SETTINGS_SCRIPT_FAIL,
  POST_STORE_SETTINGS_SCRIPT_SUCCESS,
  POST_CLEAR_LOGS,
  POST_CLEAR_LOGS_FAIL,
  POST_CLEAR_LOGS_SUCCESS,
} from "./actionTypes"

export const apiFail = (error) => ({
  type: LOG_API_FAIL,
  payload: error,
})

/* Get LOGs */
export const getLogs = (data) => ({
  type: GET_LOGS,
  payload: data,
})

export const getLogsSuccess = (logs) => ({
  type: GET_LOGS_SUCCESS,
  payload: logs,
})

export const getLogsFail = (error) => ({
  type: GET_LOGS_FAIL,
  payload: error,
})
/* Get Logs END */

/* Rebuild Script */
export const postRebuildScript = (callback) => ({
  type: POST_REBUILD_SCRIPT,
  payload: callback,
})

export const postRebuildScriptSuccess = () => ({
  type: POST_REBUILD_SCRIPT_SUCCESS,
})

export const postRebuildScriptFail = (error) => ({
  type: POST_REBUILD_SCRIPT_FAIL,
  payload: error,
})
/* Rebuild Script END */

/* Store Rebuild Script */
export const postStoreRebuildScript = (data, callback) => ({
  type: POST_STORE_REBUILD_SCRIPT,
  payload: { data, callback },
})

export const postStoreRebuildScriptSuccess = () => ({
  type: POST_STORE_REBUILD_SCRIPT_SUCCESS,
})

export const postStoreRebuildScriptFail = (error) => ({
  type: POST_STORE_REBUILD_SCRIPT_FAIL,
  payload: error,
})
/* Store Rebuild Script END */

/* Store Settigns Script */
export const postStoreSettingsScript = (data, callback) => ({
  type: POST_STORE_SETTINGS_SCRIPT,
  payload: { data, callback },
})

export const postStoreSettingsScriptSuccess = () => ({
  type: POST_STORE_SETTINGS_SCRIPT_SUCCESS,
})

export const postStoreSettingsScriptFail = (error) => ({
  type: POST_STORE_SETTINGS_SCRIPT_FAIL,
  payload: error,
})
/* Store Settings Script END */

/* Clear Logs */
export const postClearLogs = (data, callback) => ({
  type: POST_CLEAR_LOGS,
  payload: { data, callback }
})

export const postClearLogsSuccess = () => ({
  type: POST_CLEAR_LOGS_SUCCESS,
})

export const postClearLogsFail = (error) => ({
  type: POST_CLEAR_LOGS_FAIL,
  payload: error
})
/* Clear Logs END */