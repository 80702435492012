import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  addCrypto,
  uploadFile,
  removeFile,
  getCrypto,
  udpateCrypto,
} from "store/actions";

// Images
import avatar4 from "assets/images/users/symbol.png";

//i18n
import { withTranslation } from "react-i18next";

let TOKENS = [
  {
    symbol: "eth",
    label: "Ethereum",
  },
  {
    symbol: "bsc",
    label: "Binance",
  },
  {
    symbol: "poly",
    label: "Polygon",
  },
];

const AddCrypto = ({
  error,
  loading,
  onAddCrypto,
  onGetCrypto,
  onUploadFile,
  onRemoveFile,
  cryptoDetails,
  onUdpateCrypto,
  ...props
}) => {
  const obj = JSON.parse(localStorage.getItem("authUser"));

  const [fields, setFields] = useState({
    author: obj.data._id,
    name: "",
    icon: null,
    testnet_symbol: "",
    mainnet_symbol: "",
    status: "active",
    type: "coin",
    chain: TOKENS[0].symbol,
    fire_block_asset_id: "",
    tokenAddress: ""
  });

  const history = useHistory();
  const { id } = useParams();
  const [selectedFiles, setselectedFiles] = useState([]);

  const uploadFileSuccess = (response) => {
    if (response.status) {
      setFields((prevState) => ({
        ...prevState,
        icon: response.data.link,
      }));
    } else {
      toast.error(response.message);
    }
  };

  function handleAcceptedFiles(files) {
    if (selectedFiles.length > 0) {
      if (fields.icon) {
        onRemoveFile({ _id: fields.icon });

        setFields((prevState) => ({ ...prevState, icon: null }));
      }
    }

    onUploadFile({ file: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        _id: "abc",
      })
    );

    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  useEffect(() => {
    if (id) {
      onGetCrypto(id);
    }
  }, [id]);
  const onSubmit = (event) => {
    event.preventDefault();

    let data = { ...fields };

    if (!data.icon) {
      return toast.error("logo is required!");
    }

    if (loading) return;
    const CryptoCallback = (response) => {
      if (response.status == "failure") {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        history.push("/crypto");
      }
    };

    if (id) {
      onUdpateCrypto(
        {
          ...data,
          icon: data?.icon,
        },
        CryptoCallback
      );
    } else {
      onAddCrypto({ ...data }, CryptoCallback);
    }
  };

  useEffect(() => {
    if (id) {
      setFields((prev) => ({
        ...prev,
        ...cryptoDetails,
        icon: cryptoDetails.icon || null,
      }));
      setselectedFiles([{ preview: cryptoDetails?.icon }]);
    }
  }, [cryptoDetails]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>

            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Crypto"
                breadcrumbItem={props.t("Add") + " " + props.t("Crypto")}
                breadcrumbItems={[
                  { title: props.t("Crypto"), link: "/Crypto" },
                  { title: props.t("Add") },
                ]}
              />

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="formrow-profile-image-Input">
                      {props.t("Icon")}
                    </Label>

                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => {
                        const imageFile = selectedFiles[0];

                        return (
                          <div
                            className="dropzone-single-image avatar-xl"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              id="formrow-profile-image-Input"
                              multiple={false}
                            />

                            <img
                              className="rounded-circle avatar-xl"
                              alt={
                                !!imageFile && imageFile.name
                                  ? imageFile.name
                                  : "Soorx"
                              }
                              src={!!imageFile ? imageFile.preview : avatar4}
                            />

                            <div className="edit">
                              <i className="bx bx-pencil"></i>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Name")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.name}
                                onChange={handleChange("name")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Test Net Symbol")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.testnet_symbol}
                                onChange={handleChange("testnet_symbol")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Main Net Symbol")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.mainnet_symbol}
                                onChange={handleChange("mainnet_symbol")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Fireblock asset Id Mainnet")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.fire_block_asset_id}
                                onChange={handleChange("fire_block_asset_id")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Type")}
                              </Label>
                              <div className="status-switch square-switch">
                                <input
                                  className="switcher"
                                  type="checkbox"
                                  id="type"
                                  switch="none"
                                  checked={fields.type === "coin"}
                                  onChange={() => {
                                    handleChange("type")({
                                      target: {
                                        value:
                                          fields.type === "coin"
                                            ? "token"
                                            : "coin",
                                      },
                                    });
                                  }}
                                />
                                <label
                                  htmlFor="type"
                                  data-on-label="Coin"
                                  data-off-label="Token"
                                />
                              </div>
                            </FormGroup>
                          </Col>

                          {fields.type === "token" && (
                            <>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="formrow-firstname-Input">
                                    {props.t("Chain")}
                                  </Label>
                                  <Input
                                    type={"select"}
                                    id="formrow-firstname-Input"
                                    value={fields.chain}
                                    onChange={handleChange("chain")}
                                  >
                                    {TOKENS.map((obj) => (
                                      <option value={obj.symbol}>
                                        {obj.label}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="formrow-firstname-Input">
                                    {props.t("Token Address")}
                                  </Label>
                                  <Input
                                    type="text"
                                    id="formrow-firstname-Input"
                                    value={fields.tokenAddress}
                                    onChange={handleChange(
                                      "tokenAddress"
                                    )}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}
                        </Row>

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("status")}</Label>
                              <div className="status-switch square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  switch="none"
                                  checked={fields.status == "active"}
                                  onChange={() => {
                                    const value =
                                      fields.status == "active"
                                        ? "inactive"
                                        : "active";

                                    handleChange("status")({
                                      target: { value },
                                    });
                                  }}
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label={props.t("active")}
                                  data-off-label={props.t("inactive")}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {props.t(id ? "Update" : "Add")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCrypto.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddCrypto: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

const mapStateToProps = ({ customers, crypto }) => ({
  error: customers.error,
  loading: customers.loading,
  cryptoDetails: crypto?.cryptoDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onAddCrypto: (data, callback) => dispatch(addCrypto(data, callback)),
  onGetCrypto: (data, callback) => dispatch(getCrypto(data, callback)),
  onUdpateCrypto: (data, callback) => dispatch(udpateCrypto(data, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCrypto))
);
