import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";

import { Row, Col, FormGroup, FormText, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

// Images
import logo_placeholder from "assets/images/logo-placeholder.png";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;

  const [imageselectedFiles, setimageselectedFiles] = useState([]);

  useEffect(() => {
    if (fields?.images) {
      setimageselectedFiles([{ preview: fields?.image }]);
    }
  }, [JSON.stringify(fields?.image)]);

  const uploadFileSuccess = (card, name, index) => (response) => {
    const { link } = response.data;

    handleChange(card)(name,index)({ target: { value: link } });
  };

  const handleAcceptedFiles = (card) => (name, index) => (_files) => {
    const files = _files?.filter((file) => file.size < 5242880);

    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }

    onUploadFile({ file: files[0] }, uploadFileSuccess(card, name, index));

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    if (name === "image") {
      setimageselectedFiles(files);
    }
  };
  // alert("yaa");

  return (
    <React.Fragment>
      <div className="my-4">
        <Row>
          <Col lg={12}>
            <Instructions t={props.t} />
          </Col>

          {/* <Col md={6}>
            <FormGroup>
              <Label>image</Label>

              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles("image")(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => {
                  const imageFile = imageselectedFiles[0];

                  return (
                    <div
                      className="dropzone-single-image avatar-xl border"
                      {...getRootProps()}
                    >
                      <input
                        {...getInputProps()}
                        id="formrow-profile-image-Input"
                        multiple={false}
                      />

                      <img
                        className="rounded avatar-xl"
                        alt={
                          !!imageFile && imageFile.name ? imageFile.name : "hlc"
                        }
                        src={!!imageFile ? imageFile.preview : logo_placeholder}
                      />

                      <div className="edit">
                        <i className="bx bx-pencil"></i>
                      </div>
                    </div>
                  );
                }}
              </Dropzone>
              <FormText>Max. upload file size: 5MB</FormText>
            </FormGroup>
          </Col> */}

          <Col lg={12}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Heading 1 English</Label>
                  <Input
                    type="text"
                    value={fields?.heading1En}
                    onChange={(e) => handleChange(null)("heading1En")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Heading 1 Arabic</Label>
                  <Input
                    type="text"
                    value={fields?.heading1Ar}
                    onChange={(e) => handleChange(null)("heading1Ar")(e)}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>Description English</Label>
                  <Input
                    type="textarea"
                    value={fields?.descriptionEn}
                    onChange={(e) => handleChange(null)("descriptionEn")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Description Arabic</Label>
                  <Input
                    type="textarea"
                    value={fields?.descriptionAr}
                    onChange={(e) => handleChange(null)("descriptionAr")(e)}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>Card 1 Heading 1 English</Label>
                  <Input
                    type="text"
                    value={fields?.card1?.heading1En}
                    onChange={(e) => handleChange("card1")("heading1En")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 1 Heading 1 Arabic</Label>
                  <Input
                    type="text"
                    value={fields?.card1?.heading1Ar}
                    onChange={(e) => handleChange("card1")("heading1Ar")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 1 Description English</Label>
                  <Input
                    type="textarea"
                    value={fields?.card1?.descriptionEn}
                    onChange={(e) => handleChange("card1")("descriptionEn")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 1 Description Arabic</Label>
                  <Input
                    type="textarea"
                    value={fields?.card1?.descriptionAr}
                    onChange={(e) => handleChange("card1")("descriptionAr")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 2 Heading 1 English</Label>
                  <Input
                    type="text"
                    value={fields?.card2?.heading1En}
                    onChange={(e) => handleChange("card2")("heading1En")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 2 Heading 1 Arabic</Label>
                  <Input
                    type="text"
                    value={fields?.card2?.heading1Ar}
                    onChange={(e) => handleChange("card2")("heading1Ar")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 2 Description English</Label>
                  <Input
                    type="textarea"
                    value={fields?.card2?.descriptionEn}
                    onChange={(e) => handleChange("card2")("descriptionEn")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 2 Description Arabic</Label>
                  <Input
                    type="textarea"
                    value={fields?.card2?.descriptionAr}
                    onChange={(e) => handleChange("card2")("descriptionAr")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 2 Crypto Count</Label>
                  <Input
                    type="text"
                    value={fields?.card2?.cryptoCount}
                    onChange={(e) => handleChange("card2")("cryptoCount")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 2 Countries Count</Label>
                  <Input
                    type="text"
                    value={fields?.card2?.countriesCount}
                    onChange={(e) => handleChange("card2")("countriesCount")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 2 User Count</Label>
                  <Input
                    type="text"
                    value={fields?.card2?.userCount}
                    onChange={(e) => handleChange("card2")("userCount")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}></Col>

              {/* Card 3 */}
              <Col md={6}>
                <FormGroup>
                  <Label>Card 3 Heading 1 English</Label>
                  <Input
                    type="text"
                    value={fields?.card3?.heading1En}
                    onChange={(e) => handleChange("card3")("heading1En")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 3 Heading 1 Arabic</Label>
                  <Input
                    type="text"
                    value={fields?.card3?.heading1Ar}
                    onChange={(e) => handleChange("card3")("heading1Ar")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 3 Description English</Label>
                  <Input
                    type="textarea"
                    value={fields?.card3?.descriptionEn}
                    onChange={(e) => handleChange("card3")("descriptionEn")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 3 Description Arabic</Label>
                  <Input
                    type="textarea"
                    value={fields?.card3?.descriptionAr}
                    onChange={(e) => handleChange("card3")("descriptionAr")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Card 3 Images</Label>
                  <Row>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <Col md={6}>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles("card3")("images", index)(
                              acceptedFiles
                            );
                          }}
                        >
                          {({ getRootProps, getInputProps }) => {
                            const imageFile = fields?.card3?.images[index];

                            return (
                              <div
                                className="dropzone-single-image avatar-xl border"
                                {...getRootProps()}
                              >
                                <input
                                  {...getInputProps()}
                                  id="formrow-profile-image-Input"
                                  multiple={false}
                                />

                                <img
                                  className="rounded avatar-xl"
                                  alt={
                                    !!imageFile && imageFile?.name
                                      ? imageFile?.name
                                      : "hlc"
                                  }
                                  src={
                                    !!imageFile
                                      ? imageFile.url
                                      : logo_placeholder
                                  }
                                />

                                <div className="edit">
                                  <i className="bx bx-pencil"></i>
                                </div>
                              </div>
                            );
                          }}
                        </Dropzone>
                      </Col>
                    ))}
                  </Row>
                  {/* <Button onClick={handleAddCard3Image}>Add Image</Button> */}
                </FormGroup>
              </Col>

              {/* Card 4 */}
              <Col md={6}>
                <FormGroup>
                  <Label>Card 4 Heading 1 English</Label>
                  <Input
                    type="text"
                    value={fields?.card4?.heading1En}
                    onChange={(e) => handleChange("card4")("heading1En")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 4 Heading 1 Arabic</Label>
                  <Input
                    type="text"
                    value={fields?.card4?.heading1Ar}
                    onChange={(e) => handleChange("card4")("heading1Ar")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 4 Description English</Label>
                  <Input
                    type="textarea"
                    value={fields?.card4?.descriptionEn}
                    onChange={(e) => handleChange("card4")("descriptionEn")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 4 Description Arabic</Label>
                  <Input
                    type="textarea"
                    value={fields?.card4?.descriptionAr}
                    onChange={(e) => handleChange("card4")("descriptionAr")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Card 4 Images</Label>
                  <Row>
                    {Array.from({ length: 10 }).map((_, index) => (
                      <Col md={6}>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles("card4")("images", index)(
                              acceptedFiles
                            );
                          }}
                        >
                          {({ getRootProps, getInputProps }) => {
                            const imageFile = fields?.card4?.images[index];

                            return (
                              <div
                                className="dropzone-single-image avatar-xl border"
                                {...getRootProps()}
                              >
                                <input
                                  {...getInputProps()}
                                  id="formrow-profile-image-Input"
                                  multiple={false}
                                />

                                <img
                                  className="rounded avatar-xl"
                                  alt={
                                    !!imageFile && imageFile?.name
                                      ? imageFile?.name
                                      : "hlc"
                                  }
                                  src={
                                    !!imageFile
                                      ? imageFile.url
                                      : logo_placeholder
                                  }
                                />

                                <div className="edit">
                                  <i className="bx bx-pencil"></i>
                                </div>
                              </div>
                            );
                          }}
                        </Dropzone>
                      </Col>
                    ))}
                  </Row>
                  {/* <Button onClick={handleAddCard3Image}>Add Image</Button> */}
                </FormGroup>
              </Col>

              {/* Card 5 */}
              <Col md={6}>
                <FormGroup>
                  <Label>Card 5 Heading 1 English</Label>
                  <Input
                    type="text"
                    value={fields?.card5?.heading1En}
                    onChange={(e) => handleChange("card5")("heading1En")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 5 Heading 1 Arabic</Label>
                  <Input
                    type="text"
                    value={fields?.card5?.heading1Ar}
                    onChange={(e) => handleChange("card5")("heading1Ar")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 5 Description English</Label>
                  <Input
                    type="textarea"
                    value={fields?.card5?.descriptionEn}
                    onChange={(e) => handleChange("card5")("descriptionEn")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Card 5 Description Arabic</Label>
                  <Input
                    type="textarea"
                    value={fields?.card5?.descriptionAr}
                    onChange={(e) => handleChange("card5")("descriptionAr")(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Card 5 Images</Label>
                  <Row>
                    {Array.from({ length: 1 }).map((_, index) => (
                      <Col md={6}>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles("card5")("images", index)(
                              acceptedFiles
                            );
                          }}
                        >
                          {({ getRootProps, getInputProps }) => {
                            const imageFile = fields?.card5?.images[index];

                            return (
                              <div
                                className="dropzone-single-image avatar-xl border"
                                {...getRootProps()}
                              >
                                <input
                                  {...getInputProps()}
                                  id="formrow-profile-image-Input"
                                  multiple={false}
                                />

                                <img
                                  className="rounded avatar-xl"
                                  alt={
                                    !!imageFile && imageFile?.name
                                      ? imageFile?.name
                                      : "hlc"
                                  }
                                  src={
                                    !!imageFile
                                      ? imageFile.url
                                      : logo_placeholder
                                  }
                                />

                                <div className="edit">
                                  <i className="bx bx-pencil"></i>
                                </div>
                              </div>
                            );
                          }}
                        </Dropzone>
                      </Col>
                    ))}
                  </Row>
                  {/* <Button onClick={handleAddCard3Image}>Add Image</Button> */}
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
