import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (history, toggleConfirmModal, t, accesses) => [
  {
    dataField: "fname",
    text: t("Name"),
    sort: true,
  },
  {
    dataField: "email",
    text: t("Email"),
    sort: true,
  },
  {
    dataField: "accountStatus",
    text: t("Status"),
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "text-capitalize font-size-13 badge-soft-" +
          (row.accountStatus == "active"
            ? "success"
            : row.accountStatus == "inactive"
              ? "warning"
              : "danger")
        }
        color={row.badgeClass}
      >
        {t(row.accountStatus)}
      </Badge>
    ),
  },
 
  {
    text: t("Registered At"),
    dataField: "created_at",
    sort: true,
    formatter: (_, row) =>
      moment(row.created_at).format("DD MMM YYYY hh:mm A"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link  to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal(row);
            }} className="mr-3 text-secondary">
          {
            row.accountStatus == "active" ?
              <Badge className="text-capitalize font-size-13 badge-soft-">
                {"Deactive"}
              </Badge> :
              <Badge className="text-capitalize font-size-13 badge-soft-">
                {"Active"}
              </Badge>


          }
        
        </Link>
      </>
    ),
  },
];

export default CustomersColumns;
