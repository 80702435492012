import {
  LOG_API_FAIL,
  GET_LOGS,
  GET_LOGS_FAIL,
  GET_LOGS_SUCCESS,
  POST_REBUILD_SCRIPT,
  POST_REBUILD_SCRIPT_FAIL,
  POST_REBUILD_SCRIPT_SUCCESS,
  POST_STORE_REBUILD_SCRIPT,
  POST_STORE_REBUILD_SCRIPT_FAIL,
  POST_STORE_REBUILD_SCRIPT_SUCCESS,
  POST_STORE_SETTINGS_SCRIPT,
  POST_STORE_SETTINGS_SCRIPT_FAIL,
  POST_STORE_SETTINGS_SCRIPT_SUCCESS,
  POST_CLEAR_LOGS,
  POST_CLEAR_LOGS_FAIL,
  POST_CLEAR_LOGS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  logs: [],
  totalLogs: 0,
  error: "",
  loading: false,
  log: {},
}

const Log = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Logs */
    case GET_LOGS:
      return {
        ...state,
        loading: true,
      }

    case GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.payload.data || [],
        totalLogs: action.payload.totalCount || 0,
        loading: false,
      }

    case GET_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Logs END */

    /* Rebuild script */
    case POST_REBUILD_SCRIPT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case POST_REBUILD_SCRIPT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }

    case POST_REBUILD_SCRIPT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Rebuild script END */

    /* Store Rebuild script */
    case POST_STORE_REBUILD_SCRIPT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case POST_STORE_REBUILD_SCRIPT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }

    case POST_STORE_REBUILD_SCRIPT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Store Rebuild script END */

    /* Store Settings script */
    case POST_STORE_SETTINGS_SCRIPT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case POST_STORE_SETTINGS_SCRIPT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }

    case POST_STORE_SETTINGS_SCRIPT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Store Settings script END */

    /* Clear Logs */
    case POST_CLEAR_LOGS:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case POST_CLEAR_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      }

    case POST_CLEAR_LOGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    /* CLear Logs API */

    case LOG_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Log
