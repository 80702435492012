import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { Card, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";
import logo_placeholder from "assets/images/logo-placeholder.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { uploadFile } from "store/actions";
import {
  getCompanyContent,
  updateCompanyContent,
} from "helpers/backend_helper";
import toast from "react-hot-toast";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const index = ({ onUploadFile }) => {
  const [formData, setFormData] = useState({
    section1: {
      titleEn: "",
      titleAr: "",
      descriptionEn: "",
      descriptionAr: "",
      image1: "",
      image2: "",
    },
    section2: {
      titleEn: "",
      titleAr: "",
      cards: [
        {
          image: "",
          titleEn: "",
          titleAr: "",
          descriptionEn: "",
          descriptionAr: "",
        },
      ],
    },
    section3: {
      titleEn: "",
      titleAr: "",
      cards: [
        {
          image: "",
          nameEn: "",
          designationEn: "",
          nameAr: "",
          designationAr: "",
          linkedinLink: "",
        },
      ],
    },
  });

  const getPageContentInfo = () => {
    getCompanyContent().then((res) => {
      if (res?.statusCode === 200) {
        const { data } = res;
        const newFormData = { ...formData };
        newFormData._id = data._id;
        if (data.section1) {
          newFormData.section1 = data.section1;
        }
        if (data.section2) {
          newFormData.section2 = data.section2;
        }
        if (data.section3) {
          newFormData.section3 = data.section3;
        }
        setFormData(newFormData);
      }
    });
  };
  useEffect(() => {
    getPageContentInfo();
  }, []);
  const uploadFileSuccess = (section, name, index) => (response) => {
    const { link } = response.data;

    let nameLabel = "";
    if (index !== false && index !== undefined) {
      nameLabel = "image";
    } else if (section === "section1") {
      nameLabel = name;
    }
    console.log("here ==============>", section, name, index);

    handleChange(
      section,
      name,
      index
    )({ target: { value: link, name: nameLabel } });
  };

  const handleAcceptedFiles =
    (section) =>
    (name, index = false) =>
    (_files) => {
      const files = _files?.filter((file) => file.size < 5242880);

      if (files.length < _files.length) {
        return toastr.error("Maximum upload file size: 5MB");
      }

      onUploadFile({ file: files[0] }, uploadFileSuccess(section, name, index));

      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
    };
  const handleChange = (section, field, index) => (event) => {
    console.log("in handleChange =========>", section, field, index);
    const { name, value } = event.target;
    const newFormData = { ...formData };

    console.log(
      "condition  ========>",
      index !== false && index !== undefined,
      name
    );
    if (index !== false && index !== undefined) {
      newFormData[section][field][index][name] = value;
    } else {
      newFormData[section][field] = value;
    }
    setFormData(newFormData);
  };

  const handleCardAdd = (section) => {
    const newFormData = { ...formData };
    newFormData[section].cards.push({
      image: "",
      titleEn: "",
      titleAr: "",
      descriptionEn: "",
      descriptionAr: "",
    });
    setFormData(newFormData);
  };

  const handleRemoveCard = (section, index) => {
    const newFormData = { ...formData };
    newFormData[section].cards.splice(index, 1);
    setFormData(newFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await updateCompanyContent(formData);
      if (res?.statusCode === 200) {
        toast.success(res.message);
      }
    } catch (error) {
      console.log("error =========>", error);
    }
  };

  console.log(formData);
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Content"
          breadcrumbItem={"Company Page Content"}
          breadcrumbItems={[{ title: "Company Page Content" }]}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h2>Section 1</h2>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title (English)</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.section1.titleEn}
                          onChange={handleChange("section1", "titleEn")}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title (Arabic)</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.section1.titleAr}
                          onChange={handleChange("section1", "titleAr")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Description (English)</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={formData.section1.descriptionEn}
                          onChange={handleChange("section1", "descriptionEn")}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Description (Arabic)</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={formData.section1.descriptionAr}
                          onChange={handleChange("section1", "descriptionAr")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Image 1</Form.Label>
                        <Col md={6}>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("section1")("image1")(
                                acceptedFiles
                              );
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = formData?.section1?.image1;

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl border"
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  <img
                                    className="rounded avatar-xl"
                                    alt={
                                      !!imageFile && imageFile?.name
                                        ? imageFile?.name
                                        : "hlc"
                                    }
                                    src={
                                      !!imageFile ? imageFile : logo_placeholder
                                    }
                                  />

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Image 2</Form.Label>
                        <Col md={6}>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("section1")("image2")(
                                acceptedFiles
                              );
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = formData?.section1?.image2;

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl border"
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  <img
                                    className="rounded avatar-xl"
                                    alt={
                                      !!imageFile && imageFile?.name
                                        ? imageFile?.name
                                        : "hlc"
                                    }
                                    src={
                                      !!imageFile ? imageFile : logo_placeholder
                                    }
                                  />

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  <h2>Section 2</h2>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title (English)</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.section2.titleEn}
                          onChange={handleChange("section2", "titleEn")}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title (Arabic)</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.section2.titleAr}
                          onChange={handleChange("section2", "titleAr")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {formData.section2.cards.map((card, index) => (
                    <div key={index}>
                      <h3>Card {index + 1}</h3>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Image</Form.Label>
                            <Col md={6}>
                              onUploadFile
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles("section2")(
                                    "cards",
                                    index
                                  )(acceptedFiles);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => {
                                  const imageFile =
                                    formData?.section2?.cards?.[index]?.image;

                                  return (
                                    <div
                                      className="dropzone-single-image avatar-xl border"
                                      {...getRootProps()}
                                    >
                                      <input
                                        {...getInputProps()}
                                        id="formrow-profile-image-Input"
                                        multiple={false}
                                      />

                                      <img
                                        className="rounded avatar-xl"
                                        alt={
                                          !!imageFile && imageFile?.name
                                            ? imageFile?.name
                                            : "hlc"
                                        }
                                        src={
                                          !!imageFile
                                            ? imageFile
                                            : logo_placeholder
                                        }
                                      />

                                      <div className="edit">
                                        <i className="bx bx-pencil"></i>
                                      </div>
                                    </div>
                                  );
                                }}
                              </Dropzone>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Title (English)</Form.Label>
                            <Form.Control
                              type="text"
                              value={card.titleEn}
                              onChange={handleChange(
                                "section2",
                                "cards",
                                index
                              )}
                              name="titleEn"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Title (Arabic)</Form.Label>
                            <Form.Control
                              type="text"
                              value={card.titleAr}
                              onChange={handleChange(
                                "section2",
                                "cards",
                                index
                              )}
                              name="titleAr"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Description (English)</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={card.descriptionEn}
                              onChange={handleChange(
                                "section2",
                                "cards",
                                index
                              )}
                              name="descriptionEn"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Description (Arabic)</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={card.descriptionAr}
                              onChange={handleChange(
                                "section2",
                                "cards",
                                index
                              )}
                              name="descriptionAr"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {index === formData.section2.cards.length - 1 && (
                        <Button
                          onClick={() => handleCardAdd("section2")}
                          variant="success"
                          className="mt-3"
                        >
                          Add Card
                        </Button>
                      )}
                      {formData.section2.cards.length > 1 && (
                        <Button
                          onClick={() => handleRemoveCard("section2", index)}
                          variant="danger"
                          className="mt-3"
                        >
                          Remove Card
                        </Button>
                      )}
                    </div>
                  ))}

                  <h2>Section 3</h2>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title (English)</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.section3.titleEn}
                          onChange={handleChange("section3", "titleEn")}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title (Arabic)</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.section3.titleAr}
                          onChange={handleChange("section3", "titleAr")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {formData.section3.cards.map((card, index) => (
                    <div key={index}>
                      <h3>Card {index + 1}</h3>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Image</Form.Label>
                            <Col md={6}>
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles("section3")(
                                    "cards",
                                    index
                                  )(acceptedFiles);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => {
                                  const imageFile =
                                    formData?.section3?.cards?.[index]?.image;

                                  return (
                                    <div
                                      className="dropzone-single-image avatar-xl border"
                                      {...getRootProps()}
                                    >
                                      <input
                                        {...getInputProps()}
                                        id="formrow-profile-image-Input"
                                        multiple={false}
                                      />

                                      <img
                                        className="rounded avatar-xl"
                                        alt={
                                          !!imageFile && imageFile?.name
                                            ? imageFile?.name
                                            : "hlc"
                                        }
                                        src={
                                          !!imageFile
                                            ? imageFile
                                            : logo_placeholder
                                        }
                                      />

                                      <div className="edit">
                                        <i className="bx bx-pencil"></i>
                                      </div>
                                    </div>
                                  );
                                }}
                              </Dropzone>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Name English</Form.Label>
                            <Form.Control
                              type="text"
                              value={card.nameEn}
                              onChange={handleChange(
                                "section3",
                                "cards",
                                index
                              )}
                              name="nameEn"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Designation English</Form.Label>
                            <Form.Control
                              type="text"
                              value={card.designationEn}
                              onChange={handleChange(
                                "section3",
                                "cards",
                                index
                              )}
                              name="designationEn"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Name Arabic</Form.Label>
                            <Form.Control
                              type="text"
                              value={card.nameAr}
                              onChange={handleChange(
                                "section3",
                                "cards",
                                index
                              )}
                              name="nameAr"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Designation Arabic</Form.Label>
                            <Form.Control
                              type="text"
                              value={card.designationAr}
                              onChange={handleChange(
                                "section3",
                                "cards",
                                index
                              )}
                              name="designationAr"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>LinkedIn Link</Form.Label>
                            <Form.Control
                              type="text"
                              value={card.linkedinLink}
                              onChange={handleChange(
                                "section3",
                                "cards",
                                index
                              )}
                              name="linkedinLink"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {index === formData.section3.cards.length - 1 && (
                        <Button
                          onClick={() => handleCardAdd("section3")}
                          variant="success"
                          className="mt-3"
                        >
                          Add Card
                        </Button>
                      )}
                      {formData.section3.cards.length > 1 && (
                        <Button
                          onClick={() => handleRemoveCard("section3", index)}
                          variant="danger"
                          className="mt-3"
                        >
                          Remove Card
                        </Button>
                      )}
                    </div>
                  ))}
                  <Button variant="primary" type="submit" className="mt-3">
                    Submit
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
