import {
  GET_SUPPORT_LIST,
  GET_SUPPORT_LIST_SUCCESS,
  GET_SUPPORT_LIST_FAIL,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: COLLECTION_API_FAIL,
  payload: error,
});

/* Get TopDapp */
export const getSupportList = (data) => ({
  type: GET_SUPPORT_LIST,
  payload: data,
});

export const getSupportListSuccess = (TopDapp) => ({
  type: GET_SUPPORT_LIST_SUCCESS,
  payload: TopDapp,
});

export const getSupportListFail = (error) => ({
  type: GET_SUPPORT_LIST_FAIL,
  payload: error,
});
/* Get TopDapp END */
