import {
  GET_SUPPORT_LIST,
  GET_SUPPORT_LIST_SUCCESS,
  GET_SUPPORT_LIST_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  supportList: [],
  totalSupport: 0
};

const TopDapp = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get TopDapp */
    case GET_SUPPORT_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_SUPPORT_LIST_SUCCESS:
      return {
        ...state,
        supportList: action.payload.data || [],
        totalSupport: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_SUPPORT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get TopDapp END */

    default:
      return state;
  }
};

export default TopDapp;
