import {
  EMAIL_TEMPLATE_API_FAIL,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_FAIL,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_FAIL,
  GET_EMAIL_TEMPLATE_SUCCESS,
  PUT_EMAIL_TEMPLATE,
  PUT_EMAIL_TEMPLATE_FAIL,
  PUT_EMAIL_TEMPLATE_SUCCESS,
  POST_EMAIL_TEMPLATE,
  POST_EMAIL_TEMPLATE_FAIL,
  POST_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_FAIL,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
} from "./actionTypes"

export const apiFail = (error) => ({
  type: EMAIL_TEMPLATE_API_FAIL,
  payload: error,
})

/* Get EMAIL_TEMPLATEs */
export const getEmailTemplates = (data) => ({
  type: GET_EMAIL_TEMPLATES,
  payload: data,
})

export const getEmailTemplatesSuccess = (emailTemplates) => ({
  type: GET_EMAIL_TEMPLATES_SUCCESS,
  payload: emailTemplates,
})

export const getEmailTemplatesFail = (error) => ({
  type: GET_EMAIL_TEMPLATES_FAIL,
  payload: error,
})
/* Get EmailTemplates END */

/* Get EmailTemplate */
export const getEmailTemplate = (id) => ({
  type: GET_EMAIL_TEMPLATE,
  payload: id,
})

export const getEmailTemplateFail = (error) => ({
  type: GET_EMAIL_TEMPLATE_FAIL,
  payload: error,
})

export const getEmailTemplateSuccess = (emailTemplate) => ({
  type: GET_EMAIL_TEMPLATE_SUCCESS,
  payload: emailTemplate,
})
/* Get EmailTemplate END */

/* Update EmailTemplate */
export const putEmailTemplate = (data, history) => ({
  type: PUT_EMAIL_TEMPLATE,
  payload: { data, history },
})

export const putEmailTemplateFail = (error) => ({
  type: PUT_EMAIL_TEMPLATE_FAIL,
  payload: error,
})

export const putEmailTemplateSuccess = () => ({
  type: PUT_EMAIL_TEMPLATE_SUCCESS,
})
/* Update EmailTemplate END */

/* Post EmailTemplate */
export const postEmailTemplate = (data, callback) => ({
  type: POST_EMAIL_TEMPLATE,
  payload: { data, callback },
})

export const postEmailTemplateFail = (error) => ({
  type: POST_EMAIL_TEMPLATE_FAIL,
  payload: error,
})

export const postEmailTemplateSuccess = (emailTemplate) => ({
  type: POST_EMAIL_TEMPLATE_SUCCESS,
  payload: emailTemplate,
})
/* Post EmailTemplate END */

/* Delete EmailTemplate */
export const deleteEmailTemplate = (data, callback) => ({
  type: DELETE_EMAIL_TEMPLATE,
  payload: { data, callback },
})

export const deleteEmailTemplateFail = (error) => ({
  type: DELETE_EMAIL_TEMPLATE_FAIL,
  payload: error,
})

export const deleteEmailTemplateSuccess = (emailTemplate) => ({
  type: DELETE_EMAIL_TEMPLATE_SUCCESS,
  payload: emailTemplate,
})
/* Delete EmailTemplate END */
