export const CURRENCY_API_FAIL = "COLLECTION_API_FAIL";

// CURRENCY
export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";
export const GET_CURRENCY_LIST_FAIL = "GET_CURRENCY_LIST_FAIL";
export const GET_CURRENCY_LIST_SUCCESS = "GET_CURRENCY_LIST_SUCCESS";

export const ADD_CURRENCY = "ADD_CURRENCY";
export const ADD_CURRENCY_FAIL = "ADD_CURRENCY_FAIL";
export const ADD_CURRENCY_SUCCESS = "ADD_CURRENCY_SUCCESS";

export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const DELETE_CURRENCY_FAIL = "DELETE_CURRENCY_FAIL";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";

export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_CURRENCY_FAIL = "UPDATE_CURRENCY_FAIL";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";

export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_FAIL = "GET_CURRENCY_FAIL";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
