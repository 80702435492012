import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { post } from "../../../helpers/api_helper";
import { STATUS_CHANGE_BY_ADMIN } from "../../../helpers/url_helper";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getCustomer } from "store/actions";

const ViewCustomer = (props) => {
  const {
    match: { params },
    onGetCustomer,
    customer,
    error,
    loading,
  } = props;

  const [Status, setStatus] = useState(customer.adminStatus);
  const [statusData, setStatusData] = useState(customer.adminStatus);
  useEffect(() => {
    if (params && params.id) {
      onGetCustomer(params.id);
    }
  }, [params]);
  useEffect(() => {
    setStatus(customer.adminStatus);
  }, [customer]);
  const handleChange = (name) => (event) => {
    const { value } = event.target;
    let statusChange = "";
    if (value && value === "requested") {
      statusChange = "active";
    } else if (value && value === "active") {
      statusChange = "inactive";
    } else if (value && value === "inactive") {
      statusChange = "active";
    }
    setStatusData(event.target.checked ? "active" : "inactive");
    post(STATUS_CHANGE_BY_ADMIN, { status: statusChange, userId: params.id })
      .then((res) => {
        setStatus(statusChange);
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="page-content">
          <Spinner color="primary" />
        </div>
      )}
      {error && <Redirect to="/dashboard" />}
      {!error && !isEmpty(customer) && !loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Users" breadcrumbItem="User Details" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <p className="text-primary">{customer.role}</p>
                          <h5 className="mt-1 mb-3">{customer.name}</h5>
                          <h5 className="mt-1 mb-3">
                            Wallet Address - {customer.walletAddress}
                          </h5>
                          <h5 className="mt-1 mb-3 text-muted">
                            Email - {customer.email}
                          </h5>

                          <div className="text-muted float-left mr-3 mb-3">
                            <p>Average Rating - {customer.avgRating}</p>
                            {/* <StarRatings
                              rating={customer.avgRating}
                              starRatedColor="#F1B44C"
                              starEmptyColor="#2D363F"
                              numberOfStars={5}
                              name="rating"
                              starDimension="14px"
                              starSpacing="3px"
                            /> */}
                          </div>
                          <h6 className="text-success text-uppercase">
                            {customer.reviewCount} Total Reviews
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    {customer.reviewCount > 0 && (
                      <div className="mt-5">
                        <h5 className="mb-4">Reviews :</h5>
                        {customer.reviews.map((comments) => (
                          <p className="text-muted">{comments}</p>
                        ))}
                      </div>
                    )}
                    {customer.adminStatus && (
                      <div className="status-switch square-switch">
                        <input
                          className="switcher"
                          type="checkbox"
                          id="square-switch1"
                          switch="none"
                          checked={Status === "active" ? true : false}
                          value={statusData}
                          onChange={() => {
                            handleChange("AdminStatusChanges")({
                              target: {
                                value:
                                  Status === "active" ? "active" : "inactive",
                              },
                            });
                          }}
                        />
                        <label
                          htmlFor="square-switch1"
                          data-on-label="active"
                          data-off-label="inactive"
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ViewCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  customer: PropTypes.any,
  onGetCustomer: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getCustomer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
