import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { getSettings, putSettings } from "store/actions";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

// Actions
import { uploadFile } from "store/actions";

import Breadcrumbs from "components/Common/Breadcrumb";

const WalletSettings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({
    staging_Key: "",
    production_Key: "",
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings && props.settings.transakDetails) {
      setFields((prevState) => ({
        ...prevState,
        staging_Key: props.settings.transakDetails.staging_Key,
        production_Key: props.settings.transakDetails.production_Key,
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  function submit() {
    try {
      onPutSettings({
        transakDetails: {
          staging_Key: fields.staging_Key,
          production_Key: fields.production_Key,
        },
        _id: props.settings._id,
      });
    } catch {
      throw new Error("Some Error Occurred!!");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Transak Settings" />
          <Row>
            <Card>
              <CardBody className="spinner-content">
                <Col md={12}>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Stagging Key</Label>

                          <Input
                            type="text"
                            value={fields.staging_Key}
                            onChange={(e) => {
                              handleChange("staging_Key")(e);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Production Key</Label>

                          <Input
                            type="text"
                            value={fields.production_Key}
                            onChange={(e) => {
                              handleChange("production_Key")(e);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <hr className="my-3" />

                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}
                    <Button
                      className="buttoncolor"
                      disabled={loading}
                      onClick={submit}
                    >
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

WalletSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WalletSettings)
);
