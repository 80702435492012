import {
  GET_ACTIVITIES,
  GET_ACTIVITIES_FAIL,
  GET_ACTIVITIES_SUCCESS,
  ADD_ACTIVITY,
  ADD_ACTIVITY_FAIL,
  ADD_ACTIVITY_SUCCESS,
  GET_ACTIVITY,
  GET_ACTIVITY_FAIL,
  GET_ACTIVITY_SUCCESS,
  PUT_ACTIVITY,
  PUT_ACTIVITY_FAIL,
  PUT_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_SUCCESS,
  PUT_ACTIVITIES_STATUS,
  PUT_ACTIVITIES_STATUS_FAIL,
  PUT_ACTIVITIES_STATUS_SUCCESS,
  ACTIVITY_API_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  items: [],
  totalActivities: 0,
  error: "",
  loading: false,
  item: {},
  items: [],
  totalActivities: 0,
  item: {},
}

const Activities = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Activities */
    case GET_ACTIVITIES:
      return {
        ...state,
        loading: true,
      }

    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalActivities: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_ACTIVITIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Activities END */

    /* Add Activity */
    case ADD_ACTIVITY:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case ADD_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Add Activity END */

    /* Get Activity */
    case GET_ACTIVITY:
      return {
        ...state,
        error: "",
        loading: true,
        item: {},
      }

    case GET_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        item: {},
      }

    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        item: action.payload,
      }
    /* Add Activity END */

    /* Update Activity */
    case PUT_ACTIVITY:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_ACTIVITY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update Activity END */

    /* Delete Activity */
    case DELETE_ACTIVITY:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Delete Activity END */

    /* Update Activities Status */
    case PUT_ACTIVITIES_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_ACTIVITIES_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_ACTIVITIES_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update Activities Status END */

    case ACTIVITY_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Activities
