import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup, FormText, Label, Input } from "reactstrap";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
} from "reactstrap";

// Actions
import { getSettings, putSettings } from "store/actions";

// Other Settings
import SectionOne from "./Section-one";
import SectionTwo from "./Section-two";
import SectionThree from "./Section-three";
import SectionFour from "./Section-four";
import SectionLogo from "./Section-Logo";
import SectionFifth from "./Section-fifth";
import SectionSix from "./Section-six";
import SectionSeven from "./Section-seven";

const Settings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({
    section1: {
      image: "",
      heading1En: "",
      heading1Ar: "",
      descriptionEn: "",
      descriptionAr: "",
    },
    section2: {
      image: "",
      heading1En: "",
      heading1Ar: "",
      descriptionEn: "",
      descriptionAr: "",
    },
    section3: {
      image: "",
      heading1En: "",
      heading1Ar: "",
      descriptionEn: "",
      descriptionAr: "",
      card1: {
        heading1En: "",
        heading1Ar: "",
        descriptionEn: "",
        descriptionAr: "",
      },
      card2: {
        heading1En: "",
        heading1Ar: "",
        descriptionEn: "",
        descriptionAr: "",
        cryptoCount: "",
        countriesCount: "",
        userCount: "",
      },
      card3: {
        heading1En: "",
        heading1Ar: "",
        descriptionEn: "",
        descriptionAr: "",
        images: [],
      },
      card4: {
        heading1En: "",
        heading1Ar: "",
        descriptionEn: "",
        descriptionAr: "",
        images: [],
      },
      card5: {
        heading1En: "",
        heading1Ar: "",
        descriptionEn: "",
        descriptionAr: "",
        images: [],
      },
    },
    section4: {
      heading1En: "",
      heading1Ar: "",
      descriptionEn: "",
      descriptionAr: "",
      images: [],
    },
    section5: {
      heading1En: "",
      heading1Ar: "",
    },
    section6: {
      heading1En: "",
      heading1Ar: "",
      descriptionEn: "",
      descriptionAr: "",
    },
    section7: {
      card1: {
        descriptionEn: "",
        descriptionAr: "",
        appStoreLink: "",
        playStoreLink: "",
      },
      card2: {
        heading1En: "",
        heading1Ar: "",
        descriptionEn: "",
        descriptionAr: "",
        twitterLink: "",
        instagramLink: "",
        linkedinLink: "",
        threadsLink: "",
      },
    },
    referral_points: "",
    logo: "",
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        section1: props.settings.section1 || fields.section1,
        section2: props.settings.section2 || fields.section2,
        section3: props.settings.section3 || fields.section3,
        section4: props.settings.section4 || fields.section4,
        section5: props.settings.section5 || fields.section5,
        section6: props.settings.section6 || fields.section6,
        section7: props.settings.section7 || fields.section7,
        referral_points:
          props.settings.referral_points || fields.referral_points,
        logo: props.settings.logo || fields.logo,
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleInnerChange =
    (section) =>
    (card) =>
    (name, index = false) =>
    (event) => {
      const { value } = event.target;
      setFields((prevState) => {
        const newState = { ...prevState };

        if (card && name === "images") {
          if (
            Array.isArray(newState[section][card][name]) &&
            newState[section][card][name].length > 0
          ) {
            if (newState[section][card][name][index]) {
              newState[section][card][name][index].url = value; // Set the URL of the first image
            } else {
              newState[section][card][name].push({ url: value });
            }
          } else {
            newState[section][card][name].url = value; // Update other properties normally
          }
        } else if (name === "images") {
          if (newState[section][name][index]) {
            newState[section][name][index].url = value; // Set the URL of the first image
          } else {
            newState[section][name].push({ url: value });
          }
        } else {
          newState[section] = card
            ? {
                ...newState[section],
                [card]: {
                  ...newState[section][card],
                  [name]: value,
                },
              }
            : {
                ...newState[section],
                [name]: value,
              };
        }

        return newState; // Return the updated state
      });
    };

  const handleLogoChange = (event) => {
    const { value } = event.target;
    setFields({
      ...fields,
      logo: value,
    });
  };

  function submit() {
    const info = {
      ...fields,
      _id: props.settings?._id,
    };
    onPutSettings(info);
  }

  console.log("fieldssss", fields);

  return (
    <div className="page-content">
      <Container fluid>
        {/* <Breadcrumbs title="Settings" breadcrumbItem="Settings" /> */}

        <Row>
          <Col sm={1} lg={2}></Col>

          <Col sm={10} lg={8}>
            <Card>
              <CardBody className="spinner-content">
                {error && typeof error === "string" ? (
                  <Alert color="danger">{error}</Alert>
                ) : null}

                {loading && <div className="spinner"></div>}
                <SectionLogo fields={fields} handleChange={handleLogoChange} />
                <SectionOne
                  fields={fields?.section1}
                  handleChange={handleInnerChange("section1")}
                />
                <SectionTwo
                  fields={fields?.section2}
                  handleChange={handleInnerChange("section2")}
                />
                <SectionThree
                  fields={fields?.section3}
                  handleChange={handleInnerChange("section3")}
                />
                <SectionFour
                  fields={fields?.section4}
                  handleChange={handleInnerChange("section4")}
                />
                <SectionFifth
                  fields={fields?.section5}
                  handleChange={handleInnerChange("section5")}
                />
                <SectionSix
                  fields={fields?.section6}
                  handleChange={handleInnerChange("section6")}
                />
                <SectionSeven
                  fields={fields?.section7}
                  handleChange={handleInnerChange("section7")}
                />

                <FormGroup>
                  <Label>Referral Points</Label>

                  <Input
                    type="number"
                    value={fields.referral_points}
                    onChange={(e) => {
                      setFields({ ...fields, referral_points: e.target.value });
                    }}
                  />
                </FormGroup>

                <hr className="my-3" />

                {/* <HomePageSettings
                  fields={fields.homePageBannerSetting}
                  handleChange={handleInnerChange("homePageBannerSetting")}
                />

                <hr className="my-3" />

                <FooterSettings
                  fields={fields.footerSetting}
                  handleChange={handleInnerChange("footerSetting")}
                />

                <hr className="my-3" />

                <SocialMediaSettings
                  fields={fields.socialMediaSetting}
                  handleChange={handleInnerChange("socialMediaSetting")}
                />

                <hr className="my-3" />

                <SupportSettings
                  fields={fields.supportSetting}
                  handleChange={handleInnerChange("supportSetting")}
                /> */}

                {/* <hr className="my-3" /> */}

                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}

                    <Button className="buttoncolor" onClick={submit}>
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col sm={1} lg={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

Settings.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onGetSettings: PropTypes.func,
  onPutSettings: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
