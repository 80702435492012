import {
  ADD_BLOGS, ADD_BLOGS_FAIL, ADD_BLOGS_SUCCESS,
  GET_BLOGS, GET_BLOGS_FAIL, GET_BLOGS_SUCCESS,
  UPDATE_BLOGS, UPDATE_BLOGS_FAIL, UPDATE_BLOGS_SUCCESS,
  BLOGS_BY_ID, BLOGS_BY_ID_FAIL, BLOGS_BY_ID_SUCCESS,
  DELETE_BLOGS, DELETE_BLOGS_FAIL, DELETE_BLOGS_SUCCESS

} from "./actionTypes"

const INIT_STATE = {
  blogs: [],
  totalBlogs: 0,
  error: "",
  keys_error: "",
  loading: false,

  blogDetails: {},

}

const Blogs = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_BLOGS:
      return {
        ...state,
        loading: true,
      }

    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload.data || [],
        totalBlogs: action.payload.total || 0,
        loading: false,
      }

    case GET_BLOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case ADD_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case ADD_BLOGS_FAIL:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPDATE_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPDATE_BLOGS_FAIL:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case BLOGS_BY_ID:
      return {
        ...state,
        loading: true,
      }

    case BLOGS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        blogDetails: action.payload.data,

      }

    case BLOGS_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DELETE_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case DELETE_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case DELETE_BLOGS_FAIL:
      return {
        ...state,
        loading: false,
        error: "",

      };

    default:
      return state
  }
}

export default Blogs
