import * as CONST from "./actionTypes";

export const getTransaction = (payload) => ({
  type: CONST.GET_TRANSACTION,
  payload: payload,
});

export const getTransactionSuccess = (data) => ({
  type: CONST.GET_TRANSACTION_SUCCESS,
  payload: data,
});

export const getTransactionFail = (error) => ({
  type: CONST.GET_TRANSACTION_FAIL,
  payload: error,
});


/* getBorrowTransactions */
export const getBorrowTransactions = (data) => ({
  type: CONST.GET_BORROW_TRANSACTIONS,
  payload: data,
});

export const getBorrowTransactionsSuccess = (data) => ({
  type: CONST.GET_BORROW_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getBorrowTransactionsFail = (error) => ({
  type: CONST.GET_BORROW_TRANSACTIONS_FAIL,
  payload: error,
});