import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
} from "./actionTypes"

export const editProfile = (user, history) => {
  return {
    type: EDIT_PROFILE,
    payload: { user, history },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const putChangePwd = (data, callback) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { data, callback },
  }
}

export const putChangePwdSuccess = msg => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: msg,
  }
}

export const putChangePwdError = error => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: error,
  }
}
