import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
/* import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EditorUploadAdapterPlugin from "../../../plugins/ckeditor/UploadAdapterPlugin" */
// import CKEditor from "react-ckeditor-component";
import FormButton from "components/Common/FormButtons";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import { addFaq, getFaq, putFaq } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

const AddCustomer = ({
  accessLevel,
  history,
  error,
  onAddFaq,
  onGetFaq,
  onPutFaq,
  faq,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);

  console.log("hisssss", history)

  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    questionEn: "",
    answerEn: "",
    questionAr: "",
    answerAr: "",
    status: "active",
  });

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetFaq(id);
    }
  }, [id]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!!faq && !!faq) {
        try {
          setFields({
            questionAr: faq.questionAr || "",
            answerAr: faq.answerAr || "",
            questionEn: faq.questionEn || "",
            answerEn: faq.answerEn || "",
            status: faq.status || "active",
          });
        } catch (err) {
        }
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(faq)]);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    if (id) {
      if (!accesses.canEdit) return;
      onPutFaq({ _id: faq._id, ...fields }, history.push('/faqs'));
    } else {
      if (!accesses.canAdd) return;
      onAddFaq({ ...fields }, history.push('/faqs'));
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Faqs"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) + " " + props.t("faq")
            }
            breadcrumbItems={[
              { title: props.t("faqs"), link: "/faqs" },
              { title: id ? props.t("edit") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Question English")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.questionEn}
                            onChange={handleChange("questionEn")}
                            required
                          />
                        </FormGroup>
                      </Col>
                    
                      <Col md={12}>
                        <FormGroup>
                          <Label>{props.t("Answer English")}</Label>
                          <div >

                           <CKEditor
                            data={fields.answerEn}
                            editor={ClassicEditor}
                            onChange={(evt, editor)=>{
                              const data = editor?.getData();
                              handleChange("answerEn")({
                                target: { value: data },
                              });
                            }}
                            
                          
                          /> 
                          </div>

                          {/* <CKEditor
                            editor={ClassicEditor}
                            data={fields.answer}
                            config={{
                              extraPlugins: [EditorUploadAdapterPlugin],
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData()

                              handleChange("answer")({
                                target: { value: data },
                              })
                            }}
                          /> */}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Question Arabic")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.questionAr}
                            onChange={handleChange("questionAr")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>{props.t("Answer Arabic")}</Label>
                          <div >

                           <CKEditor
                            data={fields.answerAr}
                            editor={ClassicEditor}
                            onChange={(evt, editor)=>{
                              const data = editor?.getData();
                              handleChange("answerAr")({
                                target: { value: data },
                              });
                            }}
                            
                          
                          /> 
                          </div>

                          {/* <CKEditor
                            editor={ClassicEditor}
                            data={fields.answer}
                            config={{
                              extraPlugins: [EditorUploadAdapterPlugin],
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData()

                              handleChange("answer")({
                                target: { value: data },
                              })
                            }}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton
                      needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      goBack={() => history.goBack()}
                    />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  faq: PropTypes.object,
  onAddFaq: PropTypes.func,
  onGetFaq: PropTypes.func,
  onPutFaq: PropTypes.func,
};

const mapStateToProps = ({ faqs }) => ({
  error: faqs.error,
  loading: faqs.loading,
  faq: faqs.faq,
});

const mapDispatchToProps = (dispatch) => ({
  onAddFaq: (data, history) => dispatch(addFaq(data, history)),
  onGetFaq: (id) => dispatch(getFaq(id)),
  onPutFaq: (data, history) => dispatch(putFaq(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
);
