import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Promo Code Redux States
import {
  GET_LOGS,
  POST_REBUILD_SCRIPT,
  POST_STORE_REBUILD_SCRIPT,
  POST_STORE_SETTINGS_SCRIPT,
  POST_CLEAR_LOGS
} from "./actionTypes"
import {
  getLogsFail,
  getLogsSuccess,
  postRebuildScriptFail,
  postRebuildScriptSuccess,
  postStoreSettingsScriptFail,
  postStoreSettingsScriptSuccess,
  postClearLogsFail,
  postClearLogsSuccess
} from "./actions"

import {
  getLogs,
  postRebuildScript,
  postStoreRebuildScript,
  postStoreSettingsScript,
  postClearLogs
} from "helpers/backend_helper"

function* fetchLogs({ payload }) {
  try {
    const response = yield call(getLogs, payload)

    if (response.status == "failure") {
      return yield put(getLogsFail(response.message))
    }

    yield put(getLogsSuccess(response))
  } catch (error) {
    yield put(getLogsFail(error))
  }
}

function* onPostRebuildScript({ payload: callback }) {
  try {
    const response = yield call(postRebuildScript)

    if (response.status == "failure") {
      return yield put(postRebuildScriptFail(response.message))
    }

    yield put(postRebuildScriptSuccess(response))

    callback && callback()
  } catch (error) {
    yield put(postRebuildScriptFail(error))
  }
}

function* onStorePostRebuildScript({ payload: { data, callback } }) {
  try {
    const response = yield call(postStoreRebuildScript, data)

    if (response.status == "failure") {
      return yield put(postStoreRebuildScriptFail(response.message))
    }

    yield put(postStoreRebuildScriptSuccess(response))
    callback && callback()
  } catch (error) {
    yield put(postStoreRebuildScriptFail(error))
  }
}

function* onStorePostSettingsScript({ payload: { data, callback } }) {
  try {
    const response = yield call(postStoreSettingsScript)

    if (response.status == "failure") {
      return yield put(postStoreSettingsScriptFail(response.message))
    }

    yield put(postStoreSettingsScriptSuccess(response))
    callback && callback()
  } catch (error) {
    yield put(postStoreSettingsScriptFail(error))
  }
}

function* onPostClearLogs({ payload: { data, callback } }) {
  try {
    const response = yield call(postClearLogs, `?type=${data}`)

    if (response.status == "failure") {
      return yield put(postClearLogsFail(response.message))
    }

    yield put(postClearLogsSuccess(response))
    callback && callback()
  } catch (error) {
    yield put(postClearLogsFail(error))
  }
}

function* LogsSaga() {
  yield takeEvery(GET_LOGS, fetchLogs)
  yield takeLatest(POST_REBUILD_SCRIPT, onPostRebuildScript)
  yield takeLatest(POST_STORE_REBUILD_SCRIPT, onStorePostRebuildScript)
  yield takeLatest(POST_STORE_SETTINGS_SCRIPT, onStorePostSettingsScript)
  yield takeLatest(POST_CLEAR_LOGS, onPostClearLogs)
}

export default LogsSaga
