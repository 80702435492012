import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import {
  copyToClipboard,
  custmToFixed,
  sliceWalletAddress,
} from "helpers/contants";
import toastr from "toastr";
import { delay } from "lodash";

const ConfirmModal = (props) => {
  const { isOpen, toggle, detail } = props;

  const calculateTotal = (data) => {
    if (!data) {
      return;
    }
    let gasPrice = custmToFixed(data?.gasPrice);
    let total = Number(data.amount) + Number(gasPrice);
    return custmToFixed(total);
  };

  const copyClip = (e, text) => {
    e.preventDefault();
    copyToClipboard(text);
    toastr.success("Copied!");
  };
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
    >
      <div className="modal-content remove-confirm">
        <ModalBody>
          <div className="Status_modal">
            <div class="headmodal">
              <h5>Detail</h5>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  toggle(null);
                }}
              >
                <svg width="20" height="20" viewBox="0 0 24 24">
                  <path
                    fill="#000"
                    d="m12 13.4l-4.9 4.9q-.275.275-.7.275q-.425 0-.7-.275q-.275-.275-.275-.7q0-.425.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7L13.4 12l4.9 4.9q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275Z"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="Status_Content">
              {/* <div className="Status_col">
                <h6>Wallet Address</h6>
              </div>

              <div className="Status_col">
                <h6>{detail?.walletAddress || ""}</h6>
              </div> */}

              <div className="trasaction_col">
                {/* <h6>Transaction</h6> */}

                <ul>
                  <li>
                    <div className="tra_list">
                      <p>Name</p>
                      <p>{detail?.name}</p>
                    </div>
                  </li>
                  <li>
                    <div className="tra_list">
                      <p> User Email</p>
                      <p>{detail?.email || ""}</p>
                    </div>
                  </li>
                  <li>
                    <div className="tra_list">
                      <p>Message</p>
                      <p>{detail?.message || ""}</p>
                    </div>
                  </li>

                  {/* <li>
                  <div className="tra_list">
                    <p>Base Fee (GWEI)</p>
                    <p>0.000775965</p>
                  </div>
                </li>

                <li>
                  <div className="tra_list">
                    <p>Priority Fee (GWEI)</p>
                    <p>1</p>
                  </div>
                </li>

                <li>
                  <div className="tra_list">
                    <p>Total Gase Fee</p>
                    <p>0.0123 GoerliETH</p>
                  </div>
                </li>

                <li>
                  <div className="tra_list">
                    <p>Max Fee per gas</p>
                    <p>0.000000001 GoerliETH</p>
                  </div>
                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default withTranslation()(ConfirmModal);
