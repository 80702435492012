import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import toastr from "toastr"
// Customer Redux States
import {
  GET_CUSTOMERS,
  GET_CUSTOMER,
  DELETE_USER,
  ADD_USER
} from "./actionTypes"
import {
  getCustomersFail,
  getCustomersSuccess,
  getCustomerFail,
  getCustomerSuccess,
  deleteUserFail,deleteUserSuccess,addUserFail,addUserSuccess
} from "./actions"

import {
  getCustomers,
  getCustomer,
  deleteCustomer,addCustomer
} from "helpers/backend_helper"

toastr.options = {
  newestOnTop: true,
  progressBar: true,
}

function* fetchCustomers({ payload }) {

  try {
    const response = yield call(getCustomers, payload)



    if (response.status == "failure") {
      return yield put(getCustomersFail(response.message))
    }

    yield put(getCustomersSuccess(response))
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}

function* fetchCustomer({ payload }) {

  try {
    const response = yield call(getCustomer, payload)

    if (response.status == "failure") {
      return yield put(getCustomerFail(response.message))
    }

    yield put(getCustomerSuccess(response.data))
  } catch (error) {
    yield put(getCustomerFail(error))
  }
}


function* deleteCustomerSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCustomer, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(deleteUserFail(response.message));
    }
    yield put(deleteUserSuccess(response));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* addCustomerSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(addCustomer, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(addUserFail(response.message));
    }
    yield put(addUserSuccess(response));
  } catch (error) {
    toastr.error(error?.response?.data?.message)
    yield put(addUserFail(error));
  }
}
function* customersSaga() {
  yield takeLatest(GET_CUSTOMERS, fetchCustomers)
  yield takeLatest(GET_CUSTOMER, fetchCustomer)
  yield takeLatest(DELETE_USER, deleteCustomerSaga)
  yield takeLatest(ADD_USER, addCustomerSaga)

}

export default customersSaga
