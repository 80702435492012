import * as CONST from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  transactions: [],
  borrowTransaction:[],
  borrowTotal:0,
  total:0
};

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.GET_TRANSACTION:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: action.payload.data || [],
        total: action.payload.total || [],
        loading: false,
        error: "",
      };

    case CONST.GET_TRANSACTION_FAIL:
      return {
        ...state,
        transactions: [],
        loading: false,
        error: "",
      };



       /* GET_BORROW_TRANSACTION */
    case CONST.GET_BORROW_TRANSACTIONS:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_BORROW_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        borrowTransaction: action.payload.data || [],
        borrowTotal: action.payload.total || [],

        loading: false,
        error: "",
      };

    case CONST.GET_BORROW_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Transaction;
