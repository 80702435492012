import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup, FormText, Label, Input } from "reactstrap";

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Spinner,
    Alert,
} from "reactstrap";

// Actions
import { getSettings, putSettings } from "store/actions";


const UnirampSettings = (props) => {
    const { onGetSettings, onPutSettings, loading, error } = props;

    const [fields, setFields] = useState({
        unirampApiKeys: {
            SandboxApiUrl: "",
            SandboxPublishableApiKey: "",
            ProductionApiUrl: "",
            ProductionPublishableApiKey: "",
        },

    });


    useEffect(() => {
        onGetSettings();
    }, []);


    useEffect(() => {
        if (props.settings) {

            setFields((prevState) => ({
                ...prevState,
                unirampApiKeys: props?.settings?.unirampApiKeys,
                section1: props.settings.section1,
                section2: props.settings.section2,
                section4: props.settings.section4,
                section5: props.settings.section5,
                referral_points: props.settings.referral_points
            }));
        }
    }, [JSON.stringify(props?.settings)]);



    const handleChange = (name) => (event) => {
        console.log(name);
        const { value } = event.target;
        setFields(prevFields => ({
            ...prevFields,
            unirampApiKeys: {
                ...prevFields.unirampApiKeys,
                [name]: value,
            },
        }));
    };




    function submit() {

        onPutSettings({
            ...fields,
            _id: props.settings?._id,
        });
    }


    return (
        <div className="page-content">
            <Container fluid>
                {/* <Breadcrumbs title="Settings" breadcrumbItem="Settings" /> */}

                <Row>
                    <Col sm={1} lg={2}></Col>

                    <Col sm={10} lg={8}>
                        <Card>
                            <CardBody className="spinner-content">
                                {error && typeof error === "string" ? (
                                    <Alert color="danger">{error}</Alert>
                                ) : null}

                                {loading && <div className="spinner"></div>}


                                <Col lg={12}>
                                    <Row>
                                        <h5 className="my-4">Sandbox Api Credentials</h5>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="formrow-firstname-Input">
                                                    Sandbox Api Url                                        </Label>
                                                <Input
                                                    type="text"
                                                    id="formrow-firstname-Input"
                                                    value={fields?.unirampApiKeys?.SandboxApiUrl}
                                                    onChange={handleChange("SandboxApiUrl")}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="formrow-firstname-Input">
                                                    Sandbox Publishable Api Key                                        </Label>
                                                <Input
                                                    type="text"
                                                    id="formrow-firstname-Input"
                                                    value={fields?.unirampApiKeys?.SandboxPublishableApiKey}
                                                    onChange={handleChange("SandboxPublishableApiKey")}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <h5 className="my-4">Production Api Credentials</h5>

                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="formrow-firstname-Input">
                                                    Production Api Key                                        </Label>
                                                <Input
                                                    type="text"
                                                    id="formrow-firstname-Input"
                                                    value={fields?.unirampApiKeys?.ProductionApiUrl}
                                                    onChange={handleChange("ProductionApiUrl")}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="formrow-firstname-Input">
                                                    Production Publishable Api Key                                        </Label>
                                                <Input
                                                    type="text"
                                                    id="formrow-firstname-Input"
                                                    value={fields?.unirampApiKeys?.ProductionPublishableApiKey}
                                                    onChange={handleChange("ProductionPublishableApiKey")}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </Col>



                                <hr className="my-3" />


                                <Row>
                                    <Col></Col>

                                    <Col className="d-flex justify-content-end">
                                        {loading && <Spinner color="primary" className="mr-2" />}

                                        <Button className="buttoncolor" onClick={submit}>
                                            Save Changes
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm={1} lg={2}></Col>
                </Row>
            </Container>
        </div>
    );
};

UnirampSettings.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    onGetSettings: PropTypes.func,
    onPutSettings: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
    settings: Settings.settings,
    loading: Settings.loading,
    error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
    onGetSettings: () => dispatch(getSettings()),
    onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UnirampSettings)
);
