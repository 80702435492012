import { call, put, retry, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN_USER,
  GET_CURRENT_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  GET_LANGUAGES,
} from "./actionTypes";
import {
  apiError,
  loginSuccess,
  currentUserSuccess,
  currentUserFail,
  logoutUserSuccess,
  getLanguagesSuccess,
  getLanguagesFail,
} from "./actions";
import { getStarted } from "../../Settings/actions";

import {
  postLogin,
  getCurrentUser,
  getLanguages,
  // postSocialLogin,
} from "helpers/backend_helper";

// Fake Backend
// import { postLogin } from "helpers/fakebackend_helper";  

import { updateToken } from "helpers/api_helper";
import {
  ROLES,
  ADMIN_SERVICE_ROUTE,
  ADMIN_SUB_SERVICES_ROUTE,
} from "helpers/contants";

const permissionsFilter = (item) => {
  if (item?.type === "storetypes") return true;

  const _index = item?.permissions?.findIndex((i) =>
    ["VIEW", "view", "List", "list"].includes(i.label)
  );

  if (_index !== -1) {
    if (!item?.permissions) {
      return true;
    }

    return item?.permissions[_index]?.value;
  }

  return false;
};

const findValidRoute = ({ permissions, routes, preLink }) => {
  permissions = permissions?.filter(permissionsFilter);
  let route = "";

  if (!permissions) return route;

  const item = permissions[0];

  if (!item) return route;

  if (item?.type === "storetypes") {
    return findValidRoute({
      preLink: item?.storeTypes[0]?.storeType?.toLowerCase(),
      permissions: item?.storeTypes[0]?.navigation,
      routes: ADMIN_SUB_SERVICES_ROUTE,
    });
  }

  route = routes[item?.type]?.key;

  if (preLink) {
    route = preLink + "/" + route;
  }

  if (routes[item?.type]?.isDynamic) {
    route += "s";
  }

  return route;
};

function* loginUser({ payload: { user, history } }) {

  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    });

    // const response = yield postLogin()


    // console.log(response, "response in saga");

    if (response.status == "failure") {

      return yield put(apiError(response.message));
    }
    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.Token);
    yield put(loginSuccess(response));
    history.push("/");
  } catch (error) {

    yield put(apiError(error?.response?.data?.message));
  }
}

function* currentUser() {
  try {
    const response = yield call(getCurrentUser);

    if (response.status == "failure") {
      return yield put(currentUserFail(response.message));
    }

    if (response?.data?.role === ROLES.admin) {
      yield put(getStarted());
    }

    yield put(currentUserSuccess(response));
  } catch (error) {
    yield put(currentUserFail("Server Error"));
  }
}

function* logoutUser({ payload: { history, role } }) {
  try {
    localStorage.removeItem("authUser");

    yield put(logoutUserSuccess());

    if (role === ROLES.vendor) {
      history.push("/login");
      window.location.replace("/login");
    } else {
      history.push("/login");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* onGetLanguages({ payload }) {
  try {
    const response = yield call(getLanguages);

    if (response.status == "failure") {
      return yield put(getLanguagesFail(response.message));
    }

    /* let _timezones = [moment.tz.guess()]

    response?.data?.map(language => {
      language?.timezones?.map(item => {
        if (!_timezones.includes(item)) {
          _timezones.push(item)
        }
      })
    })

    _timezones = _timezones?.sort()

    data.timezones = _timezones
      ?.map(item => {
        const zoneIndex = TIMEZONES.findIndex(zone => zone.value === item)

        if (zoneIndex !== -1) {
          return TIMEZONES[zoneIndex]
        }
      })
      ?.filter(item => !!item) */

    yield put(getLanguagesSuccess(response?.data));
  } catch (error) {
    yield put(getLanguagesFail(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(GET_CURRENT_USER, currentUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_LANGUAGES, onGetLanguages);
}

export default authSaga;
