import {
  GET_CRYPTO_LIST,
  GET_CRYPTO_LIST_SUCCESS,
  GET_CRYPTO_LIST_FAIL,
  ADD_CRYPTO,
  ADD_CRYPTO_SUCCESS,
  ADD_CRYPTO_FAIL,
  UPDATE_CRYPTO,
  UPDATE_CRYPTO_FAIL,
  UPDATE_CRYPTO_SUCCESS,
  GET_CRYPTO,
  GET_CRYPTO_FAIL,
  GET_CRYPTO_SUCCESS,
  DELETE_CRYPTO,
  DELETE_CRYPTO_SUCCESS,
  DELETE_CRYPTO_FAIL,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: COLLECTION_API_FAIL,
  payload: error,
});

/* Get crypto */
export const getCryptoList = (data) => ({
  type: GET_CRYPTO_LIST,
  payload: data,
});

export const getCryptoListSuccess = (crypto) => ({
  type: GET_CRYPTO_LIST_SUCCESS,
  payload: crypto,
});

export const getCryptoListFail = (error) => ({
  type: GET_CRYPTO_LIST_FAIL,
  payload: error,
});
/* Get crypto END */

/* add crypto */
export const addCrypto = (data, callback) => ({
  type: ADD_CRYPTO,
  payload: { data, callback },
});

export const addCryptoSuccess = (crypto) => ({
  type: ADD_CRYPTO_SUCCESS,
  payload: crypto,
});

export const addCryptoFail = (error) => ({
  type: ADD_CRYPTO_FAIL,
  payload: error,
});
/* add crypto END */

/* udpate crypto */
export const udpateCrypto = (data, callback) => ({
  type: UPDATE_CRYPTO,
  payload: { data, callback },
});

export const udpateCryptoSuccess = (crypto) => ({
  type: UPDATE_CRYPTO_SUCCESS,
  payload: crypto,
});

export const udpateCryptoFail = (error) => ({
  type: UPDATE_CRYPTO_FAIL,
  payload: error,
});
/* udpate crypto END */

/* get crypto */
export const getCrypto = (data, callback) => ({
  type: GET_CRYPTO,
  payload: { data, callback },
});

export const getCryptoSuccess = (crypto) => ({
  type: GET_CRYPTO_SUCCESS,
  payload: crypto,
});

export const getCryptoFail = (error) => ({
  type: GET_CRYPTO_FAIL,
  payload: error,
});
/* get crypto END */

/* get crypto */
export const deleteCrypto = (data, callback) => ({
  type: DELETE_CRYPTO,
  payload: { data, callback },
});

export const deleteCryptoSuccess = (crypto) => ({
  type: DELETE_CRYPTO_SUCCESS,
  payload: crypto,
});

export const deleteCryptoFail = (error) => ({
  type: DELETE_CRYPTO_FAIL,
  payload: error,
});
/* get crypto END */
