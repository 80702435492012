import {
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  ADD_ITEM,
  ADD_ITEM_FAIL,
  ADD_ITEM_SUCCESS,
  GET_ITEM,
  GET_ITEM_FAIL,
  GET_ITEM_SUCCESS,
  PUT_ITEM,
  PUT_ITEM_FAIL,
  PUT_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  PUT_ITEMS_STATUS,
  PUT_ITEMS_STATUS_FAIL,
  PUT_ITEMS_STATUS_SUCCESS,
  ITEM_API_FAIL,
  MINT_ITEM,
  MINT_ITEM_FAIL,
  MINT_ITEM_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  items: [],
  totalItems: 0,
  error: "",
  loading: false,
  item: {},
};

const Items = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Items */
    case GET_ITEMS:
      return {
        ...state,
        loading: true,
      };

    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case GET_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Items END */

    /* Add Item */
    case ADD_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case ADD_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Item END */

    /* Get Item */
    case GET_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
        item: {},
      };

    case GET_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        item: {},
      };

    case GET_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        item: action.payload,
      };
    /* Add Item END */

    /* Update Item */
    case PUT_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case PUT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case PUT_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Item END */

    /* Delete Item */
    case DELETE_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case DELETE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Item END */

    /* Update Items Status */
    case PUT_ITEMS_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case PUT_ITEMS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case PUT_ITEMS_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Items Status END */

    /* Mint Items */
    case MINT_ITEM:
      return {
        ...state,
        loading: true,
      };

    case MINT_ITEM_SUCCESS:
      return {
        ...state,
        items: action.payload.data || {},
        loading: false,
        error: "",
      };

    case MINT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Mint Items END */

    case ITEM_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Items;
