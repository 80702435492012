export const LOG_API_FAIL = "LOG_API_FAIL"

// LOGs
export const GET_LOGS = "GET_LOGS"
export const GET_LOGS_FAIL = "GET_LOGS_FAIL"
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS"

// Rebuild Script
export const POST_REBUILD_SCRIPT = "POST_REBUILD_SCRIPT"
export const POST_REBUILD_SCRIPT_FAIL = "POST_REBUILD_SCRIPT_FAIL"
export const POST_REBUILD_SCRIPT_SUCCESS = "POST_REBUILD_SCRIPT_SUCCESS"

// Store Rebuild Script
export const POST_STORE_REBUILD_SCRIPT = "POST_STORE_REBUILD_SCRIPT"
export const POST_STORE_REBUILD_SCRIPT_FAIL = "POST_STORE_REBUILD_SCRIPT_FAIL"
export const POST_STORE_REBUILD_SCRIPT_SUCCESS =
  "POST_STORE_REBUILD_SCRIPT_SUCCESS"

// Store Settings Script
export const POST_STORE_SETTINGS_SCRIPT = "POST_STORE_SETTINGS_SCRIPT"
export const POST_STORE_SETTINGS_SCRIPT_FAIL = "POST_STORE_SETTINGS_SCRIPT_FAIL"
export const POST_STORE_SETTINGS_SCRIPT_SUCCESS =
  "POST_STORE_SETTINGS_SCRIPT_SUCCESS"

// Clear Logs
export const POST_CLEAR_LOGS = "POST_CLEAR_LOGS"
export const POST_CLEAR_LOGS_FAIL = "POST_CLEAR_LOGS_FAIL"
export const POST_CLEAR_LOGS_SUCCESS = "POST_CLEAR_LOGS_SUCCESS"
