import { all, fork } from "redux-saga/effects";

import Alert from "./Alert/saga";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import Register from "./auth/register/saga";
import Settings from "./Settings/saga";
import Customers from "./customers/saga";
import Domain from "./domain/saga";
import Collection from "./collection/saga";
import Items from "./items/saga";
import content from "./content/saga"

import Referrals from "./referrals/saga";


// Sub admins
import SubAdmins from "./subAdmins/saga";

// File
import File from "./File/saga";

// Logs
import Logs from "./logs/saga";

// Email Template
import emailtemplates from "./emailtemplates/saga";
import ActivitiesSaga from "./activity/saga";

// Faqs
import faqs from "./faq/saga";

//crypto
import crypto from "./crypto/saga.js";

//currencies
import currencies from "./currencies/saga.js";

import topDapps from "./top_dapps/saga.js";
import Support from "./Support/saga.js";

///transaction
import transaction from "./transaction/saga";

import contactEmail from "./contact-emails/saga";
import blogs from "./blogs/saga";

export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    Register(),
    LayoutSaga(),
    crypto(),
    currencies(),
    Settings(),
    fork(ActivitiesSaga),
    fork(Customers),
    fork(Domain),
    fork(Collection),
    fork(Items),

    fork(SubAdmins),
    fork(File),
    fork(Logs),
    fork(emailtemplates),
    fork(faqs),
    fork(topDapps),
    fork(Support),
    fork(transaction),
    fork(contactEmail),
    fork(Referrals),
    fork(content),
    fork(blogs)

  ]);
}
