import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toastr from "toastr";

// Customer Redux States
import {
  GET_SETTINGS,
  PUT_SETTINGS,
  GET_ACCESS_LIST,
  GET_STORE_TYPES,
  GET_STARTED,
  POST_CONTRACT_DEPLOY,
  POST_COMPILE_CONTRACT,
  POST_BORROW_CONTRACT_DEPLOY,
  POST_COMPILE_BORROW_CONTRACT,
} from "./actionTypes";
import {
  getSettings as getSettingsAction,
  getSettingsSuccess,
  getSettingsFail,
  putSettingsSuccess,
  putSettingsFail,
  getAccessListSuccess,
  getAccessListFail,
  getStoreTypesSuccess,
  getStoreTypesFail,
  getStartedSuccess,
  getStartedFail,
  postDeployContractSuccess,
  postDeployContractFail,
  postCompileContractSuccess,
  postCompileContractFail,
  postDeployBorrowContractSuccess,
  postDeployBorrowContractFail,
  postCompileBorrowContractSuccess,
  postCompileBorrowContractFail,
} from "./actions";
import { currentUser } from "../auth/login/actions";

import {
  getSettings,
  putSettings,
  getAccessList,
  getStoreTypes,
  getStarted,
  postDeployContract,
  postCompileContract,
  postDeployBorrowContract,
  postCompileBorrowContract,
} from "helpers/backend_helper";

function* onGetSettings() {
  try {
    const response = yield call(getSettings);

    if (response.status == "failure") {
      return yield put(getSettingsFail(response.message));
    }

    yield put(getSettingsSuccess(response.data));
  } catch (error) {
    yield put(getSettingsFail(error));
  }
}

function* onPutSettings({ payload }) {
  try {
    const response = yield call(putSettings, payload);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(putSettingsFail(response.message));
    }
    toastr.success(response.message);
    yield put(getSettingsAction());
    yield put(currentUser());
    yield put(putSettingsSuccess());
  } catch (error) {
    yield put(putSettingsFail(error));
  }
}

function* onGetAccessList({ payload }) {
  try {
    const response = yield call(getAccessList, payload);

    if (response.status == "failure") {
      return yield put(getAccessListFail(response.message));
    }

    yield put(getAccessListSuccess(response.data));
  } catch (error) {
    yield put(getAccessListFail(error));
  }
}

function* onGetStoreTypes({ payload }) {
  try {
    const response = yield call(getStoreTypes, payload);

    if (response.status == "failure") {
      return yield put(getStoreTypesFail(response.message));
    }

    yield put(getStoreTypesSuccess(response.data));
  } catch (error) {
    yield put(getStoreTypesFail(error));
  }
}

function* onGetStarted({}) {
  try {
    const response = yield call(getStarted);

    if (response.status == "failure") {
      return yield put(getStartedFail(response.message));
    }

    yield put(getStartedSuccess(response.data));
  } catch (error) {
    yield put(getStartedFail("Server Error"));
  }
}

function* onPostDeployContract({ payload: { data, callback } }) {
  try {
    const response = yield call(postDeployContract, data);

    if (response.status == "failure") {
      return yield put(postDeployContractFail(response.message));
    }

    callback && callback(response);
    yield put(postDeployContractSuccess(response.data));
    yield put(getSettingsAction());
  } catch (error) {
    yield put(postDeployContractFail("Server Error"));
  }
}

function* onPostCompileContract({ payload: { data, callback } }) {
  try {
    const response = yield call(postCompileContract, data);

    if (response.status == "failure") {
      callback && callback(false, response);

      return yield put(postCompileContractFail(response.message));
    }

    callback && callback(true, response);
    yield put(postCompileContractSuccess(response.data));
    // yield put(getSettings());
  } catch (error) {
    yield put(postCompileContractFail("Server Error"));
  }
}

function* onPostDeployBorrowContract({ payload: { data, callback } }) {
  try {
    const response = yield call(postDeployBorrowContract, data);

    if (response.status == "failure") {
      return yield put(postDeployBorrowContractFail(response.message));
    }

    callback && callback(response);
    yield put(postDeployBorrowContractSuccess(response.data));
    yield put(getSettingsAction());
  } catch (error) {
    yield put(postDeployBorrowContractFail("Server Error"));
  }
}

function* onPostCompileBorrowContract({ payload: { data, callback } }) {
  try {
    const response = yield call(postCompileBorrowContract, data);

    if (response.status == "failure") {


      return yield put(postCompileBorrowContractFail(response.message));
    }

    callback && callback(true, response);
    yield put(postCompileBorrowContractSuccess(response.data));
    // yield put(getSettings());
  } catch (error) {
    yield put(postCompileBorrowContractFail("Server Error"));
  }
}

function* settingsSaga() {
  yield takeEvery(GET_SETTINGS, onGetSettings);
  yield takeEvery(PUT_SETTINGS, onPutSettings);
  yield takeEvery(GET_ACCESS_LIST, onGetAccessList);
  yield takeLatest(GET_STORE_TYPES, onGetStoreTypes);
  yield takeLatest(GET_STARTED, onGetStarted);
  yield takeLatest(POST_CONTRACT_DEPLOY, onPostDeployContract);
  yield takeLatest(POST_COMPILE_CONTRACT, onPostCompileContract);

  yield takeLatest(POST_BORROW_CONTRACT_DEPLOY, onPostDeployBorrowContract);
  yield takeLatest(POST_COMPILE_BORROW_CONTRACT, onPostCompileBorrowContract);
}

export default settingsSaga;
