import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  GET_TOPDAPP_LIST,
  ADD_TOPDAPP,
  DELETE_TOPDAPP,
  UPDATE_TOPDAPP,
  GET_TOPDAPP,
} from "./actionTypes";
import {
  getTopDappListSuccess,
  getTopDappListFail,
  addTopDappFail,
  addTopDappSuccess,
  udpateTopDappFail,
  udpateTopDappSuccess,
  getTopDappFail,
  getTopDappSuccess,
  deleteTopDappFail,
  deleteTopDappSuccess,
} from "./actions";

import {
  getTopDappList,
  addTopDapp,
  updateTopDapp,
  getTopDapp,
  deleteTopDapp,
} from "helpers/backend_helper";

function* getTopDappSaga({ payload }) {
  try {
    const response = yield call(getTopDappList, payload);
    if (response.status == "failure") {
      return yield put(getTopDappListFail(response.message));
    }
    yield put(getTopDappListSuccess(response));
  } catch (error) {
    yield put(getTopDappListFail(error));
  }
}
function* addTopDappSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(addTopDapp, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(addTopDappFail(response.message));
    }
    yield put(addTopDappSuccess(response));
  } catch (error) {
    yield put(addTopDappFail(error));
  }
}
function* updateTopDappSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(updateTopDapp, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(udpateTopDappFail(response.message));
    }
    yield put(udpateTopDappSuccess(response));
  } catch (error) {
    yield put(udpateTopDappFail(error));
  }
}

function* deleteTopDappSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteTopDapp, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(deleteTopDappFail(response.message));
    }
    yield put(deleteTopDappSuccess(response));
  } catch (error) {
    yield put(deleteTopDappFail(error));
  }
}

function* getTopDappDetailSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getTopDapp, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getTopDappFail(response.message));
    }
    yield put(getTopDappSuccess(response));
  } catch (error) {
    yield put(getTopDappFail(error));
  }
}

function* CollectionsSaga() {
  yield takeEvery(GET_TOPDAPP_LIST, getTopDappSaga);
  yield takeEvery(ADD_TOPDAPP, addTopDappSaga);
  yield takeEvery(UPDATE_TOPDAPP, updateTopDappSaga);
  yield takeEvery(DELETE_TOPDAPP, deleteTopDappSaga);
  yield takeEvery(GET_TOPDAPP, getTopDappDetailSaga);
}

export default CollectionsSaga;
