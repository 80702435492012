import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Spinner, Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// action
import { userForgetPassword, userResetPassword, apiError } from "store/actions";

// import images
import logo from "assets/images/logo.png";
import logodark from "../../assets/images/logoopen.png";
import logodark2x from "../../assets/images/logoopen.png";
import logoheader from "../../assets/images/logo.png";
import logoheader2x from "../../assets/images/logoopen.png";

const ForgotPassword = (props) => {
  const { loading, fields, handleChange, handleSubmit } = props;

  return (
    <form
      id="loginform"
      onSubmit={handleSubmit}
      className="spinner-content position-relative"
    >
      {loading && (
        <div className="spinner">
          <Spinner color="primary" />
        </div>
      )}

      {props.forgetError && typeof props.forgetError === "string" ? (
        <Alert color="danger">{props.forgetError}</Alert>
      ) : null}

      {props.forgetSuccessMsg && typeof props.forgetSuccessMsg === "string" ? (
        <Alert color="success">{props.forgetSuccessMsg}</Alert>
      ) : null}

      <div className="row">
        <div className="col-lg-12">
          <h1>Forgot Password</h1>
        </div>
        <div className="col-lg-12">
          <div className="input-group">
            <label>Enter Email</label>
            <input
              type="email"
              placeholder="Enter email"
              value={fields.email}
              onChange={handleChange("email")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <button type="submit" className="themebtn fullwidthbtn" id="nextBtn">
            Send
          </button>
        </div>
      </div>
    </form>
  );
};

const ResetPassword = (props) => {
  const { loading, fields, handleChange, handleSubmit } = props;

  return (
    <form
      id="loginform"
      onSubmit={handleSubmit}
      className="spinner-content position-relative"
    >
      {loading && (
        <div className="spinner">
          <Spinner color="primary" />
        </div>
      )}

      {props.forgetError && typeof props.forgetError === "string" ? (
        <Alert color="danger">{props.forgetError}</Alert>
      ) : null}

      {props.forgetSuccessMsg && typeof props.forgetSuccessMsg === "string" ? (
        <Alert color="success">{props.forgetSuccessMsg}</Alert>
      ) : null}

      <div className="row">
        <div className="col-lg-12">
          <h1>Reset Password</h1>
        </div>
        <div className="col-lg-12">
          <div className="input-group">
            <label>Enter OTP</label>
            <input
              type="text"
              placeholder="Enter OTP"
              value={fields.otp}
              onChange={handleChange("otp")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter Password"
              value={fields.password}
              onChange={handleChange("password")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Confirm Password</label>
            <input
              type="password"
              placeholder="Enter Confirm Password"
              value={fields.confirmPassword}
              onChange={handleChange("confirmPassword")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <button type="submit" className="themebtn fullwidthbtn" id="nextBtn">
            Send
          </button>
        </div>
      </div>
    </form>
  );
};

const ForgetPasswordPage = (props) => {
  const { loading } = props;

  const [page, setpage] = useState("forgot");
  const [fields, setfields] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    props.apiError();
  }, []);

  const handleChange = (name) => (e) => {
    const { value } = e.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  function handleSubmit(e) {
    e.preventDefault();

    const callback = () => {
      setpage("reset");
    };

    props.userForgetPassword(fields, callback);
  }

  function handleResetSubmit(e) {
    e.preventDefault();

    const callback = () => {
      setTimeout(() => {
        history.replace("/");
      }, 1000);
    };

    props.userResetPassword(fields, callback);
  }

  return (
    <section id="login">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="sign-in-top">
              <Link to="/">
                <img src={logodark} className="img-fluid registerlogo" />
              </Link>
              <h5>Unicorn Wallet</h5>
            </div>
          </div>
        </div>

        {page === "forgot" ? (
          <ForgotPassword
            {...props}
            fields={fields}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
          />
        ) : page === "reset" ? (
          <ResetPassword
            {...props}
            fields={fields}
            handleSubmit={handleResetSubmit}
            handleChange={handleChange}
          />
        ) : null}
      </div>
    </section>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
  userResetPassword: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg, loading } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg, loading };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword, userResetPassword, apiError })(
    ForgetPasswordPage
  )
);
