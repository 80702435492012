import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import datauser from "./data.json";

//i18n
import { withTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import { getContactEmail, getContactEmailSuccess } from "store/actions";
import UsersColumns, { selectRow } from "./Columns";
import UsersConfirmModal from "./ConfirmModal";

import { STORE_FIELD_NAME, STATUS_VALUE, TRIAL_VALUE } from "helpers/contants";

const statusFilter = { fieldName: "status", fieldValue: { $ne: "temp" } };

const ContactEmail = (props) => {
  const {
    accessLevel,
    history,
    users,
    totalUsers,
    getContactEmailSuccess,
    onGetUsers,
    loading,
  } = props;


  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });

  const [filter, setFilter] = useState({
    orderBy: "created_at",
    order: -1,
    page: 0 ,
    limit: 20,
   
 
  });
  const [lastFields, setLastFields] = useState({
    fieldName: "",
    fieldValue: "",
  });
  const [searchText, setSearchText] = useState("");
  const [userList, setUsersList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalUsers,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    getContactEmailSuccess({ data: [], totalcount: 0 });
    onGetUsers({ ...filter});
    // setUsersList(users?.resdata);
  }, [JSON.stringify(filter), lastFields.fieldValue]);

  useEffect(() => {
    setUsersList(users|| []);
  }, [users]);

  useEffect(() => {
    onGetUsers(filter);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "created_at") {
        sortField = "created_at";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetUsers(filter);
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    onDeleteUser({ _id: userId }, onComplete);
  };

  const onFieldNameChange = (value) => {
    setLastFields({ fieldName: value, fieldValue: "" });
   
  };

  const onFieldValueChange = (value) => {
    let fieldName = lastFields.fieldName;
    let fieldValue = value;

    if (value !== "" && fieldName === "isTrial") {
      fieldName = "billingPlan";
    }

    if (value === "isTrial") {
      fieldName = "isTrial";
      fieldValue = true;
    }

    if (value == "isPaid") {
      fieldName = "isTrial";
      fieldValue = false;
    }

    setLastFields((prevState) => ({ ...prevState, fieldName, fieldValue }));
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };


  // log


  return (
    <React.Fragment>
      <UsersConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Contact Us Email List"
            breadcrumbItem={props.t("ContactEmail")}
            breadcrumbItems={[{ title: props.t("Contact Us Email List") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={userList?.resdata || []}
                        columns={UsersColumns(
                          history,
                          toggleConfirmModal,
                          props.t,
                          accesses
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="6" lg="4">
                                <div className="form-group row mb-2 mr-2">
                                 
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ContactEmail.propTypes = {
  t: PropTypes.any,
  users: PropTypes.array,
  totalUsers: PropTypes.number,
  getContactEmailSuccess: PropTypes.func,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ ContactEmails }) => ({
  loading: ContactEmails.loading,
  users: ContactEmails.customers,
  totalUsers: ContactEmails.customers?.count,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getContactEmailSuccess: (data) => dispatch(getContactEmailSuccess(data)),
    onGetUsers: (data) => {
      dispatch(getContactEmail(data));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactEmail))
);
