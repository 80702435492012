import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import { GET_SUPPORT_LIST } from "./actionTypes";
import { getSupportListSuccess, getSupportListFail } from "./actions";

import { getSupportList } from "helpers/backend_helper";

function* getSupportListSaga({ payload }) {
  try {
    const response = yield call(getSupportList, payload);
    if (response.status == "failure") {
      return yield put(getSupportListFail(response.message));
    }
    yield put(getSupportListSuccess(response));
  } catch (error) {
    yield put(getSupportListFail(error));
  }
}

function* CollectionsSaga() {
  yield takeEvery(GET_SUPPORT_LIST, getSupportListSaga);
}

export default CollectionsSaga;
