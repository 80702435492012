import {
  GET_TOPDAPP_LIST,
  GET_TOPDAPP_LIST_SUCCESS,
  GET_TOPDAPP_LIST_FAIL,
  ADD_TOPDAPP,
  ADD_TOPDAPP_SUCCESS,
  ADD_TOPDAPP_FAIL,
  UPDATE_TOPDAPP,
  UPDATE_TOPDAPP_FAIL,
  UPDATE_TOPDAPP_SUCCESS,
  DELETE_TOPDAPP,
  DELETE_TOPDAPP_SUCCESS,
  DELETE_TOPDAPP_FAIL,
  GET_TOPDAPP,
  GET_TOPDAPP_SUCCESS,
  GET_TOPDAPP_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  TopDappList: [],
  TopDappDetails: {},
  totaltopDapps: 0,
};

const TopDapp = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get TopDapp */
    case GET_TOPDAPP_LIST:
    case UPDATE_TOPDAPP:
    case ADD_TOPDAPP:
    case DELETE_TOPDAPP:
    case GET_TOPDAPP:
      return {
        ...state,
        loading: true,
      };

    case GET_TOPDAPP_LIST_SUCCESS:
      return {
        ...state,
        TopDappList: action.payload.data || [],
        loading: false,
        error: "",
      };
    case GET_TOPDAPP_SUCCESS:
      return {
        ...state,
        TopDappDetails: action.payload.data,
        totaltopDapps: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_TOPDAPP_LIST_FAIL:
    case UPDATE_TOPDAPP_FAIL:
    case ADD_TOPDAPP_FAIL:
    case UPDATE_TOPDAPP_SUCCESS:
    case DELETE_TOPDAPP_SUCCESS:
    case DELETE_TOPDAPP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get TopDapp END */

    default:
      return state;
  }
};

export default TopDapp;
