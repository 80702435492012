import {
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_SUCCESS,
  GET_CURRENCY_LIST_FAIL,
  ADD_CURRENCY,
  ADD_CURRENCY_SUCCESS,
  ADD_CURRENCY_FAIL,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_FAIL,
  UPDATE_CURRENCY_SUCCESS,
  DELETE_CURRENCY,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAIL,
  GET_CURRENCY,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  CurrencyList: [],
  CurrencyDetails: {},
  totalCurrency: 0
};

const Currency = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get CURRENCY */
    case GET_CURRENCY_LIST:
    case UPDATE_CURRENCY:
    case ADD_CURRENCY:
    case DELETE_CURRENCY:
    case GET_CURRENCY:
      return {
        ...state,
        loading: true,
      };

    case GET_CURRENCY_LIST_SUCCESS:
      return {
        ...state,
        CurrencyList: action.payload.data || [],
        totalCurrency: action.payload.total || 0,
        loading: false,
        error: "",
      };
    case GET_CURRENCY_SUCCESS:
      return {
        ...state,
        CurrencyDetails: action.payload.data,
        loading: false,
        error: "",
      };

    case GET_CURRENCY_LIST_FAIL:
    case UPDATE_CURRENCY_FAIL:
    case ADD_CURRENCY_FAIL:
    case UPDATE_CURRENCY_SUCCESS:
    case DELETE_CURRENCY_SUCCESS:
    case DELETE_CURRENCY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get CURRENCY END */

    default:
      return state;
  }
};

export default Currency;
