/** Super Admin apis start */

// LOGIN
export const POST_LOGIN = "/admin/login";
export const STATUS_CHANGE_BY_ADMIN = "/admin/status-change";
export const CHANGE_PASSWORD = "/admin/changepassword";
export const GET_LANGUAGES = "/setting/configs";
export const DELETE_STORE = "/super-admin/deletestore";

// CUSTOMERS (STORES)
export const UPDATE_STORE_STATUS = "/super-admin/store/updateStatus";
export const PUT_STORE_KEYS = "/super-admin/store/keys";
export const POST_CHECK_MAIL = "/super-admin/checkMailgunEmail";
export const POST_HIDE_THINGS = "/super-admin/store/hidethings";
export const DELETE_CUSTOMER = "/admin/remove-user";
export const ADD_CUSTOMER = "/admin/create-user";

// Sub-Admins
export const GET_SUB_ADMINS = "/super-admin/getSuperAdminUsersWithFilter";
export const ADD_SUB_ADMIN = "/super-admin/add";
export const GET_SUB_ADMIN = "/super-admin/getSuperAdminById";
export const PUT_SUB_ADMIN = "/super-admin/updateSuperAdminById";
export const DELETE_SUB_ADMIN = "/super-admin/delete";

// Logs
export const GET_LOGS = "/super-admin/getLog";
export const POST_REBUILD_SCRIPT = "/super-admin/rebuild";
export const POST_STORE_REBUILD_SCRIPT =
  "/super-admin/updateRebuildScriptByStoreId";
export const POST_STORE_SETTINGS_SCRIPT = "/super-admin/rebuildSettingScript";
export const POST_CLEAR_LOGS = "/super-admin/clearlogs";

// Email Template
export const GET_EMAIL_TEMPLATES = "/super-admin/templates";
export const POST_EMAIL_TEMPLATE = "/super-admin/template/add";
export const PUT_EMAIL_TEMPLATE = "/super-admin/templates/update";
export const DELETE_EMAIL_TEMPLATE = "/super-admin/templates/remove";
export const GET_EMAIL_TEMPLATE = "/super-admin/template/view";

// Billing Products
// export const POST_ITEM = "/super-admin/billingproduct/add"
export const PUT_ITEM = "/super-admin/billingproduct/update";
// export const GET_ITEM = "/super-admin/billingproduct/view"

// Collections
// export const GET_COLLECTIONS = "/super-admin/billingplan/list"
export const POST_COLLECTION = "/collection/admin/add";
export const PUT_COLLECTION = "/super-admin/billingplan/update";
export const DELETE_COLLECTION = "/super-admin/billingplan/remove";
// export const GET_COLLECTION = "/super-admin/billingplan/view"
export const PUT_COLLECTION_STATUS =
  "/super-admin/billingplan/updatestatus/all";
export const UPDATE_STORE_COLLECTION = "/super-admin/store/updatePlan";
export const PUT_COLLECTION_DISCOUNT = "/super-admin/billingplan/discount";

// Domain
export const UPDATE_DNS = process.env.REACT_APP_UPDATE_DNS;
export const ADD_DOMAIN = "/super-admin/domain/add";
export const SEARCH_DOMAIN = "/super-admin/domain/search";
export const BUY_DOMAIN = "/super-admin/domain/buy";

// Transactions
export const GET_TRANSACTIONS = "/admin/buy-sell-report";
export const GET_BORROW_TRANSACTIONS = "/lendingBorrow/stats";
export const POST_STORE_CHARGE = "/super-admin/store/charge";
export const POST_STORE_REFUND = "/super-admin/store/refund";

export const GET_REFERRALS = "/referral/admin/get-referral-list";
export const DELETE_REFERRALS = "/referral/admin/delete-referral";

// Store User Login
export const STORE_LOGIN_BY_ADMIN = "/super-admin/store/generate-token";

/** Super Admin apis end */

export const POST_FORGOT_PASSWORD = "/store/forgotpassword";
export const POST_RESET_PASSWORD = "/store/resetpassword";
export const SOCIAL_LOGIN = "/social-login";

// Profile
export const GET_CURRENT_USER = "/store/me";
export const PUT_PROFILE = "/store/updateprofile";

// SIGNUP
export const POST_REGISTER_EMAIL = "/store/signupemail";
export const POST_STORE_NAME = "/store/check";
export const POST_REGISTER = "/store/signup";

// File
export const POST_FILE = "/file/add";
export const POST_ITEM_FILE = "/file/ipfs";
export const POST_REMOVE_FILE = "/file/remove";

// Settings
export const API_SETTINGS = "/setting";
export const GET_SETTINGS = "/setting/view";
export const PUT_SETTINGS = "/setting/update";
export const GET_STORE_SETTINGS = "storetype";
export const PUT_STORE_SETTINGS = "addsetting";
export const GET_ACCESS_LIST = "/setting/accesslist";
export const GET_STORE_TYPES = "/setting/storetypes";
export const GET_STARTED = "/setting/getStarted";
export const POST_CONTRACT_DEPLOY = "/setting/admin/deploycontract";
export const POST_COMPILE_CONTRACT = "/setting/admin/compiletoken";

export const POST_BORROW_CONTRACT_DEPLOY =
  "/setting/admin/compilelandingborrowing";
export const POST_COMPILE_BORROW_CONTRACT =
  "/setting/admin/deploylandingborrowingcontract";

// Category
export const ADD_CATEGORY = "/category/admin/add";
export const VIEW_CATEGORIES = "/category/admin/list";
export const DELETE_ITEM = "/category/admin/remove";
export const PUT_ITEM_STATUS = "/category/admin/updatestatus/all";
export const VIEW_CATEGORY = "/category/admin/view";
export const UPDATE_CATEGORY = "/category/admin/update";

// Users
export const GET_USERS = "/user/admin/list";
export const VIEW_USER = "/user/admin/view";

// Collections
export const GET_COLLECTIONS = "/collection/admin/list";
export const VIEW_COLLECTION = "/collection/admin/view";
export const GET_COLLECTION = "/collection/admin/view";
export const COMPILE_DEPLOY_COLLECTIONS =
  "/collection/admin/compile-and-deploy-contract";

// Items
// export const VIEW_ITEM_ACTIVITY = "/item/activity"
export const GET_ITEMS = "/item/admin/list";
export const GET_ITEM = "/item/admin/view";
export const POST_ITEM = "/item/admin/add";
export const MINT_ITEM = "/item/admin/mint";

// Activity
export const GET_ACTIVITIES = "/item/activity";

// FAQS
export const GET_FAQS = "/faqs/list/withFilter";
export const ADD_FAQ = "/faq/add";
export const GET_VIEW_FAQ = "/faq/view";
export const UPDATE_FAQ = "/faq/edit";
export const DELETE_FAQ = "/faq/remove";
export const PUT_FAQS_STATUS = "/pages/updatestatus/all";

//crypto
export const GET_CRYPTO_LIST = "/crypto/admin/list";
export const GET_CRYPTO_ADD = "/crypto/admin/add";
export const DELETE_CRYPTO = "/crypto/admin/remove/";
export const UPDATE_CRYPTO = "/crypto/admin/update/";
export const GET_CRYPTO = "/crypto/admin/view/";

//currency
export const GET_CURRENCY_LIST = "/currency/admin/list";
export const GET_CURRENCY_ADD = "/currency/admin/add";
export const DELETE_CURRENCY = "/currency/admin/remove/";
export const UPDATE_CURRENCY = "/currency/admin/update/";
export const GET_CURRENCY = "/currency/admin/view/";

//TOPDAPP
export const GET_TOPDAPP_LIST = "/dapps/list/withFilter";
export const GET_TOPDAPP_ADD = "/dapp/add";
export const DELETE_TOPDAPP = "/dapp/remove";
export const UPDATE_TOPDAPP = "/dapp/edit";
export const GET_TOPDAPP = "/dapp/view/";

///support

export const GET_SUPPORT_LIST = "/support/list";

// Contact-Emails
export const GET_CONTACT_EMAIL = "/subscriber/list";

// CONTENTS
export const GET_CONTENTS = "/pages/admin/list";
export const ADD_CONTENT = "/pages/admin/add";
export const GET_CONTENT = "/pages/admin/view/";
export const PUT_CONTENT = "/pages/admin/update";
export const DELETE_CONTENT = "/pages/admin/remove";
export const PUT_CONTENTS_STATUS = "/pages/updatestatus/all";

// Blogs
export const GET_BLOGS = "/blog/get-blog-withFilter";
export const ADD_BLOGS = "/blog/add-blog";
export const UPDATE_BLOGS = "/blog/update-blog";
export const GET_BLOGS_BY_ID = "/blog/get-blog/";
export const DELETE_BLOGS = "/blog/delete-blog";

export const GET_COMPANY_PAGE_CONTENT = "/company/get-company-details";
export const UPDATE_COMPANY_PAGE_CONTENT = "/company/update-company-details"
