import { call, put, takeEvery } from "redux-saga/effects";
import * as CONST from "./actionTypes";
import * as ACT from "./actions";
import { getBorrowTransactions, getTransactions } from "helpers/backend_helper";

function* getTransactionSaga({ payload }) {
  try {
    const response = yield call(getTransactions, payload);
    if (response.status == "failure") {
      return yield put(ACT.getTransactionFail(response.message));
    }
    yield put(ACT.getTransactionSuccess(response));
  } catch (error) {
    yield put(ACT.getTransactionFail(error));
  }
}


function* getBorrowTransactionSaga({ payload }) {
  try {
    const response = yield call(getBorrowTransactions, payload);
    if (response.status == "failure") {
      return yield put(ACT.getBorrowTransactionsFail(response.message));
    }
    yield put(ACT.getBorrowTransactionsSuccess(response));
  } catch (error) {
    yield put(ACT.getBorrowTransactionsFail(error));
  }
}


function* transactionSaga() {
    yield takeEvery(CONST.GET_TRANSACTION, getTransactionSaga);
    yield takeEvery(CONST.GET_BORROW_TRANSACTIONS, getBorrowTransactionSaga);

  }
  
  export default transactionSaga;
