import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  GET_CURRENCY_LIST,
  ADD_CURRENCY,
  DELETE_CURRENCY,
  UPDATE_CURRENCY,
  GET_CURRENCY,
} from "./actionTypes";
import {
  getCurrencyListSuccess,
  getCurrencyListFail,
  addCurrencyFail,
  addCurrencySuccess,
  udpateCurrencyFail,
  udpateCurrencySuccess,
  getCurrencyFail,
  getCurrencySuccess,
  deleteCurrencyFail,
  deleteCurrencySuccess,
} from "./actions";

import {
  getCurrencyList,
  addCurrency,
  updateCurrency,
  getCurrency,
  deleteCurrency,
} from "helpers/backend_helper";

function* getCurrencySaga({ payload }) {
  try {
    const response = yield call(getCurrencyList, payload);
    if (response.status == "failure") {
      return yield put(getCurrencyListFail(response.message));
    }
    yield put(getCurrencyListSuccess(response));
  } catch (error) {
    yield put(getCurrencyListFail(error));
  }
}
function* addCurrencySaga({ payload: { data, callback } }) {
  try {
    const response = yield call(addCurrency, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(addCurrencyFail(response.message));
    }
    yield put(addCurrencySuccess(response));
  } catch (error) {
    yield put(addCurrencyFail(error));
  }
}
function* updateCurrencySaga({ payload: { data, callback } }) {
  try {
    const response = yield call(updateCurrency, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(udpateCurrencyFail(response.message));
    }
    yield put(udpateCurrencySuccess(response));
  } catch (error) {
    yield put(udpateCurrencyFail(error));
  }
}

function* deleteCurrencySaga({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCurrency, data.id);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(deleteCurrencyFail(response.message));
    }
    yield put(deleteCurrencySuccess(response));
  } catch (error) {
    yield put(deleteCurrencyFail(error));
  }
}

function* getCurrencyDetailSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getCurrency, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getCurrencyFail(response.message));
    }
    yield put(getCurrencySuccess(response));
  } catch (error) {
    yield put(getCurrencyFail(error));
  }
}

function* CollectionsSaga() {
  yield takeEvery(GET_CURRENCY_LIST, getCurrencySaga);
  yield takeEvery(ADD_CURRENCY, addCurrencySaga);
  yield takeEvery(UPDATE_CURRENCY, updateCurrencySaga);
  yield takeEvery(DELETE_CURRENCY, deleteCurrencySaga);
  yield takeEvery(GET_CURRENCY, getCurrencyDetailSaga);
}

export default CollectionsSaga;
