import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { BLOCKCHAIN_CURRENCY_CODE, sliceWalletAddress } from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (t, type, toggleConfirmModal) => [
  {
    text: t("Name"),
    dataField: "name",
    sort: true,
  },
  {
    text: t("Email"),
    dataField: "email",
    sort: true,
  },
  {
    text: t("Created At"),
    dataField: "createdAt",
    sort: true,
    formatter: (_, row) =>
      moment(row.createdAt).format("DD MMM YYYY - hh:mm A"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link
          to={`/`}
          onClick={(e) => {
            e.preventDefault();
            toggleConfirmModal(row);
          }}
          className="mr-3 text-secondary"
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default ListColumns;
