import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import toastr from "toastr"
toastr.options = {
  newestOnTop: true,
  progressBar: true,
}

// Crypto Redux States
import { ADD_DOMAIN, CLEAR_DOMAIN } from "./actionTypes"
import { addDomainSuccess, addDomainFail, clearDomainSuccess } from "./actions"

import { addDomain, updateDns } from "helpers/backend_helper"

function* fetchDomain({ payload: { data, callback } }) {
  try {
    const response = yield call(addDomain, data)
    if (response.status == "failure") {
      toastr.error(response.message)
    }
    yield put(addDomainSuccess(response))
    toastr.success(response.message)

    callback && callback(response.data)
  } catch (error) {
    yield put(addDomainFail(error))
  }
}

function* clearDomainFn() {
  yield put(clearDomainSuccess())
}

function* domainSaga() {
  yield takeEvery(ADD_DOMAIN, fetchDomain)
  yield takeEvery(CLEAR_DOMAIN, clearDomainFn)
}

export default domainSaga
