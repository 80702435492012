export const EMAIL_TEMPLATE_API_FAIL = "EMAIL_TEMPLATE_API_FAIL"

// EMAIL_TEMPLATEs
export const GET_EMAIL_TEMPLATES = "GET_EMAIL_TEMPLATES"
export const GET_EMAIL_TEMPLATES_FAIL = "GET_EMAIL_TEMPLATES_FAIL"
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS"

// Get EMAIL_TEMPLATE
export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE"
export const GET_EMAIL_TEMPLATE_FAIL = "GET_EMAIL_TEMPLATE_FAIL"
export const GET_EMAIL_TEMPLATE_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS"

// update EMAIL_TEMPLATE
export const PUT_EMAIL_TEMPLATE = "PUT_EMAIL_TEMPLATE"
export const PUT_EMAIL_TEMPLATE_FAIL = "PUT_EMAIL_TEMPLATE_FAIL"
export const PUT_EMAIL_TEMPLATE_SUCCESS = "PUT_EMAIL_TEMPLATE_SUCCESS"

// Post Email Template
export const POST_EMAIL_TEMPLATE = "POST_EMAIL_TEMPLATE"
export const POST_EMAIL_TEMPLATE_FAIL = "POST_EMAIL_TEMPLATE_FAIL"
export const POST_EMAIL_TEMPLATE_SUCCESS = "POST_EMAIL_TEMPLATE_SUCCESS"

// Delete Email Template
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE"
export const DELETE_EMAIL_TEMPLATE_FAIL = "DELETE_EMAIL_TEMPLATE_FAIL"
export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS"
