import {
  CUSTOMER_LOADING,
  API_FAIL,

  GET_CONTACT_EMAILS,
  GET_CONTACT_EMAIL_SUCCESS,
  GET_CONTACT_EMAIL_FAIL
} from "./actionTypes"



export const getContactEmail = (data) => ({
  type: GET_CONTACT_EMAILS,
  payload: data,
})

export const getContactEmailSuccess = (customers) => ({
  type: GET_CONTACT_EMAIL_SUCCESS,
  payload: customers,
})

export const getContactEmailFail = (error) => ({
  type: GET_CONTACT_EMAIL_FAIL,
  payload: error,
})
/* Store check mail END */
