import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Alert
import Alert from "./Alert/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Register from "./auth/register/reducer";
import Settings from "./Settings/reducer";
import customers from "./customers/reducer";
import domain from "./domain/reducer";
import collections from "./collection/reducer";
import items from "./items/reducer";
import Referrals from "./referrals/reducer";

import Activities from "./activity/reducer";

// SUbadmins
import SubAdmins from "./subAdmins/reducer";

// Files
import File from "./File/reducer";

// Logs
import Logs from "./logs/reducer";

// Email Templates
import emailtemplates from "./emailtemplates/reducer";

// Faqs
import faqs from "./faq/reducer";

//crypto
import crypto from "./crypto/reducer";

//currencies
import currencies from "./currencies/reducer";
import content from "./content/reducer"

import topDapps from "./top_dapps/reducer";
import Support from "./Support/reducer";

import Transaction from "./transaction/reducer";

import ContactEmails from "./contact-emails/reducer";
import Blogs from "./blogs/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Alert,
  Login,
  Activities,
  collections,

  items,
  ForgetPassword,
  Profile,
  Register,
  Settings,
  domain,
  customers,
  SubAdmins,
  File,
  Logs,
  crypto,
  currencies,
  emailtemplates,
  faqs,
  topDapps,
  Support,
  Transaction,
  ContactEmails,
  content,

  Referrals,
  Blogs
});

export default rootReducer;
