import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip, Button } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history,toggleConfirmModal, accesses, t, toggle) => [
 
  {
    dataField: "pointsEarned",
    text: t("Earned Points"),
  },
  {
    dataField: "referredBy",
    text: t("Referred By"),
  },
  {
    dataField: "referredTo",
    text: t("Referred To"),
  },

 
  {
    text: t("Referral Date"),
    dataField: "referralDate",
    sort: true,
    formatter: (_, row) => moment(row.referralDate).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal(row._id);
            }}
            className="text-secondary"
          >
            <i
              className="fas fa-trash-alt mr-3"
              id={`delete-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link>
      </>
    ),
  },
 
];

export default ListColumns;
