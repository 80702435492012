export const TopDapp_API_FAIL = "COLLECTION_API_FAIL";

// TopDapp
export const GET_TOPDAPP_LIST = "GET_TOPDAPP_LIST";
export const GET_TOPDAPP_LIST_FAIL = "GET_TOPDAPP_LIST_FAIL";
export const GET_TOPDAPP_LIST_SUCCESS = "GET_TOPDAPP_LIST_SUCCESS";

export const ADD_TOPDAPP = "ADD_TOPDAPP";
export const ADD_TOPDAPP_FAIL = "ADD_TOPDAPP_FAIL";
export const ADD_TOPDAPP_SUCCESS = "ADD_TOPDAPP_SUCCESS";

export const DELETE_TOPDAPP = "DELETE_TOPDAPP";
export const DELETE_TOPDAPP_FAIL = "DELETE_TOPDAPP_FAIL";
export const DELETE_TOPDAPP_SUCCESS = "DELETE_TOPDAPP_SUCCESS";

export const UPDATE_TOPDAPP = "UPDATE_TOPDAPP";
export const UPDATE_TOPDAPP_FAIL = "UPDATE_TOPDAPP_FAIL";
export const UPDATE_TOPDAPP_SUCCESS = "UPDATE_TOPDAPP_SUCCESS";

export const GET_TOPDAPP = "GET_TOPDAPP";
export const GET_TOPDAPP_FAIL = "GET_TOPDAPP_FAIL";
export const GET_TOPDAPP_SUCCESS = "GET_TOPDAPP_SUCCESS";
