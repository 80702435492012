import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

// Images

const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={12}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Heading 1 English</Label>
                <Input
                  type="text"
                  value={fields.heading1En}
                  onChange={(e) => handleChange(null)("heading1En")(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Heading 1 Arabic</Label>
                <Input
                  type="text"
                  value={fields.heading1Ar}
                  onChange={(e) => handleChange(null)("heading1Ar")(e)}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Description English</Label>
                <Input
                  type="textarea"
                  value={fields.descriptionEn}
                  onChange={(e) => handleChange(null)("descriptionEn")(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Description Arabic</Label>
                <Input
                  type="textarea"
                  value={fields.descriptionAr}
                  onChange={(e) => handleChange(null)("descriptionAr")(e)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
