export const COLLECTION_API_FAIL = "COLLECTION_API_FAIL"

// COLLECTIONs
export const GET_COLLECTIONS = "GET_COLLECTIONS"
export const GET_COLLECTIONS_FAIL = "GET_COLLECTIONS_FAIL"
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS"

// Add COLLECTION
export const ADD_COLLECTION = "ADD_COLLECTION"
export const ADD_COLLECTION_FAIL = "ADD_COLLECTION_FAIL"
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS"

// Get COLLECTION
export const GET_COLLECTION = "GET_COLLECTION"
export const GET_COLLECTION_FAIL = "GET_COLLECTION_FAIL"
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS"

// update COLLECTION
export const PUT_COLLECTION = "PUT_COLLECTION"
export const PUT_COLLECTION_FAIL = "PUT_COLLECTION_FAIL"
export const PUT_COLLECTION_SUCCESS = "PUT_COLLECTION_SUCCESS"

// Delete COLLECTION
export const DELETE_COLLECTION = "DELETE_COLLECTION"
export const DELETE_COLLECTION_FAIL = "DELETE_COLLECTION_FAIL"
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS"

// Update Multi COLLECTION Status
export const PUT_COLLECTIONS_STATUS = "PUT_COLLECTIONS_STATUS"
export const PUT_COLLECTIONS_STATUS_FAIL = "PUT_COLLECTIONS_STATUS_FAIL"
export const PUT_COLLECTIONS_STATUS_SUCCESS =
  "PUT_COLLECTIONS_STATUS_SUCCESS"

// Deploy COLLECTIONs
export const COMPILE_DEPLOY_COLLECTIONS = "COMPILE_DEPLOY_COLLECTIONS"
export const COMPILE_DEPLOY_COLLECTIONS_SUCCESS = "COMPILE_DEPLOY_COLLECTIONS_SUCCESS"
export const COMPILE_DEPLOY_COLLECTIONS_FAIL = "COMPILE_DEPLOY_COLLECTIONS_FAIL"
