import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import toastr from "toastr"
// Customer Redux States
import {
  GET_CONTACT_EMAILS,
} from "./actionTypes"
import {
  getContactEmailSuccess,
  getContactEmailFail,
} from "./actions"

import * as API from "helpers/backend_helper";

toastr.options = {
  newestOnTop: true,
  progressBar: true,
}

function* fetchContactEmails({ payload }) {
  try {
    const response = yield call(API.getContactEmails, payload);
    if (response.status == "failure") {
      return yield put(getContactEmailFail(response.message))
    }

    yield put(getContactEmailSuccess(response))
  } catch (error) {
    yield put(getContactEmailFail(error))
  }
}



function* emailContactsSaga() {
  yield takeLatest(GET_CONTACT_EMAILS, fetchContactEmails)
}

export default emailContactsSaga
