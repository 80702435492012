import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import logo from "../../assets/images/logoopen.png";
// import logodark2x from "../../assets/images/logoopen.png";
// import logoheader from "../../assets/images/logo.png";
// import logoheader2x from "../../assets/images/logoopen.png";

// actions
import { loginUser, apiError } from "store/actions";

// import images
// import logo from "assets/images/ferrylogo.png";

const Login = (props) => {
  const { loading } = props;

  const [fields, setfields] = useState({ email: "", password: "" });

  useEffect(() => {
    props.apiError();
  }, []);

  const handleChange = (name) => (e) => {
    const { value } = e.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    props.loginUser(fields, props.history);
  };

  return (
    <section id="login">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="sign-in-top">
              <img src={logo} alt="" className="img-fluid registerlogo" />
              {/* <div id="site-logo" className="clearfix">
                <div id="site-logo-inner">
                  <Link to="/" rel="home" className="main-logo">
                    <img
                      className="logo-dark"
                      id="logo_header"
                      src={logodark}
                      srcSet={`${logodark2x}`}
                      alt="nft-gaming"
                    />
                    <img
                      className="logo-light"
                      id="logo_header"
                      src={logoheader}
                      srcSet={`${logoheader2x}`}
                      alt="nft-gaming"
                    />
                  </Link>
                </div>
              </div>  */}
            </div>
          </div>
        </div>

        <form
          id="loginform"
          onSubmit={() => handleSubmit()}
          className="spinner-content position-relative"
        >
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          {props.error && typeof props.error === "string" ? (
            <Alert color="danger">{props.error}</Alert>
          ) : null}

          <div className="row">
            <div className="col-lg-12">
              <h1>Sign in</h1>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Your email</label>
                <input
                  type="text"
                  placeholder="Your email"
                  value={fields?.email}
                  onChange={handleChange("email")}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  value={fields?.password}
                  onChange={handleChange("password")}
                />
              </div>

              {/* <div className="forgor-password">
                <Link to="/forgot-password" className="colortextlink">
                  Forgot Password?
                </Link>
              </div> */}
            </div>

            <div className="col-lg-12">
              <button
                type="submit"
                className="themebtn fullwidthbtn text-white"
                id="nextBtn"
                onClick={handleSubmit}
              >
                Sign in
              </button>
            </div>
          </div>
        </form>

        {/* <div className="col-lg-12">
          <div className="reglink">
            <Link to="/signup" className="colortextlink">
              Don't have an account?
            </Link>
          </div>
        </div> */}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
