import {
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_SUCCESS,
  GET_CURRENCY_LIST_FAIL,
  ADD_CURRENCY,
  ADD_CURRENCY_SUCCESS,
  ADD_CURRENCY_FAIL,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_FAIL,
  UPDATE_CURRENCY_SUCCESS,
  GET_CURRENCY,
  GET_CURRENCY_FAIL,
  GET_CURRENCY_SUCCESS,
  DELETE_CURRENCY,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAIL,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: CURRENCY_API_FAIL,
  payload: error,
});

/* Get Currency */
export const getCurrencyList = (data) => ({
  type: GET_CURRENCY_LIST,
  payload: data,
});

export const getCurrencyListSuccess = (currency) => ({
  type: GET_CURRENCY_LIST_SUCCESS,
  payload: currency,
});

export const getCurrencyListFail = (error) => ({
  type: GET_CURRENCY_LIST_FAIL,
  payload: error,
});
/* Get Currency END */

/* add Currency */
export const addCurrency = (data, callback) => ({
  type: ADD_CURRENCY,
  payload: { data, callback },
});

export const addCurrencySuccess = (currency) => ({
  type: ADD_CURRENCY_SUCCESS,
  payload: currency,
});

export const addCurrencyFail = (error) => ({
  type: ADD_CURRENCY_FAIL,
  payload: error,
});
/* add Currency END */

/* udpate Currency */
export const udpateCurrency = (data, callback) => ({
  type: UPDATE_CURRENCY,
  payload: { data, callback },
});

export const udpateCurrencySuccess = (currency) => ({
  type: UPDATE_CURRENCY_SUCCESS,
  payload: currency,
});

export const udpateCurrencyFail = (error) => ({
  type: UPDATE_CURRENCY_FAIL,
  payload: error,
});
/* udpate Currency END */

/* get Currency */
export const getCurrency = (data, callback) => ({
  type: GET_CURRENCY,
  payload: { data, callback },
});

export const getCurrencySuccess = (currency) => ({
  type: GET_CURRENCY_SUCCESS,
  payload: currency,
});

export const getCurrencyFail = (error) => ({
  type: GET_CURRENCY_FAIL,
  payload: error,
});
/* get Currency END */

/* get Currency */
export const deleteCurrency = (data, callback) => ({
  type: DELETE_CURRENCY,
  payload: { data, callback },
});

export const deleteCurrencySuccess = (currency) => ({
  type: DELETE_CURRENCY_SUCCESS,
  payload: currency,
});

export const deleteCurrencyFail = (error) => ({
  type: DELETE_CURRENCY_FAIL,
  payload: error,
});
/* get Currency END */
