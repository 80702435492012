import {
  ADD_DOMAIN_SUCCESS,
  ADD_DOMAIN_FAIL,
  CLEAR_DOMAIN_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  domain: {},
  isAvail: false,
  notAvail: false,
  buy: {},
  error: {},
};

const domain = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_DOMAIN_SUCCESS:
      return {
        ...state,
        domain: action.payload.data,
      };

    case ADD_DOMAIN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_DOMAIN_SUCCESS:
      return {
        ...INIT_STATE,
      };
    default:
      return state;
  }
};

export default domain;
