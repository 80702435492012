import { call, put, takeEvery } from "redux-saga/effects";
import * as CONST from "./actionTypes";
import * as ACT from "./actions";
import { deleteReferrals, getReferrals } from "helpers/backend_helper";

function* getReferralSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getReferrals, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACT.getReferralFail(response.message));
    }
    yield put(ACT.getReferralSuccess(response));
  } catch (error) {
    yield put(ACT.getReferralFail(error));
  }
}

function* deleteReferralSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteReferrals, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACT.deleteReferralFail(response.message));
    }
    yield put(ACT.deleteReferralSuccess(response));
  } catch (error) {
    yield put(ACT.deleteReferralFail(error));
  }
}



function* referralsSaga() {
    yield takeEvery(CONST.GET_REFERRAL, getReferralSaga);
    yield takeEvery(CONST.DELETE_REFERRAL, deleteReferralSaga);


  }
  
  export default referralsSaga;
