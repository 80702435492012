import {
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
  PUT_SETTINGS,
  PUT_SETTINGS_FAIL,
  PUT_SETTINGS_SUCCESS,
  GET_ACCESS_LIST,
  GET_ACCESS_LIST_SUCCESS,
  GET_ACCESS_LIST_FAIL,
  GET_STORE_TYPES,
  GET_STORE_TYPES_SUCCESS,
  GET_STORE_TYPES_FAIL,
  GET_STARTED,
  GET_STARTED_SUCCESS,
  GET_STARTED_FAIL,
  POST_CONTRACT_DEPLOY,
  POST_CONTRACT_DEPLOY_SUCCESS,
  POST_CONTRACT_DEPLOY_FAIL,
  POST_COMPILE_CONTRACT,
  POST_COMPILE_CONTRACT_SUCCESS,
  POST_COMPILE_CONTRACT_FAIL,
} from "./actionTypes";

import * as CONST from "./actionTypes";

/* Get Global Settings */
export const getSettings = () => ({
  type: GET_SETTINGS,
});

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsFail = (error) => ({
  type: GET_SETTINGS_FAIL,
  payload: error,
});
/* Get Global Settings END */

/* Update Global Settings */
export const putSettings = (data) => ({
  type: PUT_SETTINGS,
  payload: data,
});

export const putSettingsSuccess = (data) => ({
  type: PUT_SETTINGS_SUCCESS,
  payload: data,
});

export const putSettingsFail = (error) => ({
  type: PUT_SETTINGS_FAIL,
  payload: error,
});
/* Update Global Settings END */

/* Get Access List */
export const getAccessList = () => ({
  type: GET_ACCESS_LIST,
});

export const getAccessListSuccess = (data) => ({
  type: GET_ACCESS_LIST_SUCCESS,
  payload: data,
});

export const getAccessListFail = (error) => ({
  type: GET_ACCESS_LIST_FAIL,
  payload: error,
});
/* Get Access List END */

/* Get Store Types */
export const getStoreTypes = () => ({
  type: GET_STORE_TYPES,
});

export const getStoreTypesSuccess = (data) => ({
  type: GET_STORE_TYPES_SUCCESS,
  payload: data,
});

export const getStoreTypesFail = (error) => ({
  type: GET_STORE_TYPES_FAIL,
  payload: error,
});
/* Get Store Types END */

/* Get Store Started */
export const getStarted = () => ({
  type: GET_STARTED,
});

export const getStartedSuccess = (data) => ({
  type: GET_STARTED_SUCCESS,
  payload: data,
});

export const getStartedFail = (error) => ({
  type: GET_STARTED_FAIL,
  payload: error,
});
/* Get Store Started END */

/* Post Contract Deploy */
export const postDeployContract = (data, callback) => ({
  type: POST_CONTRACT_DEPLOY,
  payload: { data, callback },
});

export const postDeployContractSuccess = (data) => ({
  type: POST_CONTRACT_DEPLOY_SUCCESS,
  payload: data,
});

export const postDeployContractFail = (error) => ({
  type: POST_CONTRACT_DEPLOY_FAIL,
  payload: error,
});
/* Post Contract Deploy */

/* Post Compile Contract */
export const postCompileContract = (data, callback) => ({
  type: POST_COMPILE_CONTRACT,
  payload: { data, callback },
});

export const postCompileContractSuccess = (data) => ({
  type: POST_COMPILE_CONTRACT_SUCCESS,
  payload: data,
});

export const postCompileContractFail = (error) => ({
  type: POST_COMPILE_CONTRACT_FAIL,
  payload: error,
});
/* Post Compile Contract */

/* Post Borrow Deploy */
export const postDeployBorrowContract = (data, callback) => ({
  type: CONST.POST_BORROW_CONTRACT_DEPLOY,
  payload: { data, callback },
});

export const postDeployBorrowContractSuccess = (data) => ({
  type: CONST.POST_BORROW_CONTRACT_DEPLOY_SUCCESS,
  payload: data,
});

export const postDeployBorrowContractFail = (error) => ({
  type: CONST.POST_BORROW_CONTRACT_DEPLOY_FAIL,
  payload: error,
});
/* Post BorrowContract Deploy */

/* Post Compile BorrowContract */
export const postCompileBorrowContract = (data, callback) => ({
  type: CONST.POST_COMPILE_BORROW_CONTRACT,
  payload: { data, callback },
});

export const postCompileBorrowContractSuccess = (data) => ({
  type: CONST.POST_COMPILE_BORROW_CONTRACT_SUCCESS,
  payload: data,
});

export const postCompileBorrowContractFail = (error) => ({
  type: CONST.POST_COMPILE_BORROW_CONTRACT_FAIL,
  payload: error,
});
/* Post Compile Borrow */
