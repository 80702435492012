import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import toastr from "toastr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import { putChangePwd, putChangePwdError } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

const AddDriver = ({ history, error, onPutChangePwd, loading, ...props }) => {
  const [fields, setFields] = useState({
    oldPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    props.putChangePwdError();
  }, []);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    const callback = (response) => {
      if (response.status === "success") {
        toastr.success("Password change successfully.");
        // error = "";

        setFields((pre) => ({
          oldPassword: "",
          newPassword: "",
        }));
      } else {
        toastr.error(response.message);
      }
      history.replace("/change-password");
    };

    onPutChangePwd(
      {
        newPassword: fields.newPassword,
        oldPassword: fields.oldPassword,
      },
      callback
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbItem={props.t("Edit") + " " + props.t("Profile")}
            breadcrumbItems={[
              {
                title: props.t("Change Password"),
              },
            ]}
          />

          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {/* {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null} */}

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-oldPassword-Input">
                            {props.t("Current Password")}
                          </Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="formrow-oldPassword-Input"
                            value={fields.oldPassword || ""}
                            onChange={handleChange("oldPassword")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-newPassword-Input">
                            {props.t("Password")}
                          </Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="formrow-newPassword-Input"
                            value={fields.newPassword || ""}
                            onChange={handleChange("newPassword")}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="mt-4">
                      <button type="submit" className="btn btn-primary w-md">
                        {props.t("Submit")}
                      </button>

                      {/* <button
                        type="button"
                        className="btn outline btn-outline-secondary w-md ml-3"
                        onClick={() => history.goBack()}
                      >
                        <i className="mdi mdi-arrow-left mr-1"></i>
                        {props.t("Back")}
                      </button> */}
                    </div>

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddDriver.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onPutChangePwd: PropTypes.func,
};

const mapStateToProps = ({ Profile }) => ({
  error: Profile.error,
  loading: Profile.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onPutChangePwd: (data, callback) => dispatch(putChangePwd(data, callback)),
  putChangePwdError: (data) => dispatch(putChangePwdError(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddDriver))
);
