import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

// Images

const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Instructions t={props.t} />
        </Col>

        {/* Card 1 */}
        <Col md={6}>
          <FormGroup>
            <Label>Card 1 Description English</Label>
            <Input
              type="textarea"
              value={fields?.card1?.descriptionEn}
              onChange={(e) => handleChange("card1")("descriptionEn")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Card 1 Description Arabic</Label>
            <Input
              type="textarea"
              value={fields?.card1?.descriptionAr}
              onChange={(e) => handleChange("card1")("descriptionAr")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>App Store Link</Label>
            <Input
              type="text"
              value={fields?.card1?.appStoreLink}
              onChange={(e) => handleChange("card1")("appStoreLink")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Play Store Link</Label>
            <Input
              type="text"
              value={fields?.card1?.playStoreLink}
              onChange={(e) => handleChange("card1")("playStoreLink")(e)}
            />
          </FormGroup>
        </Col>

        {/* Card 2 */}
        <Col md={6}>
          <FormGroup>
            <Label>Card 2 Heading 1 English</Label>
            <Input
              type="text"
              value={fields?.card2?.heading1En}
              onChange={(e) => handleChange("card2")("heading1En")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Card 2 Heading 1 Arabic</Label>
            <Input
              type="text"
              value={fields?.card2?.heading1Ar}
              onChange={(e) => handleChange("card2")("heading1Ar")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Card 2 Description English</Label>
            <Input
              type="textarea"
              value={fields?.card2?.descriptionEn}
              onChange={(e) => handleChange("card2")("descriptionEn")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Card 2 Description Arabic</Label>
            <Input
              type="textarea"
              value={fields?.card2?.descriptionAr}
              onChange={(e) => handleChange("card2")("descriptionAr")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Twitter Link</Label>
            <Input
              type="text"
              value={fields?.card2?.twitterLink}
              onChange={(e) => handleChange("card2")("twitterLink")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Instagram Link</Label>
            <Input
              type="text"
              value={fields?.card2?.instagramLink}
              onChange={(e) => handleChange("card2")("instagramLink")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>LinkedIn Link</Label>
            <Input
              type="text"
              value={fields?.card2?.linkedinLink}
              onChange={(e) => handleChange("card2")("linkedinLink")(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Threads Link</Label>
            <Input
              type="text"
              value={fields?.card2?.threadsLink}
              onChange={(e) => handleChange("card2")("threadsLink")(e)}
            />
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
