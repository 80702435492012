export const SUB_ADMIN_API_FAIL = "SUB_ADMIN_API_FAIL"

// SubAdmins
export const GET_SUB_ADMINS = "GET_SUB_ADMINS"
export const GET_SUB_ADMINS_FAIL = "GET_SUB_ADMINS_FAIL"
export const GET_SUB_ADMINS_SUCCESS = "GET_SUB_ADMINS_SUCCESS"

// Add subAdmin
export const ADD_SUB_ADMIN = "ADD_SUB_ADMIN"
export const ADD_SUB_ADMIN_FAIL = "ADD_SUB_ADMIN_FAIL"
export const ADD_SUB_ADMIN_SUCCESS = "ADD_SUB_ADMIN_SUCCESS"

// Get subAdmin
export const GET_SUB_ADMIN = "GET_SUB_ADMIN"
export const GET_SUB_ADMIN_FAIL = "GET_SUB_ADMIN_FAIL"
export const GET_SUB_ADMIN_SUCCESS = "GET_SUB_ADMIN_SUCCESS"

// update subAdmin
export const PUT_SUB_ADMIN = "PUT_SUB_ADMIN"
export const PUT_SUB_ADMIN_FAIL = "PUT_SUB_ADMIN_FAIL"
export const PUT_SUB_ADMIN_SUCCESS = "PUT_SUB_ADMIN_SUCCESS"

// Delete subAdmin
export const DELETE_SUB_ADMIN = "DELETE_SUB_ADMIN"
export const DELETE_SUB_ADMIN_FAIL = "DELETE_SUB_ADMIN_FAIL"
export const DELETE_SUB_ADMIN_SUCCESS = "DELETE_SUB_ADMIN_SUCCESS"
