export const CRYPTO_API_FAIL = "COLLECTION_API_FAIL";

// CRYPTO
export const GET_CRYPTO_LIST = "GET_CRYPTO_LIST";
export const GET_CRYPTO_LIST_FAIL = "GET_CRYPTO_LIST_FAIL";
export const GET_CRYPTO_LIST_SUCCESS = "GET_CRYPTO_LIST_SUCCESS";

export const ADD_CRYPTO = "ADD_CRYPTO";
export const ADD_CRYPTO_FAIL = "ADD_CRYPTO_FAIL";
export const ADD_CRYPTO_SUCCESS = "ADD_CRYPTO_SUCCESS";

export const DELETE_CRYPTO = "DELETE_CRYPTO";
export const DELETE_CRYPTO_FAIL = "DELETE_CRYPTO_FAIL";
export const DELETE_CRYPTO_SUCCESS = "DELETE_CRYPTO_SUCCESS";

export const UPDATE_CRYPTO = "UPDATE_CRYPTO";
export const UPDATE_CRYPTO_FAIL = "UPDATE_CRYPTO_FAIL";
export const UPDATE_CRYPTO_SUCCESS = "UPDATE_CRYPTO_SUCCESS";

export const GET_CRYPTO = "GET_CRYPTO";
export const GET_CRYPTO_FAIL = "GET_CRYPTO_FAIL";
export const GET_CRYPTO_SUCCESS = "GET_CRYPTO_SUCCESS";
