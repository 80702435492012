import {
  COLLECTION_API_FAIL,
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  ADD_COLLECTION,
  ADD_COLLECTION_FAIL,
  ADD_COLLECTION_SUCCESS,
  GET_COLLECTION,
  GET_COLLECTION_FAIL,
  GET_COLLECTION_SUCCESS,
  PUT_COLLECTION,
  PUT_COLLECTION_FAIL,
  PUT_COLLECTION_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_FAIL,
  DELETE_COLLECTION_SUCCESS,
  PUT_COLLECTIONS_STATUS,
  PUT_COLLECTIONS_STATUS_FAIL,
  PUT_COLLECTIONS_STATUS_SUCCESS,
  COMPILE_DEPLOY_COLLECTIONS,
  COMPILE_DEPLOY_COLLECTIONS_SUCCESS,
  COMPILE_DEPLOY_COLLECTIONS_FAIL,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: COLLECTION_API_FAIL,
  payload: error,
});

/* Get collections */
export const getCollections = (data) => ({
  type: GET_COLLECTIONS,
  payload: data,
});

export const getCollectionsSuccess = (collections) => ({
  type: GET_COLLECTIONS_SUCCESS,
  payload: collections,
});

export const getCollectionsFail = (error) => ({
  type: GET_COLLECTIONS_FAIL,
  payload: error,
});
/* Get Collections END */

/* Deploy collections */
export const compileDeployCollection = (data, callback) => ({
  type: COMPILE_DEPLOY_COLLECTIONS,
  payload: { data, callback },
});

export const compileDeployCollectionSuccess = () => ({
  type: COMPILE_DEPLOY_COLLECTIONS_SUCCESS,
});

export const compileDeployCollectionFail = (error) => ({
  type: COMPILE_DEPLOY_COLLECTIONS_FAIL,
  payload: error,
});
/* Deploy Collections END */

/* Add Collection */
export const addCollection = (collection, callback) => ({
  type: ADD_COLLECTION,
  payload: { collection, callback },
});

export const addCollectionFail = (error) => ({
  type: ADD_COLLECTION_FAIL,
  payload: error,
});

export const addCollectionSuccess = () => ({
  type: ADD_COLLECTION_SUCCESS,
});
/* Add Collection END */

/* Get Collection */
export const getCollection = (id) => ({
  type: GET_COLLECTION,
  payload: id,
});

export const getCollectionFail = (error) => ({
  type: GET_COLLECTION_FAIL,
  payload: error,
});

export const getCollectionSuccess = (collection) => ({
  type: GET_COLLECTION_SUCCESS,
  payload: collection,
});
/* Get Collection END */

/* Update Collection */
export const putCollection = (data, history) => ({
  type: PUT_COLLECTION,
  payload: { data, history },
});

export const putCollectionFail = (error) => ({
  type: PUT_COLLECTION_FAIL,
  payload: error,
});

export const putCollectionSuccess = () => ({
  type: PUT_COLLECTION_SUCCESS,
});
/* Update Collection END */

/* Delete Collection */
export const deleteCollection = (data, callback) => ({
  type: DELETE_COLLECTION,
  payload: { data, callback },
});

export const deleteCollectionFail = (error) => ({
  type: DELETE_COLLECTION_FAIL,
  payload: error,
});

export const deleteCollectionSuccess = () => ({
  type: DELETE_COLLECTION_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Collections Status */
export const putCollectionsStatus = (data, callback) => ({
  type: PUT_COLLECTIONS_STATUS,
  payload: { data, callback },
});

export const putCollectionsStatusFail = (error) => ({
  type: PUT_COLLECTIONS_STATUS_FAIL,
  payload: error,
});

export const putCollectionsStatusSuccess = () => ({
  type: PUT_COLLECTIONS_STATUS_SUCCESS,
});
/* Update Multi Collections Status END */
