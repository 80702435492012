import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false };
    this.capitalizeFirstLetter.bind(this);
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    this.setState({ mounted: true });
    this.addScript();
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title = currentage + " | Soorx";

    document.querySelector("body").classList.add("auth-body");
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove("auth-body");
  }

  addScript = () => {
    const script = document.createElement("script");
    const script2 = document.createElement("script");

    script.innerHTML = `window.intercomSettings = {
      app_id: "ch48gtux",
    };`;
    // script2.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ch48gtux';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`

    document.body.appendChild(script);
    //document.body.appendChild(script2)
  };
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
