import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import Toastr from "toastr";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,

} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
  getReferrals,
  deleteReferral


} from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";

const Referrals = ({
  history,
  referralList,
  total,
  onGetReferrals,
  onDeleteReferral,
  loading,
  ...props
}) => {
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    referralId: null,
  });
  const [filter, setFilter] = useState({
    
    page: 0,
    limit: 20,
    fieldName: "name",
        fieldValue: "",
   
  });
  const [searchText, setSearchText] = useState("");
  const [referral, setReferral] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: total,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };
  const { SearchBar } = Search;


 
  useEffect(() => {
    onGetReferrals(filter);
    if(referralList.length >0){
      setReferral(referralList);

    }
  }, [onGetReferrals, JSON.stringify(filter)]);

  useEffect(() => {
    if(referralList.length >0){
      setReferral(referralList || []);

    }
  }, [referralList]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };





  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, fieldValue: searchText }));
  };

  const toggleConfirmModal = (referralId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      referralId,
    }));
  };

  const removeConfirm = () => {
    const { referralId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetReferrals(filter);
    };

    setConfirmModal({ isOpen: !isOpen, referralId: null });
    onDeleteReferral({ _id: referralId }, onComplete);
  };


  return (
    <React.Fragment>
    
    <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Referrals"
            breadcrumbItem={props.t("Referrals")}
            breadcrumbItems={[{ title: props.t("Referrals") }]}
          />

          <Row>
            <Col xs="12"> 
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={referral || []}
                        columns={ListColumns(
                          history,
                          toggleConfirmModal,
                          accesses,
                          props.t,
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              {/* <Col sm="2">
                                <div className="form-group row mb-2 mr-2">
                                  <label className="col-md-5 pr-0 col-form-label">
                                    {props.t("filter_by")}:
                                  </label>

                                  <div className="col-md-7">
                                    <select
                                      className="custom-select"
                                      onChange={(e) =>
                                        onStatusChange(e.target.value)
                                      }
                                      value={filter.fields[1]?.fieldValue}
                                    >
                                      <option value="">
                                        {props.t("status")}
                                      </option>
                                      <option value="active">
                                        {props.t("active")}
                                      </option>
                                      <option value="inactive">
                                        {props.t("inactive")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </Col> */}

                              <Col sm="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />
                              </Col>

                              <Col sm="2">
                                {!!filter?.fieldName ||
                                  (!!filter?.search && (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            fields: [
                                              {
                                                fieldName: "productId",
                                                fieldValue: "",
                                              },
                                              {
                                                fieldName: "",
                                                fieldValue: "",
                                              },
                                            ],
                                            search: "",
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("clear_filters")}
                                      </Link>
                                    </div>
                                  ))}
                              </Col>
                            </Row>

                            {/* {(accesses.canEdit || accesses.canDelete) && (
                              <AnimateHeight
                                duration={500}
                                height={selected.length > 0 ? "auto" : 0}
                              >
                                <Row className="pb-2">
                                  {accesses.canEdit && (
                                    <Col sm="2">
                                      <div className="form-group row">
                                        <label className="col-md-5 pr-0 col-form-label">
                                          {props.t("action")}:
                                        </label>

                                        <div className="col-md-7">
                                          <select
                                            className="custom-select"
                                            onChange={(e) =>
                                              handleCollectionStatusChange(
                                                e.target.value,
                                              )
                                            }
                                          >
                                            <option value="">
                                              {props.t("status")}
                                            </option>
                                            <option value="active">
                                              {props.t("active")}
                                            </option>
                                            <option value="inactive">
                                              {props.t("inactive")}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  {accesses.canDelete && (
                                    <Col sm="2">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          handleCollectionStatusChange(
                                            "archived",
                                          )
                                        }
                                      >
                                        {props.t("delete")}{" "}
                                        {props.t("selected")}
                                      </button>
                                    </Col>
                                  )}

                                  <Col></Col>
                                </Row>
                              </AnimateHeight>
                            )} */}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                    // selectRow={selectRow({
                                    //   selected,
                                    //   onSelect,
                                    //   onSelectAll,
                                    // })}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
   
    </React.Fragment>
  );
};

Referrals.propTypes = {
  referralList: PropTypes.array,
  total: PropTypes.number,

};

const mapStateToProps = ({ Referrals }) => ({
  loading: Referrals.loading,
  referralList: Referrals?.referrals,
  total: Referrals.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetReferrals: (data, callback) =>  dispatch(getReferrals(data, callback)),
  onDeleteReferral: (data, callback) =>  dispatch(deleteReferral(data, callback)),


});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Referrals))
);
