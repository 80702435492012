import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import worldCountries from "world-countries";
import toastr from "toastr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";
import Select from "react-select";

import { addUser, uploadFile, removeFile } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

// Images
import avatar4 from "assets/images/users/avatar-9.jpg";

const optionGroup = worldCountries
  .filter(
    (country) => !!country.flag && country.name.common && country.idd.root
  )
  .map((country) => ({
    name: country.name.common,
    label: country.idd.root + country.idd.suffixes[0] || "",
    value: country.name.common,
    flag: country.flag,
  }));

const CustomOptionComponent = ({ innerProps, innerRef, data, ...props }) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`country-code-option p-2${props.isSelected ? " selected" : ""
        }`}
    >
      <span className="flag">{data.flag}</span>
      <span className="country-code">{data.label}</span>
      <div className="country-name">{data.name}</div>
    </div>
  );
};

const AddCustomer = (props) => {
  const {
    accessLevel,
    history,
    error,
    onAddCustomer,
    loading,
    onUploadFile,
    onRemoveFile,
  } = props;
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    fname: "",
    lname: "",
    mobileNumber: "",
    countryCode: "",
    // profileImage: null,
    email: "",
    password: "",
    // accountStatus: "active",
    kyc: {},
    role: "USER"
  });
  const [countryCodeData, setCountryCode] = useState({});
  const [selectedFiles, setselectedFiles] = useState([]);

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  const uploadFileSuccess = (response) => {
    const { _id: profileImage } = response.data;

    setFields((prevState) => ({ ...prevState, profileImage }));
  };

  function handleAcceptedFiles(files) {
    if (!accesses.canAdd) return;
    if (selectedFiles.length > 0) {
      if (fields.profileImage) {
        onRemoveFile({ _id: fields.profileImage });
        setFields((prevState) => ({ ...prevState, profileImage: null }));
      }
    }
    setFields((prevState) => ({ ...prevState, profileImage: files[0] }));
    onUploadFile({ file: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    let data = { ...fields };

    data.kyc = {
      "fname": fields?.fname,
      "lname": fields?.lname,
      "email": fields?.email,
    };

    const callback = (response) => {
      if (response.status == "failure") {
        toastr.error(response.message);
      } else {
        toastr.success(response.message);
        history.push("/users");
      }
    };

    onAddCustomer({ ...data }, callback);

  };
  const removeSelectedFiles = () => {
    const { profileImage } = fields;

    if (profileImage) {
      onRemoveFile({ _id: profileImage });
      setFields((prevState) => ({ ...prevState, profileImage: null }));
    }

    setselectedFiles([]);
  };

  const handleCountryCode = (data) => {
    setFields((prevState) => ({ ...prevState, countryCode: data.label }));
    setCountryCode(data);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="User"
            breadcrumbItem={props.t("Add") + " " + props.t("User")}
            breadcrumbItems={[
              { title: props.t("User"), link: "/customers" },
              { title: props.t("Add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}
                    {/* <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-profile-image-Input">
                            {props.t("Profile")} {props.t("Image")}
                          </Label>

                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles[0];

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl"
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  <img
                                    className="rounded-circle avatar-xl"
                                    alt={
                                      !!imageFile && imageFile.name
                                        ? imageFile.name
                                        : "Soorx"
                                    }
                                    src={
                                      !!imageFile ? imageFile.preview : avatar4
                                    }
                                  />

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </FormGroup>
                      </Col>
                    </Row> */}

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("First Name")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            value={fields.fname}
                            onChange={handleChange("fname")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Last Name")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            value={fields.lname}
                            onChange={handleChange("lname")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-email-Input">
                            {props.t("Email")}
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            value={fields.email}
                            onChange={handleChange("email")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-mobilenumber-Input">
                            {props.t("Mobile Number")}
                          </Label>

                          <Row>
                            <Col md={2}>
                              <Select
                                placeholder=""
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                                components={{ Option: CustomOptionComponent }}
                                inputId={"country-code-select"}
                                styles={{
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "4px",
                                  }),
                                  menu: (provided) => {
                                    return {
                                      ...provided,
                                      minWidth: "200px",
                                    };
                                  },
                                }}
                                onChange={handleCountryCode}
                                value={countryCodeData}
                              />
                            </Col>

                            <Col md={10}>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-mobilenumber-Input"
                                value={fields.mobileNumber}
                                onChange={handleChange("mobileNumber")}
                                required
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>


                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-password-Input">
                            {props.t("Password")}
                          </Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="formrow-password-Input"
                            value={fields.password}
                            onChange={handleChange("password")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("Status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("Active")}
                              data-off-label={props.t("Inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col> */}
                    </Row>




                    <div className="mt-4">
                      {accesses.canAdd && (
                        <button type="submit" className="btn btn-primary w-md">
                          {props.t("Submit")}
                        </button>
                      )}
                    </div>

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCustomer.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddCustomer: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onAddCustomer: (data, callback) => dispatch(addUser(data, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
);
