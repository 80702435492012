import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Signup from "../pages/Authentication/Signup";

import ProfileEdit from "../pages/Authentication/EditProfile";
import ProfileChangePassword from "../pages/Authentication/ChangePassword";

import Customers_List from "../pages/Customers/CustomersList";
import AddCustomer from "../pages/Customers/AddCustomer";

import BlogsList from "../pages/Blogs/BlogsList";
import AddBlogs from "../pages/Blogs/AddBlogs";

import Merchants_List from "../pages/Merchants/CustomersList";
// import Customers_Profile from "../pages/Customers/CustomerProfile"

import CryptosList from "../pages/Cryptos/List";
import CryptoAdd from "../pages/Cryptos/AddEdit";
import ContactUsEmail from "../pages/Contact-Us-Email-list";
import PromotionEmail from "../pages/Promotion-Email-list";

import CurrencyList from "../pages/Currencies/List";
import CurrencyAdd from "../pages/Currencies/AddEdit";

import ActivitiesList from "../pages/Activities/List";

import TransactionsList from "../pages/Transactions/List";
import ViewCustomer from "../pages/Customers/ViewCustomer";
import ViewMerchant from "../pages/Merchants/ViewCustomer";

import Settings from "../pages/Settings";

import BlockchainNetwork from "../pages/Settings/BlockchainNetwork";
import Wallet from "../pages/Settings/Wallet";

import Faq from "../pages/Faq/List";
import Content from "../pages/Content/List";
import FaqAddEdit from "../pages/Faq/AddEdit";
import ContentAddEdit from "../pages/Content/AddEdit";
import MerchantTransaction from "pages/Merchants/MerchantTransaction";
import Stripe from "pages/Settings/Stripe";
import Transak from "pages/Settings/Transak";
import Uniramp from "pages/Settings/Uniramp-keys";
import TransakKeys from "pages/Settings/Transak-keys";

import Fireblock from "pages/Settings/Fireblock-keys";
import MailChimp from "pages/Settings/Mail-Chimp-Keys";
import TawkTo from "pages/Settings/Tawk-to-keys";
import KycAidKeys from "pages/Settings/Kyc-Aid-Keys";

import ReferralList from "pages/Referrals/List";
import CompanyPage from "pages/CompanyPage/index";
import ContactUs from "pages/contactUS/List";

const userRoutes = (role, accessLevel) => [
  // Logout
  { path: "/logout", exact: true, component: Logout },
  { path: "/edit-profile", exact: true, component: ProfileEdit },
  {
    path: "/change-password",
    exact: true,
    component: ProfileChangePassword,
  },
  { path: "/users", component: Customers_List },
  { path: "/add-user", component: AddCustomer },

  { path: "/blogs", component: BlogsList },
  { path: "/add-blogs", component: AddBlogs },
  { path: "/blogs/:id/edit", component: AddBlogs },

  { path: "/email-contacts", component: ContactUsEmail },
  { path: "/contact-us", component: ContactUs },

  { path: "/promotion-email", component: PromotionEmail },

  { path: "/merchants", component: Merchants_List },
  { path: "/users/profile/:id", component: ViewCustomer },
  { path: "/merchants/profile/:id", component: ViewMerchant },
  { path: "/merchants/transaction/:id", component: MerchantTransaction },

  { path: "/crypto", component: CryptosList },
  { path: "/crypto-add", component: CryptoAdd },
  { path: "/crypto/:id/edit", component: CryptoAdd },

  { path: "/currency", component: CurrencyList },
  { path: "/currency-add", component: CurrencyAdd },
  { path: "/currency/:id/edit", component: CurrencyAdd },

  { path: "/activities", component: ActivitiesList },
  { path: "/referrals", component: ReferralList },

  { path: "/transactions", component: TransactionsList },

  { path: "/general", component: Settings },
  // { path: "/settings", component: Settings },

  { path: "/blockchain", component: BlockchainNetwork },

  { path: "/uniramp", component: Uniramp },
  { path: "/transak", component: TransakKeys },

  { path: "/fireblock", component: Fireblock },
  { path: "/mail-chimp", component: MailChimp },
  { path: "/tawk-to", component: TawkTo },
  { path: "/kyc-keys", component: KycAidKeys },

  { path: "/wallet", component: Wallet },
  { path: "/stripe", component: Stripe },

  { path: "/faqs", component: Faq },
  { path: "/content", component: Content },
  { path: "/faqs/add", component: FaqAddEdit },
  { path: "/content/add", component: ContentAddEdit },
  { path: "/content/:id/edit", component: ContentAddEdit },
  { path: "/faqs/:id/edit", component: FaqAddEdit },

  { path: "/transak", component: Transak },
  { path: "/company-page", component: CompanyPage },

  { path: "/", exact: true, component: () => <Redirect to="/users" /> },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/signup", component: Signup },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { userRoutes, authRoutes };
