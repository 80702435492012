import {
  ADD_DOMAIN,
  ADD_DOMAIN_SUCCESS,
  ADD_DOMAIN_FAIL,
  CLEAR_DOMAIN,
  CLEAR_DOMAIN_SUCCESS,
} from "./actionTypes";

export const addDomain = (data, callback) => {
  return {
    type: ADD_DOMAIN,
    payload: { data, callback },
  };
};

export const addDomainSuccess = (domain) => ({
  type: ADD_DOMAIN_SUCCESS,
  payload: domain,
});

export const addDomainFail = (error) => ({
  type: ADD_DOMAIN_FAIL,
  payload: error,
});

export const clearDomain = () => ({
  type: CLEAR_DOMAIN,
});

export const clearDomainSuccess = () => ({
  type: CLEAR_DOMAIN_SUCCESS,
});
