import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  GET_CRYPTO_LIST,
  ADD_CRYPTO,
  DELETE_CRYPTO,
  UPDATE_CRYPTO,
  GET_CRYPTO,
} from "./actionTypes";
import {
  getCryptoListSuccess,
  getCryptoListFail,
  addCryptoFail,
  addCryptoSuccess,
  udpateCryptoFail,
  udpateCryptoSuccess,
  getCryptoFail,
  getCryptoSuccess,
  deleteCryptoFail,
  deleteCryptoSuccess,
} from "./actions";

import {
  getCryptoList,
  addCrypto,
  updateCrypto,
  getCrypto,
  deleteCrypto,
} from "helpers/backend_helper";

function* getCryptoSaga({ payload }) {
  try {
    const response = yield call(getCryptoList, payload);
    if (response.status == "failure") {
      return yield put(getCryptoListFail(response.message));
    }
    yield put(getCryptoListSuccess(response));
  } catch (error) {
    yield put(getCryptoListFail(error));
  }
}
function* addCryptoSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(addCrypto, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(addCryptoFail(response.message));
    }
    yield put(addCryptoSuccess(response));
  } catch (error) {
    yield put(addCryptoFail(error));
  }
}
function* updateCryptoSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(updateCrypto, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(udpateCryptoFail(response.message));
    }
    yield put(udpateCryptoSuccess(response));
  } catch (error) {
    yield put(udpateCryptoFail(error));
  }
}

function* deleteCryptoSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCrypto, data.id);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(deleteCryptoFail(response.message));
    }
    yield put(deleteCryptoSuccess(response));
  } catch (error) {
    yield put(deleteCryptoFail(error));
  }
}

function* getCryptoDetailSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getCrypto, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getCryptoFail(response.message));
    }
    yield put(getCryptoSuccess(response));
  } catch (error) {
    yield put(getCryptoFail(error));
  }
}

function* CollectionsSaga() {
  yield takeEvery(GET_CRYPTO_LIST, getCryptoSaga);
  yield takeEvery(ADD_CRYPTO, addCryptoSaga);
  yield takeEvery(UPDATE_CRYPTO, updateCryptoSaga);
  yield takeEvery(DELETE_CRYPTO, deleteCryptoSaga);
  yield takeEvery(GET_CRYPTO, getCryptoDetailSaga);
}

export default CollectionsSaga;
