import { call, put, takeEvery } from "redux-saga/effects"

// SubAdmin Redux States
import {
  GET_SUB_ADMINS,
  ADD_SUB_ADMIN,
  GET_SUB_ADMIN,
  PUT_SUB_ADMIN,
  DELETE_SUB_ADMIN,
} from "./actionTypes"
import {
  getSubAdminsFail,
  getSubAdminsSuccess,
  addSubAdminFail,
  addSubAdminSuccess,
  getSubAdminFail,
  getSubAdminSuccess,
  putSubAdminFail,
  putSubAdminSuccess,
  deleteSubAdminFail,
  deleteSubAdminSuccess,
} from "./actions"

import {
  getSubAdmins,
  postSubAdmin,
  getSubAdmin,
  putSubAdmin,
  deleteSubAdmin,
} from "helpers/backend_helper"

function* fetchSubAdmins({ payload }) {
  try {
    const response = yield call(getSubAdmins, payload)

    if (response.status == "failure") {
      return yield put(getSubAdminsFail(response.message))
    }

    yield put(getSubAdminsSuccess(response))
  } catch (error) {
    yield put(getSubAdminsFail(error))
  }
}

function* onAddNewSubAdmin({ payload: { subAdmin, history } }) {
  try {
    const response = yield call(postSubAdmin, subAdmin)

    if (response.status == "failure") {
      window.scrollTo(0, 0)

      return yield put(addSubAdminFail(response.message))
    }

    history && history.replace("/sub-admins")

    yield put(addSubAdminSuccess())
  } catch (error) {
    yield put(addSubAdminFail("Internal Error!"))
  }
}

function* fetchSubAdmin({ payload }) {
  try {
    const response = yield call(getSubAdmin, payload)

    if (response.status == "failure") {
      return yield put(getSubAdminFail(response.message))
    }

    yield put(getSubAdminSuccess(response.data))
  } catch (error) {
    yield put(getSubAdminFail(error))
  }
}

function* onPutSubAdmin({ payload: { data, history } }) {
  try {
    const response = yield call(putSubAdmin, data)

    if (response.status == "failure") {
      return yield put(putSubAdminFail(response.message))
    }

    history && history.goBack()

    yield put(putSubAdminSuccess())
  } catch (error) {
    yield put(putSubAdminFail("Internal Error!"))
  }
}

function* onDeleteSubAdmin({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteSubAdmin, data)

    if (response.status == "failure") {
      return yield put(deleteSubAdminFail(response.message))
    }

    yield put(deleteSubAdminSuccess())
    callback && callback()
  } catch (error) {
    yield put(deleteSubAdminFail("Internal Error!"))
  }
}

function* SubAdminsSaga() {
  yield takeEvery(GET_SUB_ADMINS, fetchSubAdmins)
  yield takeEvery(ADD_SUB_ADMIN, onAddNewSubAdmin)
  yield takeEvery(GET_SUB_ADMIN, fetchSubAdmin)
  yield takeEvery(PUT_SUB_ADMIN, onPutSubAdmin)
  yield takeEvery(DELETE_SUB_ADMIN, onDeleteSubAdmin)
}

export default SubAdminsSaga
