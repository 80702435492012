import * as CONST from "./actionTypes";

export const getReferrals = (data, callback) => ({
  type: CONST.GET_REFERRAL,
  payload: {data, callback},
});

export const getReferralSuccess = (data) => ({
  type: CONST.GET_REFERRAL_SUCCESS,
  payload: data,
});

export const getReferralFail = (error) => ({
  type: CONST.GET_REFERRAL_FAIL,
  payload: error,
});

export const deleteReferral = (data, callback) => ({
  type: CONST.DELETE_REFERRAL,
  payload: { data, callback },
});

export const deleteReferralSuccess = (crypto) => ({
  type: CONST.DELETE_REFERRAL_SUCCESS,
  payload: crypto,
});

export const deleteReferralFail = (error) => ({
  type: CONST.DELETE_REFERRAL_FAIL,
  payload: error,
});


