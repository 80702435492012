import {
  GET_TOPDAPP_LIST,
  GET_TOPDAPP_LIST_SUCCESS,
  GET_TOPDAPP_LIST_FAIL,
  ADD_TOPDAPP,
  ADD_TOPDAPP_SUCCESS,
  ADD_TOPDAPP_FAIL,
  UPDATE_TOPDAPP,
  UPDATE_TOPDAPP_FAIL,
  UPDATE_TOPDAPP_SUCCESS,
  GET_TOPDAPP,
  GET_TOPDAPP_FAIL,
  GET_TOPDAPP_SUCCESS,
  DELETE_TOPDAPP,
  DELETE_TOPDAPP_SUCCESS,
  DELETE_TOPDAPP_FAIL,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: COLLECTION_API_FAIL,
  payload: error,
});

/* Get TopDapp */
export const getTopDappList = (data) => ({
  type: GET_TOPDAPP_LIST,
  payload: data,
});

export const getTopDappListSuccess = (TopDapp) => ({
  type: GET_TOPDAPP_LIST_SUCCESS,
  payload: TopDapp,
});

export const getTopDappListFail = (error) => ({
  type: GET_TOPDAPP_LIST_FAIL,
  payload: error,
});
/* Get TopDapp END */

/* add TopDapp */
export const addTopDapp = (data, callback) => ({
  type: ADD_TOPDAPP,
  payload: { data, callback },
});

export const addTopDappSuccess = (TopDapp) => ({
  type: ADD_TOPDAPP_SUCCESS,
  payload: TopDapp,
});

export const addTopDappFail = (error) => ({
  type: ADD_TOPDAPP_FAIL,
  payload: error,
});
/* add TopDapp END */

/* udpate TopDapp */
export const udpateTopDapp = (data, callback) => ({
  type: UPDATE_TOPDAPP,
  payload: { data, callback },
});

export const udpateTopDappSuccess = (TopDapp) => ({
  type: UPDATE_TOPDAPP_SUCCESS,
  payload: TopDapp,
});

export const udpateTopDappFail = (error) => ({
  type: UPDATE_TOPDAPP_FAIL,
  payload: error,
});
/* udpate TopDapp END */

/* get TopDapp */
export const getTopDapp = (data, callback) => ({
  type: GET_TOPDAPP,
  payload: { data, callback },
});

export const getTopDappSuccess = (TopDapp) => ({
  type: GET_TOPDAPP_SUCCESS,
  payload: TopDapp,
});

export const getTopDappFail = (error) => ({
  type: GET_TOPDAPP_FAIL,
  payload: error,
});
/* get TopDapp END */

/* get TopDapp */
export const deleteTopDapp = (data, callback) => ({
  type: DELETE_TOPDAPP,
  payload: { data, callback },
});

export const deleteTopDappSuccess = (TopDapp) => ({
  type: DELETE_TOPDAPP_SUCCESS,
  payload: TopDapp,
});

export const deleteTopDappFail = (error) => ({
  type: DELETE_TOPDAPP_FAIL,
  payload: error,
});
/* get TopDapp END */
