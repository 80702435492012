import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (history, toggleConfirmModal, t, accesses) => [
  
  {
    dataField: "email",
    text: t("Email"),
    sort: true,
  },
  // {
  //   dataField: "address",
  //   text: t("Wallet Address"),
  //   sort: true,
  //   formatter: (_, row) =>
  //     row?.walletAddress?.[0]?.address,
  // },
  // {
  //   dataField: "adminStatus",
  //   text: t("Status"),
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         "text-capitalize font-size-13 badge-soft-" +
  //         (row?.adminStatus == "active"
  //           ? "success"
  //           : row?.adminStatus == "inactive"
  //           ? "danger"
  //           : "warning")
  //       }
  //     >
  //       {t(row?.adminStatus)}
  //     </Badge>
  //   ),
  // },
  {
    text: t("Registered At"),
    dataField: "created_at",
    sort: true,
    formatter: (_, row) =>
      moment(row.created_at).format("DD MMM YYYY hh:mm A"),
  },
  // {
  //   isDummyField: true,
  //   text: t("Action"),
  //   dataField: "action",
  //   formatter: (_, row) => (
  //     <>
  //       <Link to={`/users/profile/${row._id}`} className="mr-3 text-secondary">
  //         <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
  //         <UncontrolledTooltip
  //           placement="top"
  //           target={`view-${row._id}-tooltip`}
  //         >
  //           {t("View")} {t("Details")}
  //         </UncontrolledTooltip>
  //       </Link>
  //     </>
  //   ),
  // },
];

export default CustomersColumns;
