import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (history, toggleConfirmModal, t, accesses) => [
  {
    dataField: "name",
    text: t("Name"),
    sort: true,
  },
  {
    dataField: "email",
    text: t("Email"),
    sort: true,
  },

  {
    dataField: "walletAddress",
    text: t("Wallet Address"),
    sort: true,
  },
  {
    dataField: "adminStatus",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          "text-capitalize font-size-13 badge-soft-" +
          (row?.adminStatus == "active"
            ? "success"
            : row?.adminStatus == "inactive"
            ? "danger"
            : "warning")
        }
      >
        {t(row?.adminStatus)}
      </Badge>
    ),
  },
  {
    text: t("Registered At"),
    dataField: "date_created",
    sort: true,
    formatter: (_, row) =>
      moment(row.date_created).format("DD MMM YYYY hh:mm A"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link to={`/merchants/profile/${row._id}`} className="mr-3 text-secondary">
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link>

        <Link to={`/merchants/transaction/${row._id}`} className="mr-3 text-secondary">
          <i className="fas fa-dollar-sign mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Transaction")}
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default CustomersColumns;
